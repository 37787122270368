import ReactDOM from 'react-dom';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react'
import YouTubeConsentWrapper from '../components/cookie/YouTubeOverlay'
import { create } from 'lodash';
export function getUrlParameter(name) {
  if (typeof window !== 'undefined') {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    var results = regex.exec(location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  }
}

export function getDuration(active) {
  switch (active) {
    case 'biAnnual':
      return '24'

    case 'annual':
      return '12'

    case 'monthly':
      return '1'
  }
}

export function getPlanParameter(tarif) {
  switch (tarif) {
    case 'rechnung':
      return 'RECHNUNG-24_04'

    case 'buchhaltung':
      return 'BUCHHALTUNG-24_04'

    case 'warenwirtschaft':
      return 'BUCHHALTUNG_PRO-24_04'
  }
}

export function fixUmlauts(value) {
  value = value.replace(/ä/g, 'ae')
  value = value.replace(/ö/g, 'oe')
  value = value.replace(/ü/g, 'ue')
  value = value.replace(/ß/g, 'ss')
  return value
}

export function removeAutoFormatParameter(inputString) {
  const autoFormatIndex = inputString.indexOf('?auto=format');

  if (autoFormatIndex !== -1) {
    // Check if there's an empty string right after the '?auto=format'
    if (autoFormatIndex + '?auto=format'.length === inputString.length) {
      return inputString.substring(0, autoFormatIndex);
    }
  }

  return inputString;
}

export function setCookie(name, value) {
  const d = new Date()
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

export function setCrossDomainCookie(name, value) {
  const d = new Date()
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  if (process.env.NODE_ENV === 'development') {
    document.cookie = name + '=' + value + ';' + expires + `;domain=localhost;path=/`
  } else {
    document.cookie =
      name +
      '=' +
      value +
      ';' +
      expires +
      `;domain=.sevdesk.${process.env.GATSBY_LOCALE.replace('de-AT', 'at')};path=/`
  }
}

export function getCookie(cname) {
  if (typeof window !== 'undefined') {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
}

export const deleteAllCookies = () => {
  if (typeof window !== 'undefined') {
    document.cookie.split(';').forEach(function (c) {
      if (c.indexOf('geoConsent') === -1) {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
      }
    })
    window.location.reload()
  }
}

if (typeof window !== 'undefined') {
  window.deleteAllCookies = deleteAllCookies
}

export const getPageSlug = (category, slug) => {
  // Startseite
  if (
    (category === 'Landing' || category === undefined || category === null) &&
    slug === 'startseite'
  ) {
    return ''
  }

  // Standard
  if (category === 'Landing' || category === undefined || category === null) {
    return slug
  }

  // Events
  if (category === 'Events' || category === undefined || category === null) {
    return 'events/' + slug
  }
}

export const createBreadcrumbJSONLD = (breadcrumb) => {
  let itemListElements = []
  {
    breadcrumb &&
      breadcrumb.map((item, index) => {
        if (item?.slug && item.slug !== 'null') {
          itemListElements.push({
            '@type': 'ListItem',
            position: index,
            name: item.title,
            item: item.slug,
          })
        } else {
          itemListElements.push({
            '@type': 'ListItem',
            position: index,
            name: item.title,
          })
        }
      })
  }

  const composedBreadcrumbs = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemListElements,
  }

  return composedBreadcrumbs
}

export const createSchemaRatgeber = (
  relatedLinkArray,
  slug,
  headline,
  datePublished,
  dateModified,
  autorName,
  autorURL,
  autorKnowsAbout,
  heroImage,
  siteURL,
) => {
  const updatedHeroURL = heroImage.replace('auto=format', '')
  let schemaRatgeber = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    // mainEntity: [],
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': siteURL + slug,
      relatedLink: relatedLinkArray,
    },
    headline: headline,
    image: [
      updatedHeroURL + 'ar=1:1&fit=crop', //Artikelbild in 3 verschiedenen Größenvarianten
      updatedHeroURL + 'ar=4:3&fit=crop',
      updatedHeroURL + 'ar=16:9&fit=crop',
    ],
    datePublished: datePublished, //jeweils die korrekten Angaben der Veröffentlichung und Aktualisierung des Artikels inkl. Korrekte Angabe der Zeitzone
    dateModified: dateModified,
    // "articleBody": "Der komplette Body des Artikels soll in JSON-LD eingebunden werden.",
    author: {
      '@type': 'Person',
      name: autorName,
      url: siteURL + '/autoren/' + autorURL,
      knowsAbout: [autorKnowsAbout],
    },
    publisher: {
      '@type': 'Organization',
      name: 'sevDesk',
      logo: 'https://sevdesk.imgix.net/34060/1717417054-sevdesk-logo-512.png', // Logo URL ergänzen
    },
  }
  relatedLinkArray ??
    relatedLinkArray.forEach((elem) => {
      schemaRatgeber.relatedLink.push({
        elem,
      })
    })

  return JSON.stringify(schemaRatgeber, null, 2)
}

export const createSchemaBlog = (
  siteURL,
  pageContext
) => {

  const author = pageContext?.author
  let wordCount = countWords(pageContext?.content);
  let text = gehtWordsFromHTMLContent(pageContext?.content);
  const seoSettings = pageContext?.seoSettings
  const updatedHeroURL = pageContext.hero?.url?.replace('auto=format', '')

  let schemaBlog = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": pageContext?.title,
    "alternativeHeadline": "",
    "image": updatedHeroURL,
    "editor": author.authorName,
    "genre": pageContext?.category,
    // "keywords": "Steuererklärung Kleingewerbe Checkliste Steuern",
    "wordcount": wordCount,
    "publisher": {
      "@type": "Organization",
      "name": "sevDesk",
      "logo": {
        "@type": "ImageObject",
        "url": "https://sevdesk.imgix.net/sevdesk.png?auto=format&w=600",
        "width": 600,
        "height": 60
      },
    },
    "url": siteURL + "/blog/" + pageContext?.slug + "/",
    "datePublished": germanDateToTimestamp(pageContext?.meta?.firstPublishedAt),
    "dateCreated": germanDateToTimestamp(pageContext?.meta?.createdAt),
    "dateModified": germanDateToTimestamp(pageContext?.meta?.updatedAt),
    "description": seoSettings.description,
    "articleBody": text,
    "author": {
      "@type": "Person",
      "name": author.authorName,
    }
  }

  return JSON.stringify(schemaBlog, null, 2)
}

export const createSchemaLexikon = (
  siteURL,
  pageContext
) => {

  let text = gehtWordsFromHTMLContent(pageContext?.content);
  const updatedHeroURL = "https://sevdesk.imgix.net/lexikon-article-hero.jpg"
  const relatedLinkArray = generateSlugs(pageContext?.filterdResults, siteURL)

  let schemaLexikon = {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': siteURL + '/lexikon/' + pageContext?.slug + "/",
      relatedLink: relatedLinkArray,
    },
    "headline": pageContext?.title,
    "image": [ //Das Artikelbild jeweils eingebunden in den 3 Größenverhältnissen 1200x1200, 1200x900 und 1200x675
      `${updatedHeroURL}?w=1200&h=1200&fit=crop`,
      `${updatedHeroURL}?w=1200&h=900&fit=crop`,
      `${updatedHeroURL}?w=1200&h=675&fit=crop`
    ],
    // "wordcount": wordCount,
    "datePublished": germanDateToTimestamp(pageContext?.meta?.firstPublishedAt),
    "dateCreated": germanDateToTimestamp(pageContext?.meta?.createdAt),
    "dateModified": germanDateToTimestamp(pageContext?.meta?.updatedAt),
    "articleBody": text,
    // "url": siteURL + pageContext?.slug + "/",
    // "dateCreated": pageContext?.meta?.createdAt,
    // "description": seoSettings.description,
    "author": {
      "@type": "Organization",
      "name": "sevDesk",
      "url": siteURL
    },
    "publisher": {
      "@type": "Organization",
      "name": "sevDesk",
      "logo": {
        "@type": "ImageObject",
        "url": "https://sevdesk.imgix.net/sevdesk.png?auto=format&ixlib=react-9.3.0&w=400",
        "width": 600,
        "height": 60
      },
      "url": siteURL,
      "sameAs": [
        "https://www.facebook.com/sevdesk/", "https://de.linkedin.com/company/sevdesk-gmbh", "https://www.kununu.com/de/sevdesk", "https://www.youtube.com/channel/UCjioq2QuGj7tR0zcV6eTjZQ"
      ]

    }
  }
  return JSON.stringify(schemaLexikon, null, 2)
}

export const createSchemaAutor = (
  datePublished,
  dateModified,
  siteURL,
  listOfArticles,
  pageContext
) => {

  const autorName = pageContext?.authorName
  const headline = `${autorName}: Autor bei sevDesk`
  const autorKnowsAbout = pageContext?.knowsAbout
  const seoSettings = pageContext?.seoSettings
  const autorUrl = siteURL + '/autoren/' + pageContext?.slug
  const hasPartAsList = createSchemaAutorHasPartAsList(listOfArticles, siteURL, autorUrl)

  let schemaAutor = {
    '@context': 'https://schema.org',
    '@type': 'ProfilePage',
    'headline': headline,
    'mainEntity': {
      '@type': 'Person',
      'name': autorName,
      'knowsAbout': [autorKnowsAbout],
      'url': [
        siteURL + '/autoren/' + pageContext?.slug,
        pageContext?.linkedinLink,
        pageContext?.twitterLink,
        pageContext?.xingLink,
      ],
    },
    'description': seoSettings.description,
    'dateModified': dateModified,
    "hasPart": hasPartAsList && hasPartAsList,
  }
  const jsonLD = JSON.stringify(schemaAutor, null, 2)
  return jsonLD
}

export function germanDateToTimestamp(dateString) {
  // Define an array of German month names
  var germanMonths = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ]
  // Define a date string in the format "März 28, 2022"

  if (typeof dateString === 'undefined' || dateString === null) {
    return
  }
  // Split the date string into its parts
  var parts = dateString.split(' ')
  // Extract the month, day, and year from the date string
  var month = parts[0]
  var day = parts[1].replace(',', '')
  var year = parts[2]
  // Find the index of the month in the array of German month names
  var monthIndex = germanMonths.indexOf(month)
  // Add a leading zero to the month index, if necessary, to make it two digits long
  var monthString = (monthIndex + 1).toString().padStart(2, '0')
  // Construct a new date string in the format "yyyy-MM-dd" using the year, month index, and day from the original date string
  var newDateString = year + '-' + monthString + '-' + day
  // Create a new Date object from the new date string
  var date = new Date(newDateString)
  // Use the toISOString() method to convert the date to the format "yyyy-MM-dd'T'HH:mm:ssZ"
  var isoString = date.toISOString()

  // console.log(isoString) // 2022-03-28T00:00:00Z
  return isoString
}

// Für ShoppingLP
export function getSoftwarePackagePricing(rate, pricingJSON) {

  if (rate.toLowerCase() === 'buchhaltung') {
    return pricingJSON.buchhaltung
  }
  if (rate.toLowerCase() === 'rechnung') {
    return pricingJSON.rechnung
  }

  if (rate.toLowerCase() === 'warenwirtschaft') {
    return pricingJSON.warenwirtschaft
  }
}

/**
 * Modifies the given HTML content by updating <img> tags in two ways:
 * 1. Appends "?auto=format,compress" to the "src" attribute to request the image in an optimized format.
 * 2. Adds a "loading='lazy'" attribute to defer offscreen images until the user scrolls near them.
 *
 * This function leverages the html-react-parser library to parse and transform the HTML string.
 *
 * @param {string} htmlContent - The original HTML content containing <img> tags to be updated.
 * @returns {string} - The transformed HTML content with updated <img> tags.
 */

export function updateImgAndIframeTags(htmlContent) {

  if (htmlContent) {

    const options = {
      replace: (domNode) => {

        const [screenWidth, setScreenWidth] = useState();

        useEffect(() => {
          const parentDivBlogLexikon = document.querySelector('.c-article-body__content');
          if (parentDivBlogLexikon) {
            setScreenWidth(parentDivBlogLexikon.offsetWidth)
          }
          const parentDivRatgeber = document.querySelector('.r-content');
          if (parentDivRatgeber) {
            setScreenWidth(parentDivRatgeber.offsetWidth)
          }
        });

        if (domNode.attribs) {
          if (domNode.name === 'img') {
            // Get the src attribute if there is an src attribute
            if (domNode.attribs.src !== undefined) {
              let src = domNode.attribs?.src?.trim();

              // If src contains "?", remove "?" and everything after it
              if (src.includes('?')) {
                src = src.split('?')[0];
              }
              // Modify the src attribute
              domNode.attribs.src = `${src}?auto=format,compress`;

              // Add 'loading="lazy"' attribute only if it doesn't already exist
              if (!domNode.attribs.loading) {
                domNode.attribs.loading = 'lazy';
              }

              // Set width and height based on screen size
              if (typeof window !== 'undefined') {
                if (screenWidth) {
                  // Get the screen width and height
                  let screenHeight = window.innerHeight;

                  // Here, set your desired width and height based on the screen size
                  domNode.attribs.width = String(screenWidth);  // Example: set image width to screen width
                  domNode.attribs.height = String(Math.floor((screenHeight / 2) - 100));   // Example: set image height to half the screen height
                }
              }
            }

          }

          else if (domNode.name && domNode.name === 'iframe') {
            if (domNode.attribs.src !== undefined) {
              let src = domNode.attribs?.src?.trim();
              return (
                <YouTubeConsentWrapper youtubeVideoUrl={src} isBlog={true}></YouTubeConsentWrapper>)
            }


          }
        }
      }
    };

    // Use the html-react-parser to transform the HTML string
    return parse(htmlContent, options);
  }
  else return htmlContent;
}

export function removeAutoFormat(imageURL) {
  if (imageURL) {
    const regex = /\?auto=format$/;

    return imageURL.replace(regex, '');
  }
  else return ''
}

function createSchemaAutorHasPartAsList(listOfArticles, siteUrl, autorUrl) {
  let hasPartAsList = []
  for (let key in listOfArticles) {
    let article = listOfArticles[key];
    if (article && article.node && article.node.generatedSlug) {
      const updatedHeroURL = article.node.heroImage?.url?.replace('auto=format', '')
      hasPartAsList?.push({
        "@context": "https://schema.org",
        "@type": "Article",
        "url": siteUrl + article.node.generatedSlug,
        "headline": article.node.pageTitle,
        "datePublished": germanDateToTimestamp(article?.node?.meta?.firstPublishedAt),
        "dateModified": germanDateToTimestamp(article?.node?.meta?.updatedAt),
        "description": article.node.seoSettings.description,
        "publisher": {
          "@type": "Organization",
          "name": "sevDesk",
          "logo": {
            "@type": "ImageObject",
            "url": "https://sevdesk.imgix.net/sevdesk.png?w=600&h=60", // bitte die passende Logo URL angeben. bevorzugt png, Maße siehe unten
            "width": 600,
            "height": 60
          },
          "url": siteUrl,
          "sameAs": [
            "https://www.facebook.com/sevdesk/", "https://de.linkedin.com/company/sevdesk-gmbh", "https://www.kununu.com/de/sevdesk", "https://www.youtube.com/channel/UCjioq2QuGj7tR0zcV6eTjZQ"
          ]
        },
        "image": [
          updatedHeroURL + 'ar=1:1&fit=crop', //Artikelbild in 3 verschiedenen Größenvarianten
          updatedHeroURL + 'ar=4:3&fit=crop',
          updatedHeroURL + 'ar=16:9&fit=crop',
        ],
        "author": [
          {
            "@type": "Person",
            "name": article.node.author.authorName,
            "url": autorUrl
          }
        ]
      });
    }
  }

  return hasPartAsList
}

function countWords(htmlContent) {
  // Remove HTML tags
  if (htmlContent === undefined || htmlContent === null) return 0;
  let text = htmlContent?.replace(/<[^>]*>/g, ' ');

  // Replace HTML entities with their corresponding characters
  text = text?.replace(/&auml;/g, 'ä').replace(/&uuml;/g, 'ü').replace(/&szlig;/g, 'ß').replace(/&ouml;/g, 'ö').replace(/&Uuml;/g, 'Ü').replace(/&Ouml;/g, 'Ö').replace(/&Auml;/g, 'Ä');

  // Remove any extra whitespace
  text = text?.replace(/\s+/g, ' ').trim();

  // Split the text by spaces to count the words
  let words = text?.split(' ');

  return words?.length;
}

function gehtWordsFromHTMLContent(htmlContent) {
  if (htmlContent === undefined || htmlContent === null) return 0;
  // Remove HTML tags
  let text = htmlContent?.replace(/<[^>]*>/g, ' ');

  // Replace HTML entities with their corresponding characters
  text = text?.replace(/&auml;/g, 'ä').replace(/&uuml;/g, 'ü').replace(/&szlig;/g, 'ß').replace(/&ouml;/g, 'ö').replace(/&Uuml;/g, 'Ü').replace(/&Ouml;/g, 'Ö').replace(/&Auml;/g, 'Ä');

  // Remove any extra whitespace
  text = text?.replace(/\s+/g, ' ').trim();

  return text;
}

function generateSlugs(relatedLinkArray, siteURL) {
  return relatedLinkArray.map(array => `${siteURL}/lexikon/${array.node.slug}`).slice(0, 4);
}


export function isTodayInDateRanges(dateRanges) {
  // Get the current date in UTC
  const now = new Date();

  // Check each date range to see if 'now' falls within the range
  return dateRanges?.some(range => {
    // console.log("range", range)
      const startDate = new Date(range?.startDate);
      const endDate = new Date(range?.endDate);

      // Adjust for timezone by using UTC times

      return now >= startDate && now <= endDate;
  });
}
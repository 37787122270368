import { useState, useEffect } from 'react';

export function useMediaQuery(query) {
    if (typeof window !== 'undefined') {
        const [isMatching, setIsMatching] = useState(() => window.matchMedia(query).matches);
        useEffect(() => {
            const mediaQueryList = window.matchMedia(query);

            // The function to update state
            const handleMediaChange = (e) => {
                setIsMatching(e.matches);
            };

            // Attach the event listener
            mediaQueryList.addEventListener('change', handleMediaChange);

            // Set initial value
            setIsMatching(mediaQueryList.matches);

            // Cleanup
            return () => {
                mediaQueryList.removeEventListener('change', handleMediaChange);
            };
        }, [query]);

        return isMatching;
    }
}
import React from 'react'
import { Script } from 'gatsby'
class KreditRechner extends React.Component {
  render() {
    return (
      <div className='calc'>
        <div id="s_r_de_kredit">
          <Script type="text/javascript">
            {`
            s_r_de_kredit_premium = true;
            s_r_de_kredit_theme = "smoothness";
            s_r_de_kredit_responsive = true;
            s_r_de_kredit_slidedown = false;
            s_r_de_kredit_openresult = false;
            s_r_de_kredit_whitelabel = false;
            s_r_de_kredit_charts = false;
            s_r_de_kredit_title = "";
            s_r_de_kredit_adlink = "";
            s_r_de_kredit_adtext = "";
            s_r_de_kredit_adtarget = "_blank";
            s_r_de_kredit_adsize = "";
            s_r_de_kredit_adlink_requestmethod = "post";
            s_r_de_kredit_proxy_url = "";
            s_r_de_kredit_param_rechnertitel = "Kreditrechner";
            s_r_de_kredit_param_darlehensbetrag = "250000";
            s_r_de_kredit_param_disagio = "";
            s_r_de_kredit_param_zinssatz = "1";
            s_r_de_kredit_param_ratenintervall = "1";
            s_r_de_kredit_param_wunsch_input = "1";
            s_r_de_kredit_param_wunsch_laufzeit = "10";
            s_r_de_kredit_param_wunsch_rate = "1000";
            s_r_de_kredit_param_wunsch_anfangstilgung = "3";
            s_r_de_kredit_param_zinsbindung = "10";
            s_r_de_kredit_param_sondertilg_jaehrl = "";
        `}
          </Script>
          <Script
            type="text/javascript"
            charset="utf-8"
            src="https://load.smart-rechner.de/widget/kredit.js"
          ></Script>
        </div>
      </div>
    )
  }
}

export default KreditRechner
import { getCookie, getUrlParameter, setCrossDomainCookie } from '../utils'
const track = async (data) => {
  try {
    console.log('track')
    await fetch(`${process.env.GATSBY_SEVATLAS_BACKEND}/web/seg/t`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    // window.sevDataLayer.trackingList.trackEvent(`${data.event}`)
    // if(data.event === "SignUp"){
    //   window.lintrk('track', { conversion_id: 6238249 });
    // }
  } catch (error) {
    // Handle errors, if any
    console.error('Error while tracking:', error)
  }
}

const page = async (data) => {
  try {
    console.log('page')
    await fetch(`${process.env.GATSBY_SEVATLAS_BACKEND}/web/seg/p`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    // window.sevDataLayer.trackingList.trackEvent('Page')
  } catch (error) {
    // Handle errors, if any
    console.error('Error while sending page data:', error)
  }
}

const identify = async (data) => {
  try {
    console.log('ident')
    await fetch(`${process.env.GATSBY_SEVATLAS_BACKEND}/web/seg/i`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    // window.sevDataLayer.trackingList.trackEvent('Identify')
  } catch (error) {
    // Handle errors, if any
    console.error('Error while identifying user:', error)
  }
}

const client = {
  track,
  page,
  identify,
}
//Commit

const listCookies = () => {
  return document.cookie.split(';').reduce((cookies, cookie) => {
    const [name, value] = cookie.split('=').map((c) => c.trim())
    cookies[name] = value
    return cookies
  }, {})
}
const getExperimentIds = () => {
  try {
    const cookies = listCookies()
    let experiments = []
    Object.entries(cookies).map(([key, value]) => {
      if (key.startsWith('atlas_exp_')) {
        const testId = key.split('atlas_exp_')[1]
        // console.log(value)
        experiments.push({
          id: testId,
          variant: value,
        })
      }
    })
    return experiments
  } catch (err) {
    console.log(err)
    return err
  }
}
export default function sevAnalytics(userConfig) {
  // return object for analytics to use
  const userId =
    typeof window !== 'undefined' && window.localStorage.getItem('ajs_user_id')
      ? JSON.parse(window.localStorage.getItem('ajs_user_id'))
      : null
  return {
    /* All plugins require a name */
    name: 'sev-analytics',
    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      enabled: userConfig.enabled,
    },
    setItemStart: ({ payload, instance }) => {
      // console.log("STARTING", payload)
      if (payload.key === '__anon_id') {
        if(getCookie('__anon_id') !== ''){
          return {
            ...payload,
            ...{value: getCookie('__anon_id')}
          }
        } else {
          setCrossDomainCookie('__anon_id', payload?.value)
          setCrossDomainCookie('ajs_anonymous_id', payload?.value)
        }
      }
    },
    /* Sync id when ready */
    page: ({ payload }) => {
   
      const discount = getCookie('discount')
      var campaign
      if (window.location?.search) {
        campaign = {
          content: getUrlParameter('utm_content'),
          medium: getUrlParameter('utm_medium'),
          name: getUrlParameter('utm_campaign'),
          source: getUrlParameter('utm_source'),
          term: getUrlParameter('utm_term'),
        }
      }
      var data = {
        context: {
          discount,
          campaign,
          page: {
            path: window.location?.pathname,
            referrer: document?.referrer,
            search: window.location?.search,
            title: document?.title,
            url: window.location?.href,
          },
          userAgent: navigator?.userAgent,
          experiments: getExperimentIds(),
        },
        name: document?.title,
        properties: payload.properties,
        anonymousId: payload.anonymousId,
        userId: userId,
      }
      client.page(data)
      // call provider specific page tracking
    },
    track: ({ payload }) => {
      const discount = getCookie('discount')
      var campaign
      if (window.location?.search) {
        campaign = {
          content: getUrlParameter('utm_content'),
          medium: getUrlParameter('utm_medium'),
          name: getUrlParameter('utm_campaign'),
          source: getUrlParameter('utm_source'),
          term: getUrlParameter('utm_term'),
        }
      }
      var data = {
        context: {
          discount,
          campaign,
          page: {
            path: window.location?.pathname,
            referrer: document?.referrer,
            search: window.location?.search,
            title: document?.title,
            url: window.location?.href,
          },
          userAgent: navigator?.userAgent,
          experiments: getExperimentIds(),
        },
        event: payload.event,
        properties: payload.properties,
        anonymousId: payload.anonymousId,
        userId: userId,
      }
      client.track(data)
    },
    identify: ({ payload }) => {
      var campaign
      if (window.location?.search) {
        campaign = {
          content: getUrlParameter('utm_content'),
          medium: getUrlParameter('utm_medium'),
          name: getUrlParameter('utm_campaign'),
          source: getUrlParameter('utm_source'),
          term: getUrlParameter('utm_term'),
        }
      }
      var data = {
        context: {
          campaign,
          page: {
            path: window.location?.pathname,
            referrer: document?.referrer,
            search: window.location?.search,
            title: document?.title,
            url: window.location?.href,
          },
          userAgent: navigator?.userAgent,
          experiments: getExperimentIds(),
        },
        event: payload.event,
        traits: payload.traits,
        properties: payload.properties,
        anonymousId: payload.anonymousId,
        userId: userId,
      }
      client.identify(data)
    },
  }
}

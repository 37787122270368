import { setCookie, setCrossDomainCookie } from '.'
import { checkPathsAndBlacklist } from './atlas-paths'

function getCookieValue(cookieString, cookieName) {
  const cookies = cookieString?.split(';') ?? [] // Split the string into individual cookies
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=')
    const cookieNameTrimmed = name.trim()

    if (cookieNameTrimmed === cookieName) {
      return value
    }
  }
  return null // Return null if the cookie is not found
}

function extractPathFromURL(url) {
  const startIndex = url.indexOf('/', 1) // Find the index of the first occurrence of "/" after the initial "/"
  const endIndex = url.lastIndexOf('/')

  if (startIndex !== -1 && endIndex !== -1) {
    const extractedPath = url.substring(startIndex, endIndex + 1) // Include the trailing slash
    return extractedPath.endsWith('/') ? extractedPath : extractedPath + '/'
  }

  return url // Return the original URL if the expected parts are not found
}

export const getAtlasServerData = async (data) => {
  try {
    let headers = {}
    let pageContext = { ...data }

    // console.log("CONTEXT", extractPathFromURL(context.url))
    let formattedURL = window.location.pathname
    if (formattedURL === '/' || formattedURL === '/index/') {
      formattedURL = '/startseite/'
    }
    // console.log("FORMattedURL", formattedURL)
    // if(!context?.url?.includes("/page-data/")){
    if (formattedURL) {
      const searchParams = new URLSearchParams(window?.location.href?.split('?')[1])
      const experimentId = searchParams.get('preview')
      const currentVariant = searchParams.get('currentVariant')
      if (experimentId && currentVariant !== 'Original') {
        const res = await fetch(
          `${process.env.GATSBY_SEVATLAS_BACKEND}/experiments/preview/${experimentId}`,
        )
        if (!res.ok) {
          throw new Error(`Response failed`)
        }
        const data = await res.json()

        if (data.success) {
          const { previewData } = data?.result
          const siteData = previewData?.[currentVariant]?.[searchParams.get('currentPath')]
          if (siteData.landingContent?.length !== 0) {
            pageContext.landingContent = siteData.landingContent
          }
          const variantClientData = clientData?.[currentVariant] ?? undefined
          const clientSiteData = variantClientData ? variantClientData?.[formattedURL] : undefined
          if (clientSiteData) {
            let newCSS = ``
            clientSiteData?.map((item) => {
              if (item.css) {
                newCSS += ' ' + item.css
              }
            })
            pageContext.css = newCSS
            pageContext.clientData = clientSiteData.filter((item) => !item.css)
          }
        }
      } else {
        // If ther is no preview, we will fetch all experiments to check if some experiment is matching the requirements here
        const res = await fetch(
          `${
            process.env.GATSBY_SEVATLAS_CDN
          }/v4/data/experiments_sevdesk.${process.env.GATSBY_LOCALE.replace('de-AT', 'at')}.json`,
        )
        if (!res.ok) {
          throw new Error(`Response failed`)
        }
        const data = await res.json()
        // Get the experiment cookie to check if the user is allready targeted for this experiment
        data?.map((experiment, index) => {
          let currentPageContext = {}
          // console.log("EXPERIMENT", experiment)
          const {
            _id,
            probability,
            variants,
            serverData,
            clientData,
            trackingData,
            paths,
            blacklist,
            globalJsSnippets,
            type,
          } = experiment
          // console.log(paths, blacklist)
          const experimentCookieName = `atlas_exp_${_id}`
          const isTargeted = new Function('cookies', 'userAgent', experiment?.serverConditions)(
            document.cookie,
            navigator.userAgent,
          )
          // console.log(paths, blacklist)
          const isPaths =
            checkPathsAndBlacklist(paths) &&
            (blacklist?.length ? !checkPathsAndBlacklist(blacklist) : true)
          // console.log("AUSGABE", experimentCookieName, context.headers.get('cookie'))
          const experimentCookieValue = getCookieValue(document.cookie, experimentCookieName)
          // console.log("COOKIE VALUE", experimentCookieValue)
          // console.log("TARGETED", experimentCookieName, isTargeted, isPaths)
          //Check if the user is allready targeted for this experiment or the tagetign conditions met
          if ((isTargeted || experimentCookieValue !== null) && isPaths) {
            const variant = experimentCookieValue ?? getVariant(probability, variants)
            if (variant !== 'Original') {
              // console.log(experimentCookieName)
              // console.log("EXPERIMENT", _id)
              // const path = context?.url?.split('?')[0];
              const variantServerData = serverData?.[variant] ?? undefined
              const serverSiteData = variantServerData
                ? variantServerData?.[formattedURL]
                : undefined
              const variantClientData = clientData?.[variant] ?? undefined
              const variantTrackingData =
                trackingData?.filter(
                  (item) => item.variant === variant && item.path === formattedURL,
                ) ?? undefined
              const clientSiteData = variantClientData
                ? variantClientData?.[formattedURL]
                : undefined
              if (serverSiteData && serverSiteData?.landingContent?.length > 0) {
                // console.log(experimentCookieName, serverSiteData.landingContent)
                pageContext.landingContent = serverSiteData?.landingContent
              }
              if (clientSiteData) {
                let newCSS = ``
                clientSiteData?.map((item) => {
                  if (item.css) {
                    newCSS += ' ' + item.css
                  }
                })
                currentPageContext.css = newCSS
                currentPageContext.clientData = clientSiteData.filter((item) => !item.css)
              }
              currentPageContext.trackingData = variantTrackingData
              currentPageContext.globalJsSnippets = globalJsSnippets.filter(
                (item) => item.variant === variant,
              )
              // console.log("EXPERIMENT DATA", pageContext)
              if (index === 0) {
                pageContext = { ...pageContext, ...currentPageContext }
              } else {
                let otherExperiments = pageContext.otherExperiments ?? []
                otherExperiments.push(currentPageContext)
                pageContext = { ...pageContext, otherExperiments }
              }
            } else {
              const variantTrackingData =
                trackingData?.filter(
                  (item) => item.variant === variant && item.path === formattedURL,
                ) ?? undefined
                pageContext.trackingData = variantTrackingData
            }
            //If the experiment cookie does not exist, set it by the response headers
            if (experimentCookieValue === null && _id !== null) {
              setCrossDomainCookie(experimentCookieName, variant, 31536000)
              // headers = {
              //   'Set-Cookie': `${experimentCookieName}=${variant};Max-Age=31536000;Path=/;Secure;SameSite=None;`,
              // }
            }
          }
        })
      }
    }
    return pageContext
  } catch (err) {
    console.error(err)
    return undefined
  }
}

function getVariant(probability, variants) {
  var probabilityVariant = parseInt(probability)
  var variantArray = []
  if (variants?.length === 2) {
    var probabilityOriginal = 100 - probabilityVariant
    for (var i = 0; i < probabilityOriginal; i++) {
      variantArray.push(variants[0])
    }
    for (var i = 0; i < probabilityVariant; i++) {
      variantArray.push(variants[1])
    }
  } else {
    for (var i = 0; i < probabilityVariant; i++) {
      variants.map((variant) => {
        variantArray.push(variant)
      })
    }
  }
  return variantArray[Math.floor(Math.random() * variantArray?.length)]
}

import React, { useState, useEffect } from 'react'
import GEOModal from './GEOModal'
import ConsentModal from './ConsentModal'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const LanguageNavigator = () => {
  const [showGeoConsent, setShowGeoConsent] = useState(false)

  useEffect(() => {
    const userLanguage = (window.navigator.userLanguage || window.navigator.language).replace(
      'de-DE',
      'de',
    )
    const siteLanguage = document.documentElement.lang

    if (userLanguage === 'de' && siteLanguage === 'de-AT' && !cookies.get('geoConsent')) {
      setShowGeoConsent(true)
    } else if (userLanguage === 'de-AT' && siteLanguage === 'de' && !cookies.get('geoConsent')) {
      setShowGeoConsent(true)
    }
  }, [])

  return (
    <>
      {showGeoConsent && <GEOModal onClose={() => setShowGeoConsent(false)}></GEOModal>}
      {!showGeoConsent && <ConsentModal></ConsentModal>}
    </>
  )
}

export default LanguageNavigator

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const Modal = ({ children, cx = "", open, Id, onClose }) => {
  const ref = useRef();

  useEffect(() => {
    // did Mount
    document.body.style.overflowY = "hidden";
    document.body.style.height = "100vh";
    document.body.style.position = "fixed";
    document.body.style.right = "0";
    document.body.style.left = "0";
    ref.current.focus();
    ref.current.style.opacity = "1";
    return () => {
      // did Unmount
      document.body.style.right = "";
      document.body.style.left = "";
      document.body.style.position = "";
      document.body.style.overflowY = "";
      if (location?.pathname.includes("/preise/")) {
        document.body.style.height = "";
      }
    };
  }, []);

  const key = (e) => {
    if (e.keyCode === 27 || e.type === "click") {
      ref.current.style.opacity = "0";
      setTimeout(() => onClose(), 300);
    }
  };

  if (open) {
    const btn = buttonClass[cx] ;
    return ReactDOM.createPortal(
      <div ref={ref} onKeyDown={key} tabIndex="0" className="modal-overlay">
        <div className={`${cx}`} id={Id}>
          {children}
          { btn && <div>
            <button className={btn} onClick={key}>
              <span className="sr-only">x</span>
            </button>
          </div>}
        </div>
      </div>,
      document.body
    );
  }
};

export default Modal;

const buttonClass = {
  "modal-integration": "integration-modal-close",
  "modal-vorlage-download": "c-modal__close",
};

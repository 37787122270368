

export const getValidDiscount = () => {
  return {}
}
export const isValidCounter = (counter) => {
  const now = new Date().getTime()
  const end = new Date(counter).getTime()
  return (end - now) > 0
}

export const isValidDiscount = (discount) => {
  if(!discount || !discount.counters){
    return false
  }
  return Date.parse(discount.counters.find((counter) => isValidCounter(counter?.endDate?.replaceAll("'", "")))?.endDate?.replaceAll("'", ""));
}


// import rabatts from '../../../data/sales.json'
// export const getValidDiscount = () => {
//   const now = new Date().getTime()
//   let validDiscount = undefined
//   rabatts?.forEach((rabatt) => {
//     if (rabatt?.counters !== undefined) {
//       for (const [start, end] of rabatt?.counters) {
//         let s = new Date(start.replace(/-/g, "/")).getTime()
//         let e = new Date(end.replace(/-/g, "/")).getTime()
//         if (s <= now && now <= e) {
//           validDiscount = {}
//           Object.assign(validDiscount, rabatt)
//           validDiscount.counters = [end.replace(/-/g, "/")]
//           validDiscount.configURL = validDiscount.webAppConfig ? `https://atlas-cdn.sevdesk.de/data/webapp/${validDiscount.webAppConfig}.json` : undefined;
//           break
//         }
//       }
//     }
//   })
//   return validDiscount
// }

// export const isValidCounter = (counter) => {
//   console.log(counter)
//   const now = new Date().getTime()
//   const end = new Date(counter).getTime()
//   return end - now > 0
// }

// export const isValidDiscount = (discount) => {
//   if(!discount || !discount.counters){
//     return false
//   }
//   return Date.parse(discount.counters.find((counter) => isValidCounter(counter)));
// }

// extracted by mini-css-extract-plugin
export var bannerBottom = "BottomBanner-module--banner-bottom--9c409";
export var black = "BottomBanner-module--black--84778";
export var closeBanner = "BottomBanner-module--close-banner--b354a";
export var hideBanner = "BottomBanner-module--hide-banner--30409";
export var lPageFooter = "BottomBanner-module--l-page-footer--ee103";
export var megaTopBannerCounterField = "BottomBanner-module--megaTopBannerCounterField--21b3a";
export var megaTopBannerCounterMark = "BottomBanner-module--megaTopBannerCounterMark--1f678";
export var megaTopBannerCounterNumber = "BottomBanner-module--megaTopBannerCounterNumber--6e771";
export var megaTopBannerWrapperCounter = "BottomBanner-module--megaTopBannerWrapperCounter--44296";
export var mint = "BottomBanner-module--mint--10f3a";
export var showBanner = "BottomBanner-module--show-banner--008b4";
export var showBannerAnimation = "BottomBanner-module--showBannerAnimation--23ab5";
export var stickyBannerBottom = "BottomBanner-module--sticky-banner-bottom--69c26";
export var stickyBannerBottomContainer = "BottomBanner-module--sticky-banner-bottom-container--4d667";
export var stickyBannerBottomCounterField = "BottomBanner-module--sticky-banner-bottom-counter-field--03890";
export var stickyBannerBottomCounterMark = "BottomBanner-module--sticky-banner-bottom-counter-mark--da11e";
export var stickyBannerBottomCounterNumber = "BottomBanner-module--sticky-banner-bottom-counter-number--e3b95";
export var stickyBannerBottomInner = "BottomBanner-module--sticky-banner-bottom-inner--8d0a9";
export var stickyBannerBottomText = "BottomBanner-module--sticky-banner-bottom-text--d6a0c";
export var stickyBannerBottomWrapperCounter = "BottomBanner-module--sticky-banner-bottom-wrapper-counter--d5e3a";
import React from 'react'
import Typewriter from '../Typewriter'
import useMediaQuery from '../../hooks/useMediaQuery' 
function HeroTypedLexikon(props) {
  const { addClass, title, typedOptions, typedText } = props;
  const additionalClass = addClass || '';
  const defaultTitle = 'Selbstständig machen';
  const options = typedOptions && JSON.parse(typedOptions);
  const typedArray = options || ['Selbständigkeit.', 'Unternehmertum.', 'Buchführung.'];
  const isMobile = useMediaQuery('(max-width: 1024px)');

  return (
    <div
      data-bgset={`https://sevdesk.imgix.net/34060/1605609850-hero-ho-buchhaltung.jpg?auto=format,compress&q=75${
        isMobile ? '&w=425&h=375&fit=crop' : '&w=1920&h=500&fit=crop'
      }`}
      style={{
        backgroundImage: `url(https://sevdesk.imgix.net/34060/1605609850-hero-ho-buchhaltung.jpg?auto=format,compress&q=75${
          isMobile ? '&w=425&h=375&fit=crop' : '&w=1920&h=500&fit=crop'
        })`,
      }}
      className={`c-hero-typed l-section--padding c-hero-typed--blue ${additionalClass} `}
    >
      <div className="container py-lg-4">
        <div className="row justify-content-center">
          <div className="col-11 col-sm-8 col-md-6">
            <h1 className="display-1 text-white">{title || defaultTitle}</h1>
            <h2 className="h3 text-white">
              <div dangerouslySetInnerHTML={{ __html: typedText }}></div>
              <Typewriter typedArray={typedArray} typingSpeed={50} deleteSpeed={30} pauseDuration={900} />
            </h2>
            {/* <p className="text-white u-text-note text-center mb-0">
              Du vermisst ein Lexikonbeitrag zu einem bestimmten Thema? Schreib uns{' '}
              <Link className="text-white u-text-underline" to="#branch-feedback">
                deinen Wunsch
              </Link>
              !
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroTypedLexikon;

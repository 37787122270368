const baseUrl = 'https://my.sevdesk.de'

if (baseUrl === 'https://groot.s.sevenit.biz') {
  console.error('api is pointing to groot')
}

function createFormData(data) {
  const formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }
  return formData
}

async function fetchWrapper(url, options) {
  try {
    const response = await fetch(url, options)
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`)
    }
    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.error(error)
    throw error
  }
}

function createAccount(data) {
  const formData = createFormData(data)
  return fetchWrapper(`${baseUrl}/register/save`, {
    method: 'POST',
    body: formData,
  })
}

function createAccountCheckout(data) {
  const formData = createFormData(data)
  return fetchWrapper(`${baseUrl}/register/coupon`, {
    method: 'POST',
    body: formData,
  })
}

function checkEmail(email) {
  const formData = createFormData({ email })
  return fetchWrapper(`${baseUrl}/register/checkemail/`, {
    method: 'POST',
    body: formData,
  })
}

function toLogin(email) {
  window.location = `${baseUrl}/#/login?message=loginexists&email=${email}`
}

// Redirect user to mobile thank you page
function toMobileThankYou(url) {
  return function () {
    try {
      window.analytics.track('mobileSignup').then(() => {})
    } catch (e) {
      console.log(e)
    }
    setTimeout(function () {
      window.location = url
    }, 200)
  }
}

// Redirect to app
function toApp(hash) {
  if (hash) {
    window.location.href = `${baseUrl}/#/login?hash_login=${hash}`
  } else {
    window.location.href = `${baseUrl}/#/login`
  }
}

// Update billing details
function updateSubscriptionBilling(tokens, customerData) {
  const params = new URLSearchParams(customerData)
  return fetchWrapper(
    `/api/v1/SubscriptionType/Factory/updateBillingInformation?cft=${tokens.cftToken}&token=${tokens.apiToken}`,
    {
      method: 'POST',
      body: params,
    },
  )
}

// Create checkout order
function createOrder(tokens, orderData) {
  const params = new URLSearchParams(orderData)
  return fetchWrapper(
    `/api/v1/SubscriptionType/Factory/createOrder?cft=${tokens.cftToken}&token=${tokens.apiToken}`,
    {
      method: 'POST',
      body: params,
    },
  )
}

// User login
function login(email, password) {
  const params = new URLSearchParams({
    username_login: email,
    password_login: password,
  })
  return fetchWrapper('/auth/authenticate/', {
    method: 'GET',
    params: params,
  })
}

// Get Auto Login One Time Token
function getAutoLoginOTT(tokens) {
  const params = new URLSearchParams({
    cft: tokens.cftToken,
    token: tokens.apiToken,
  })
  return fetchWrapper('/api/v1/SevUser', { method: 'GET', params: params }).then((response) => {
    const activeUser = response.data.user.objects[0]
    const innerParams = new URLSearchParams({
      cft: tokens.cftToken,
      token: tokens.apiToken,
    })
    return fetchWrapper(`/api/v1/SevUser/${activeUser.id}/getQRLoginData`, {
      method: 'POST',
      body: innerParams,
    })
  })
}

// Verify One Time Token
function verifyOTT(hash) {
  const params = new URLSearchParams({ ott: hash })
  return fetchWrapper('/api/v1/SevUser/Factory/verifyOTT', {
    method: 'GET',
    params: params,
  })
}

// Check coupon for checkout
function checkCoupon(tokens, data) {
  const params = new URLSearchParams(data)
  return fetchWrapper(
    `/api/v1/SubscriptionType/Factory/checkCoupon?cft=${tokens.cftToken}&token=${tokens.apiToken}`,
    {
      method: 'POST',
      body: params,
    },
  )
}

// Redirect to upgrade success
function upgradeSuccessRedirect(ott) {
  window.location.href = `${baseUrl}/#/admin/subscription/upgradeSuccess?hash_login=${ott}`
}

async function getCheckoutPlans(data) {
  try {
    const response = await fetch(
      'https://p2g9q0m4mc.execute-api.eu-central-1.amazonaws.com/sevDesk/coupon/isvalid',
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`)
    }

    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default {
    baseUrl,
  createAccount,
  createAccountCheckout,
  login,
  checkEmail,
  toLogin,
  toApp,
  toMobileThankYou,
  checkCoupon,
  updateSubscriptionBilling,
  getAutoLoginOTT,
  verifyOTT,
  createOrder,
  upgradeSuccessRedirect,
  getCheckoutPlans,
}

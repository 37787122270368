import React, { Component } from 'react'
import MargenRechner from '../../calculator/Margenrechner'

class MargenRechnerCustomTemplate extends Component {
  render() {
    return (
      <div className="container p-5">
        <div className="align-items-center text-center" style={{ zIndex: '0' }}>
          <MargenRechner />
        </div>
      </div>
    )
  }
}

export default MargenRechnerCustomTemplate

import React, { Component } from 'react'
import PayPalRechner from '../../calculator/PayPalrechner'

class PayPalRechnerCustomTemplate extends Component {
  render() {
    return (
      <div className="container p-5">
        <div className="align-items-center text-center" style={{ zIndex: '0' }}>
          <PayPalRechner />
        </div>
      </div>
    )
  }
}

export default PayPalRechnerCustomTemplate

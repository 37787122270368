import React from 'react'
import { Script } from 'gatsby'

class PayPalRechner extends React.Component {
  render() {
    return (
      <div className="calc">
        <div id="s_r_de_paypal">
          <Script type="text/javascript">
            {`
            s_r_de_paypal_premium = true;
            s_r_de_paypal_theme = "smoothness";
            s_r_de_paypal_responsive = true;
            s_r_de_paypal_slidedown = false;
            s_r_de_paypal_openresult = false;
            s_r_de_paypal_whitelabel = false;
            s_r_de_paypal_charts = false;
            s_r_de_paypal_title = "";
            s_r_de_paypal_adlink = "";
            s_r_de_paypal_adtext = "";
            s_r_de_paypal_adtarget = "_blank";
            s_r_de_paypal_adsize = "";
            s_r_de_paypal_adlink_requestmethod = "post";
            s_r_de_paypal_proxy_url = "";
            s_r_de_paypal_param_berechnungsart = "1";
            s_r_de_paypal_param_betrag = "50,00";
            s_r_de_paypal_param_paypalkontoart = "2";
            s_r_de_paypal_param_transaktion_privat = "2";
            s_r_de_paypal_param_privat_verkauf_ausl = "1";
            s_r_de_paypal_param_privat_verkauf_qr_ausl = "1";
            s_r_de_paypal_param_privat_empfang_spenden_ausl = "1";
            s_r_de_paypal_param_privat_senden_ff_ausl = "1";
            s_r_de_paypal_param_privat_abhebung = "1";
            s_r_de_paypal_param_transaktion_gewerbl = "1";
            s_r_de_paypal_param_gewerbl_verkauf_normal_ausl = "1";
            s_r_de_paypal_param_gewerbl_verkauf_sonst_ausl = "1";
            s_r_de_paypal_param_gewerbl_verkauf_qr_ausl = "1";
            s_r_de_paypal_param_gewerbl_verkauf_mikro_ausl = "1";
            s_r_de_paypal_param_gewerbl_empfang_spenden_ausl = "1";
            s_r_de_paypal_param_gewerbl_empfang_spenden_gem_ausl = "1";
            s_r_de_paypal_param_gewerbl_empfang_spenden_gem_qr_ausl = "1";
            s_r_de_paypal_param_gewerbl_abhebung = "1";
            s_r_de_paypal_param_gewerbl_haendlerkond = "1";
            s_r_de_paypal_param_gewerbl_haendlerkond_ausl = "1";
        `}
          </Script>
          <Script
            type="text/javascript"
            charset="utf-8"
            src="https://load.smart-rechner.de/widget/paypal.js"
          ></Script>
        </div>
      </div>
    )
  }
}

export default PayPalRechner

import React, { Component } from 'react'
import AbschreibungsRechner from '../../calculator/Abschreibungsrechner'

class AbschreibungsrechnerCustomTemplate extends Component {
  render() {
    return (
      <div className='align-items-center text-center' style={{ zIndex: '0'}}>
        <AbschreibungsRechner />
      </div>
    )
  }
}

export default AbschreibungsrechnerCustomTemplate
import React, { useState, useEffect } from 'react';

const Image = ({ src, width, height, className, alt, loading = 'lazy', ...other }) => {

  const [classNames, setClassNames] = useState();


  useEffect(() => {
    setClassNames(updateAndAddLazyLoad(className, loading))
  }, [])

 
  return (
    <img
      className={`${classNames && classNames}`}
      width={width ? width : 'auto'}
      height={height ? height : 'auto'}
      data-src={src}
      src={loading === 'eager' ? src : null}
      alt={alt}
      loading={loading ? loading : 'lazy'}
      decode="async"
      {...other}
    />

  );
};

export default Image;


function updateAndAddLazyLoad(inputString, loading) {
  
  let newClassName = inputString && inputString ? inputString : ''
  
  if (loading === 'eager') {
    newClassName = inputString && inputString ? inputString : ''
      .replace(/\b(?:lazyload|lazyloaded|lazy)\b/g, '') // Remove lazy-related words
      .trim(); // Remove leading/trailing whitespace
  }
  else {
    const modifiedString = inputString && inputString ? inputString : ''
      .replace(/\b(?:lazyload|lazyloaded|lazy)\b/g, '') // Remove lazy-related words
      .trim(); // Remove leading/trailing whitespace

    newClassName = `${modifiedString} lazyload`; // Append 'lazyload'
  }
  return newClassName;

}
import React, { Component } from 'react'
import WährungsRechner from '../../calculator/Währungsrechner'

class WährungsRechnerCustomTemplate extends Component {
  render() {
    return (
      <div className="container p-5">
        <div className="align-items-center text-center" style={{ zIndex: '0' }}>
          <WährungsRechner />
        </div>
      </div>
    )
  }
}

export default WährungsRechnerCustomTemplate

import React, { useEffect, useState } from 'react'
import EmailFormField from '../form/EmailFormField'
import Image from '../base/Image'
import * as styles from './ExitIntent.module.scss'

export default function ExitIntent({
  exitIntentId = 'defaultExitIntent',
  headline = 'Du bist noch nicht ganz überzeugt?',
  content = `Du kannst sevDesk
<strong>unverbindlich und ohne Kreditkarte 14 Tage lang kostenlos testen.</strong> Überzeuge Dich selbst!`,
  emailForm = true,
  imageUrl = 'https://sevdesk.imgix.net/34060/1602752830-testimonial-alexa-voigtlaender.jpg?auto=format,compress&fit=crop&h=400&w=470&q=80',
  imageText = `“Seitdem ich sevDesk benutze, macht meine Buchhaltung fast schon Spaß. Schnell,
  übersichtlich und mit vielen positiven Aha-Momenten.”`,
}) {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.atlas && !window.atlas.helpers.getCookie(exitIntentId)) {
        document.addEventListener('mouseout', leaveSite)
      }
    }
  }, [])

  const leaveSite = (e) => {
    const shouldShowExitIntent = !e.toElement && !e.relatedTarget && e.clientY < 10

    if (shouldShowExitIntent) {
      document.removeEventListener('mouseout', leaveSite)
      setVisible(true)
      window.atlas[exitIntentId] = true
      const liveTime = 24 * 6
      window.atlas.helpers.setCookie(exitIntentId, true, liveTime)
    }
  }
  const close = () => {
    setVisible(false)
  }
  
  return (
    <div onClick={close} className={`${styles.exitIntentPopup} ${visible ? styles.visible : ''}`}>
      <div
        className={styles.exitIntentContainer}
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation()
        }}
        id="exit-intent"
      >
        <div className={styles.exitIntentContainerLeft}>
          <p className={styles.exitHeading}>{headline}</p>
          <div className={styles.exitText} dangerouslySetInnerHTML={{ __html: content }} />
          {emailForm && (
            <>
              <div className={`c-signup-form-s c-signup-form-s--lg`}>
                <EmailFormField />
              </div>
              <div className={styles.exitNote}>
                Mit der Anmeldung akzeptierst du die{' '}
                <a href="/agb/">
                  <span>AGB</span>
                </a>{' '}
                und die{' '}
                <a href="/datenschutz/">
                  <span>Datenschutzerklärung</span>
                </a>
                .
              </div>
            </>
          )}
        </div>
        <div className={styles.exitIntentContainerRight}>
          <Image className={`${styles.exitImage}`} src={imageUrl} alt={headline} width={470} height={400} />
          {imageText && imageText !== '' && <p className={styles.exitImageOverlay}>{imageText}</p>}
        </div>
        <div className={styles.close} onClick={close}>
          ✖
        </div>
      </div>
    </div>
  )
}






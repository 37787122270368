import React, { Component } from 'react'
import EinkommensteuerRechner from '../../calculator/Einkommensteuerrechner'

class EinkommensteuerRechnerCustomTemplate extends Component {
  render() {
    return (
      <div className="container p-5">
        <div className="align-items-center text-center" style={{ zIndex: '0' }}>
          <EinkommensteuerRechner />
        </div>
      </div>
    )
  }
}

export default EinkommensteuerRechnerCustomTemplate

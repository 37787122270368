import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Link from '../base/Link'
import * as styles from './Button.module.scss'
import useMediaQuery from '../../hooks/useMediaQuery'
import { getCookie, getUrlParameter } from '../../utils'
export default function Button({
  title,
  url = '/register/',
  buttonType = 'primary',
  nofollowLink,
  responsiveVisability,
  children,
  style,
  onClick,
  isCheckoutButton,
  ...other
}) {
  title = title || land[process.env.GATSBY_LOCALE]
  const [link, setLink] = useState(url)
  const isMobile = useMediaQuery('(max-width: 800px)')
  let additionalClass = ''

  useEffect(() => {
    if (isCheckoutButton) {
      const searchParams = new URLSearchParams(url.split('?')[1])
      //? SET ALL GENERIC STUFF IN URL PARAMETERS
      const referredBy = window.localStorage.getItem('referredBy')
      const referredByPartner = getCookie('referredByPartner')
      const referredByAwin = window.localStorage.getItem('referredByAwin')
      const accountantToken = getUrlParameter('accountantToken')
      const userConsent =  window.atlas && window.atlas.helpers && window.atlas.helpers.getCookie('userConsent')
      const gclid = window.localStorage.getItem('sev_gclid')
      // const sevInfo =  window.atlas.helpers.getCookie('sevInfo')

      referredBy && searchParams.set('referredBy', referredBy)
      referredByPartner && searchParams.set('referredByPartner', referredByPartner)
      referredByAwin && searchParams.set('referredByAwin', referredByAwin)
      accountantToken && searchParams.set('accountantToken', accountantToken)
      userConsent && searchParams.set('userConsent', userConsent)
      gclid && searchParams.set('gclid', gclid)
      // sevInfo && searchParams.set('sevInfo', sevInfo)

      setLink(url.split('?')[0] + '?' + searchParams.toString())
    } else {
      setLink(url)
    }
  }, [url])


  const openStorylanePopUp  = () => {
    if(typeof window !== 'undefined' && window.Storylane) {
     window.Storylane?.Play({type: 'popup', demo_type: 'html', width: 0, height: 0, scale: '0.95', demo_url: link, padding_bottom: '56.25%'})
    }
  }
  
  if (responsiveVisability !== 'both') {
    if (responsiveVisability === 'onlyDesktop') {
      additionalClass = isMobile ? 'invisible' : '';
    }
    else if (responsiveVisability === 'onlyMobile') {
      additionalClass = isMobile ? '' : 'invisible';
    }  
  }

  if (buttonType === 'link') {
    if (nofollowLink) {
      return (
        <Link className={styles.linkMore} style={style} title={title} to={link} nofollow>
          <span>{children ? children : title}</span>
        </Link>
      )
    } else {
      return (
        <Link className={styles.linkMore} style={style} title={title} to={link}>
          <span>{children ? children : title}</span>
        </Link>
      )
    }
  }
  if (buttonType === 'modal') {
    return (
      <button className={`${styles.primaryButton}`} style={style} title={title} onClick={onClick}>
        {children ? children : title}
      </button>
    )
  }
  if (buttonType === 'video') {
    return (
      <button className={` ${styles.videoButton} `} title={title} onClick={onClick}>
        {children ? children : title}
      </button>
    )
  }

  if (buttonType === 'inputField') {
    return (
      <button style={style} className={` ${styles.emailFormFieldButton}`} onClick={onClick} {...other}>
        {' '}
        {children ? children : title}
      </button>
    )
  }
  if (buttonType === 'submit') {
    return (
      <button
        className={` ${styles.submitButton} `}
        title={title}
        style={style}
        onClick={onClick}
        {...other}
      >
        {children ? children : title}
      </button>
    )
  }
  if (buttonType === 'primary' || buttonType === 'secondary') {
    return (
      <Link
        className={` ${buttonType === 'primary' ? styles.primaryButton : styles.secondaryButton} ${additionalClass}`}
        style={style}
        title={title}
        to={link}
        {...other}
      >
        {children ? children : title}
      </Link>
    )
  }
  if (buttonType === 'storylanePrimary' || buttonType === 'storylaneSecondary') {
    if  (buttonType === 'storylanePrimary') {
      return (
        // <button style={{display: isMobile ? 'none' : 'unset'}} className={styles.primaryButton} title={title} onClick={openStorylanePopUp}>
        //   {children ? children : title}
        // </button>
        <Link
          className={styles.primaryButton}
          // style={{display: isMobile ? 'none' : 'unset'}}
          title={title}
          to={link}
          {...other}
          target='_blank'
        >
          {children ? children : title}
        </Link>
      )
    }
    else if(buttonType === 'storylaneSecondary' || buttonType === 'storylane') {
      return (
        // <button style={{display: isMobile ? 'none' : 'unset'}} className={styles.secondaryButton} title={title} onClick={openStorylanePopUp}>
        //   {children ? children : title}
        // </button>
        <Link
          className={styles.secondaryButton}
          // style={{display: isMobile ? 'none' : 'unset'}}
          title={title}
          to={link}
          {...other}
          target='_blank'
        >
          {children ? children : title}
        </Link>
      )
    }
  }
}
Button.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  style: PropTypes.object,
  nofollowLink: PropTypes.bool,
  onClick: PropTypes.func,
  buttonType: PropTypes.oneOf([
    'primary',
    'secondary',
    'video',
    'modal',
    'link',
    'submit',
    'inputField',
    'storylane',
    'storylanePrimary',
    'storylaneSecondary'
  ]),
  responsiveVisability: PropTypes.oneOf([
    'both',
    'onlyDesktop',
    'onlyMobile',
  ]),
  isCheckoutButton: PropTypes.bool,
}

const land = {
  de: 'Kostenlos Testen',
  'de-AT': 'Gratis Testen',
}

import React, { useState, useEffect } from 'react'
import * as styles from './PricingTableTest.module.scss'

import useMediaQuery from '../../../hooks/useMediaQuery'
import Tooltip from '../../tooltip/Tooltip.js'
import Button from '../../button'
import dePricing from '../../../data/bookableTariffs'
import atPricing from '../../../data/bookableTariffsAT'
import { useDiscountContext } from '../../../hooks/useDiscountContext'
import { getLocale } from '../../../queries/locales'

import check from '../../../images/pricing-page-test/check.svg'
import phone from '../../../images/pricing-page-test/phone-01.svg'
import plus from '../../../images/pricing-page-test/plus.svg'
import message from '../../../images/pricing-page-test/message-heart-square.svg'

export default function PricingTableAbTest() {
  const [selectedDuration, setSelectedDuration] = useState('biAnnual')
  const [tariffsByDuration, setTariffsByDuration] = useState({})
  const locale = process.env.GATSBY_LOCALE
  const { ...discount } = useDiscountContext()
  const [bookableTariffs, setBookableTariffs] = useState({})

  const pricing = [
    {
      id: 'RECHNUNG-24_04',
      title: 'Rechnung',
      price: '0,00€',
      description: 'Perfekt für alle, die Rechnungen schreiben – schnell und einfach.',
      descriptionExtra: 'Alles was du brauchst, um erfolgreich durchzustarten',
    },
    {
      id: 'BUCHHALTUNG-24_04',
      promoted: true,
      title: 'Buchhaltung',
      price: '0,00€',
      description:
        'Perfekt für alle, die ihre Belege & Finanzen selbst verwalten und die Steuererklärung vorbereiten.',
      descriptionExtra: 'Alle Funktionen aus Rechnung und zusätzlich',
    },
    {
      id: 'BUCHHALTUNG_PRO-24_04',
      title: 'Buchhaltung Pro',
      price: '0,00€',
      description:
        'Perfekt für alle, die ihre Buchhaltung automatisieren und ihr Unternehmen in Echtzeit überblicken.',
      descriptionExtra: 'Alle Funktionen aus Buchhaltung und zusätzlich',
    },
  ]

  const features = {
    'RECHNUNG-24_04': [
      {
        label: 'Unbegrenzt Rechnungen schreiben',
        tooltip: `<p>Erstelle Rechnungen, die sämtliche rechtlichen Pflichtangaben einhalten. Die Umsatzsteuer wird anhand der geltenden Steuersätze automatisch berechnet. Nutze für einen professionellen Auftritt unsere modernen Rechnungsvorlagen.</p>`,
      },
      {
        label: 'Unbegrenzt Angebote schreiben',
        tooltip: `<p>Schreibe professionelle, GoBD-konforme Angebote und versende sie zur Bestätigung per E-Mail oder per Post über unsere Postschnittstelle. Optional kannst du kundenspezifische Rabatte nutzen.</p>`,
      },
      {
        label: 'E-Rechnungen senden und empfangen',
        badge: 'Bald',
      },
      {
        label: 'Individuelles Layout und Logo',
        tooltip: `<p>Personalisiere deine Rechnungen mit deinem Logo, lege wiederkehrende Textblöcke an und wähle aus verschiedenen Layouts.</p>`,
      },
      {
        label: 'Übersicht über offene Rechnungen',
        tooltip: `<p>Behalte fällige und offene Rechnungen immer im Blick, um rechtzeitig reagieren zu können und ggf. Zahlungserinnerungen an deine Kunden zu versenden.</p>`,
      },
      {
        label: 'Zahlungserinnerungen',
        tooltip: `<p>Behalte fällige Zahlungen im Blick und versende eine rechtskonforme Zahlungserinnerung – so bleibst du nicht länger auf unbezahlten Rechnungen sitzen. Auch Mahnungen kannst du direkt aus sevdesk versenden.</p>`,
      },
      {
        label: 'DATEV- / ADDISON Schnittstelle',
        tooltip: `<p>Digitale Zusammenarbeit mit deiner Steuerberatung wird Realität mit sicherem Zugriff auf Belege, Auswertungen und steuerlich relevanten Einstellungen.</p>`,
      },
      {
        label: 'Verknüpfung zu deinem Steuerberater',
        tooltip: `<p>Gib deiner Steuerberatung direkten Zugriff zu deinem sevdesk Konto. So musst du keine Daten eigenständig übermitteln.</p>`,
      },
    ],
    'BUCHHALTUNG-24_04': [
      {
        label: 'Für einfache und doppelte Buchführung',
        tooltip: `<p>Die einfache Buchführung (Ermittlung von Einnahmen und Ausgaben) wird am Jahresende (Ende des Kalenderjahres) in der Einnahmen-Überschuss-Rechnung (EÜR) zusammengefasst. Die doppelte Buchführung wird mittels eines Kontenrahmens durchgeführt und endet abschließend mit der Erstellung der Bilanz für das Kalenderjahr.</p>`,
      },
      {
        label: `${
          locale === 'de'
            ? 'Umsatzsteuervoranmeldung (UStVA) direkt ans Finanzamt'
            : 'Umsatzsteuerauswertung'
        }`,
        tooltip: `<p>${
          locale === 'de'
            ? 'Erstelle deine Umsatzsteuervoranmeldung in unter 2 Minuten und versende sie direkt an das Finanzamt. Dank unserer integrierten Schnittstelle zu ELSTER benötigst du kein zusätzliches Zertifikat.'
            : 'Erstelle deine Umsatzsteuervoranmeldung in unter 2 Minuten und versende sie direkt an das Finanzamt. Dank unserer integrierten Schnittstelle zu ELSTER benötigst du kein zusätzliches Zertifikat.'
        }
        </p>`,
      },
      {
        label: `${
          locale === 'de' ? 'EÜR und GuV für deine Steuererklärung' : 'Einnahmen-Ausgaben-Rechnung'
        }`,
        tooltip: `<p>${
          locale === 'de'
            ? 'Auf Basis deiner Einnahmen und Ausgaben erstellt sevdesk eine Einnahmen-Überschuss-Rechnung. Diese Gewinnermittlungsmethode muss jährlich beim Finanzamt eingereicht werden. Im Vergleich dazu richtet sich die Gewinn-und-Verlust-Rechnung nach dem Leistungsdatum. Ob diese EInnahmen und Ausgaben bereits bezahlt sind, spielt hierbei keine Rolle.'
            : 'Auf Basis deiner Einnahmen und Ausgaben erstellt sevdesk eine Einnahmen-Überschuss-Rechnung. Diese Gewinnermittlungsmethode muss jährlich beim Finanzamt eingereicht werden. Im Vergleich dazu richtet sich die Gewinn-und-Verlust-Rechnung nach dem Leistungsdatum. Ob diese EInnahmen und Ausgaben bereits bezahlt sind, spielt hierbei keine Rolle.'
        }
          </p>`,
      },
      // {
      //   label: 'Gewinn-und-Verlust-Rechnung',
      //   tooltip: `<p>Deine Einnahmen und Ausgaben werden mit dem Leistungsdatum aufgenommen. Ob diese bereits bezahlt sind, spielt hierbei keine Rolle.
      //   Die Umsatz- und Vorsteuer ist im Gegensatz zur Einnahmen-Überschuss-Rechnung neutral und hat keinen Einfluss auf den Gewinn.</p>`,
      // },
      {
        label: 'KI-gestützte Belegerfassung',
        tooltip: `<p>Scanne deine Belege mit der mobilen App oder lade sie per Drag & Drop am PC hoch. Deine Belege werden passenden Transaktionen zugeordnet und du erhältst eine Empfehlung für die passende Buchungskategorie.</p>`,
      },
      {
        label: 'Online Banking Verknüpfung',
        tooltip: `<p>Verknüpfe dein Online-Banking und verwalte deine Zahlungsein- und -ausgänge direkt in sevdesk. Wir unterstützen bereits über 4.000 Banken und Neobanken.</p>`,
      },
      {
        label: 'Abschreibungen',
        tooltip: `<p>Deine Anlagen werden als Ausgabebeleg automatisch erkannt und korrekt entweder als GWGs sofort oder als Anlagegut über ihre Nutzungsdauer gleichmäßig monatlich abgeschrieben und in deinen Auswertungen dargestellt.</p>`,
      },
      {
        label: 'Auftragsbestätigungen',
        tooltip: `<p>Erzeuge aus einem bestätigten Angebot eine rechtssichere Auftragsbestätigung mit deinen angebotenen Artikeln und zugehörigen Mengen.</p>`,
      },
      {
        label: 'Lieferscheine',
        tooltip: `<p>Erstelle passende Lieferscheine zu deinem Angebot inkl. allen Pflichtangaben. Alle folgenden Dokumente, wie die Rechnung, tragen automatisch dieselbe Referenz.</p>`,
      },
      {
        label: 'Mahnstufen',
        tooltip: `<p>Erstelle eine Mahnung aus einer überfälligen Rechnung und versende sie direkt aus sevdesk. Diese Funktion ermöglicht es dir, sämtliche Mahnstufen des internen Mahnwesens abzubilden.</p>`,
      },
      {
        label: 'Kassenbuchführung',
        tooltip: `<p>Mit dem integrierten Kassenbuch erfasst du Zahlungseingänge und -ausgänge und hast somit den aktuellen Kassenbestand im Blick. Tages- und Monatsabschlüsse lassen sich mit nur einem Mausklick erstellen.</p>`,
      },
    ],
    'BUCHHALTUNG_PRO-24_04': [
      {
        label: 'Für umfangreiche Analyse und Auswertung von Geschäftsvorgängen',
        tooltip: `<p>Erweitere deine Toolbox, um die wirtschaftlichen Gegebenheiten deines Betriebs besser zu erfassen. sevdesk unterstützt dich dabei, das Wachstum deines Unternehmens basierend auf wohlüberlegten Entscheidungen voranzutreiben.</p>`,
      },
      {
        label: 'Kostenstellen',
        tooltip: `<p>Weise Rechnungen und Belege verschiedenen Kostenstellen zu. So kannst du später nach Kostenstellen filtern und die Einnahmen und Ausgaben zu deinen Kostenstellen auswerten.</p>`,
      },
      {
        label: 'REST-API Schnittstelle',
        tooltip: `<p>Mit der REST-API Schnittstelle kannst du alle Daten und Funktionen innerhalb sevdesk steuern und externe Dienste mit sevdesk verbinden. Die API-Dokumentation hilft dir, eigene Lösungen zu entwickeln.</p>`,
      },
      locale === 'de' && {
        label: 'Betriebswirtschaftliche Auswertung',
        // tooltip: `<p>Erstelle deine betriebswirtschaftliche Auswertung (BWA) auf Basis aktueller Daten deiner digitalen Buchhaltung in sevdesk in Echtzeit. Nutze die BWA als wichtiges Instrument, um deinen Unternehmenserfolg zu bewerten und zu kontrollieren. Erhalte einen Überblick über die wichtigsten Finanzkennzahlen. Weise deinen Unternehmenserfolg unkompliziert gegenüber Dritten nach, z.B. deiner Bank.</p>`,
        badge: 'Bald',
      },
    ],
  }

  useEffect(() => {
    setBookableTariffs(process.env.GATSBY_LOCALE === 'de' ? dePricing : atPricing)
  }, [])

  const buttons = [
    {
      duration: 'monthly',
      plan: 'Monatlich',
    },
    {
      duration: 'annual',
      plan: '12 Monate',
    },
    {
      duration: 'biAnnual',
      plan: '24 Monate',
    },
  ]

  const getPrice = (planCode) => {
    const durationTranslate =
      selectedDuration === 'monthly' ? '1' : selectedDuration === 'annual' ? '12' : '24'
    const item = bookableTariffs?.objects?.find(
      (plan) => plan.planDuration === durationTranslate && plan.planCode === planCode,
    )

    if (item) {
      const total = item?.sumNet
      const monthly = (parseFloat(total) / parseInt(durationTranslate)).toFixed(2)
      return monthly.replace('.', ',')
    } else {
      return undefined
    }
  }

  useEffect(() => {
    setTariffsByDuration(discount?.tariffs?.[selectedDuration])
  }, [selectedDuration])

  return (
    <div className="container">
      <div className={styles.durationButtonGroup}>
        {buttons?.map((item, i) => (
          <button
            key={i}
            id={item.duration}
            name={item.duration}
            className={selectedDuration === item.duration ? styles.activeBtn : ''}
            onClick={() => setSelectedDuration(item.duration)}
          >
            {item.plan}
          </button>
        ))}
      </div>

      <div className="row mt-1 mt-md-5">
        {pricing?.map((item, i) => {
          return (
            <div className={`col-12 col-md-12 col-lg-4 col-xl-4 py-3 px-4 p-lg-0`} key={i}>
              <div
                className={`${styles.cardContainer} ${
                  item.promoted ? styles.bordered : styles.paddingTop
                }`}
              >
                {item.promoted ? <div className={styles.promoted}>Unsere Empfehlung</div> : null}
                <div className={styles.title}>{item.title}</div>
                {/* <p className={styles.description}>{item.description}</p> */}

                <div className={styles.priceValue}>
                  {tariffsByDuration?.[item.id]?.enabled ? (
                    <>
                      <span className={styles.strikethrough}>{getPrice(item.id)} €</span>{' '}
                      {tariffsByDuration?.[item.id]?.newPrice?.toFixed(2)?.replace('.', ',')} €{' '}
                      <span className={styles.pricingExtra}>/ Monat (zzgl. MwSt.)</span>
                    </>
                  ) : (
                    <>
                      {getPrice(item.id)} €{' '}
                      <span className={styles.pricingExtra}>/ Monat (zzgl. MwSt.)</span>
                    </>
                  )}
                </div>
                {console.log('tariffsByDuration', tariffsByDuration)}
                <p
                  className={styles.note}
                  dangerouslySetInnerHTML={{
                    __html: tariffsByDuration?.pricingTableDescription
                      ?.replaceAll(
                        '{coupon}',
                        `<span class=${styles.couponStyle}>${
                          tariffsByDuration?.[item.id]?.coupon
                        }</span>`,
                      )
                      .replaceAll('{normalPrice}', `${getPrice(item.id)} €`),
                  }}
                />
                <p className={styles.note}>
                  {' '}
                  Abbuchung erfolgt{' '}
                  {selectedDuration === 'monthly'
                    ? 'monatlich. Monatlich kündbar.'
                    : selectedDuration === 'annual'
                    ? 'jährlich'
                    : 'alle zwei Jahre'}
                </p>
                <div className={styles.buttonContainer}>
                  <Button
                    url={`https://buchen.sevdesk.${process.env.GATSBY_LOCALE.replace(
                      'de-AT',
                      'at',
                    )}/?plan=${item.id}&duration=${
                      selectedDuration === 'monthly'
                        ? '1'
                        : selectedDuration === 'annual'
                        ? '12'
                        : '24'
                    }`}                    
                    title="Jetzt buchen"
                    buttonType="primary"
                    style={{ width: '100%' }}
                    isCheckoutButton
                  >
                    Jetzt buchen
                  </Button>
                  <div className={styles.mobileButton}>
                    <Button
                      // className="sjdkflsdkjfksdk"
                      url={`/register/?plan=${item.id}&duration=${
                        selectedDuration === 'monthly'
                          ? '1'
                          : selectedDuration === 'annual'
                          ? '12'
                          : '24'
                      }`}
                      buttonType="link"
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        marginTop: '12px',
                        textTransform: 'uppercase',
                        fontSize: '14px',
                      }}
                    />
                  </div>
                  {/* Fallback for old pricing table */}
                  {/* <Button
                  url={`/register/?plan=${item.id}&duration=${selectedDuration === 'monthly' ? '1' : selectedDuration === 'annual' ? '12' : '24'}&coupon=${
                    tariffsByDuration?.[item.id]?.coupon
                  }`}
                  buttonType="primary"
                  style={{ width: '100%' }}
                />
                <Button
                  url={`https://buchen.sevdesk.${process.env.GATSBY_LOCALE.replace(
                    'de-AT',
                    'at',
                  )}/?plan=${
                    item.id
                  }&duration=${selectedDuration === 'monthly' ? '1' : selectedDuration === 'annual' ? '12' : '24'}&coupon=${
                    tariffsByDuration?.[item.id]?.coupon
                  }`}
                  title="Jetzt buchen"
                  buttonType="secondary"
                  style={{ width: '100%' }}
                  isCheckoutButton
                >
                  Jetzt buchen
                </Button> */}
                </div>

                <p className={styles.descriptionExtra}>{item.descriptionExtra}</p>
                <ul className={styles.featureList}>
                  {features[item.id]?.map((feature, i) => {
                    return (
                      <li key={i}>
                        {feature?.label}
                        {feature?.tooltip && <Tooltip abTest content={feature?.tooltip} />}
                        {feature?.badge && <span className={styles.badge}>{feature?.badge}</span>}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )
        })}
        <PricingTableBanner />
      </div>
    </div>
  )
}

const PricingTableBanner = () => {
  return (
    <div className="col-12 mt-3 mt-md-5 mt-lg-0 px-4 p-lg-0">
      <div className={styles.bannerContainer}>
        <p className={styles.title}>In allen Tarifen enthalten</p>
        <ul className={styles.bannerList}>
          <li>
            <img src={message} alt="GreenCheck" width={24} height={24} />
            <span style={{ paddingLeft: '0.5rem' }}>Persönlicher Support</span>
          </li>
          <li>
            <img src={check} alt="GreenCheck" width={24} height={24} />
            <span style={{ paddingLeft: '0.5rem' }}>GoBD-konforme Buchhaltung</span>
          </li>
          <li>
            <img src={phone} alt="GreenCheck" width={24} height={24} />
            <span style={{ paddingLeft: '0.5rem' }}>Kostenlose Mobile Apps</span>
          </li>
          <li>
            <img src={plus} alt="GreenCheck" width={24} height={24} />
            <span style={{ paddingLeft: '0.5rem' }}>
              Zahlreiche Integrationen (z.B. zu Zeiterfassungs- und e-Commerce-Systemen)
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

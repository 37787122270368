import React from 'react'
import { Link } from 'gatsby'
import EmailFormField from '../../components/form/EmailFormField'
import CheckmarkTemplate from './CheckMarkTags'
import Image from '../../components/base/Image'
import useMediaQuery from '../../hooks/useMediaQuery'


function HeroLPA(props) {
  const {
    trustIcon,
    imageMockup,
    checkmarks,
    socialProof,
    title,
    imageAlt,
  } = props;
  const isMobile = useMediaQuery('(max-width: 1024px)');

  return (
    <section className="c-hero c-hero--landing-sale2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-8">
            <h1 className="c-hero__headline display-3">{title}</h1>
            {socialProof === true ? (
              <p className="c-hero__lpa-desc h4 pb-md-2">
                Bereits über 100.000 Gründer und Selbständige vertrauen sevDesk für eine einfache Buchhaltung
              </p>
            ) : (
              <p className="c-hero__desc h4" style={{ height: 40 }}></p>
            )}
            {trustIcon === null ? null : (
              { isMobile } ? null : (<Image
                src={`${trustIcon}?auto=format,enhance&amp;fit=crop&w=110&h=110`}
                alt={'social proof'}
                className="img-fluid c-hero__gobd-icon pageBuilder-icon d-md-block"
              />)

            )}
            <div className="px-sm-5 mb-4">
              <div id="hero-signup">
                <div className="c-signup-form-s c-signup-form-s--sm">
                  <EmailFormField />
                </div>
              </div>
            </div>
            <CheckmarkTemplate checkmarks={checkmarks}></CheckmarkTemplate>
            <span className="c-hero__note">
              Es gelten die <Link to="/agb/">AGB</Link> &{' '}
              <Link to="/datenschutz/">Datenschutzerklärung</Link>.
              Wir verwenden deine E-Mail, um dir Infos rund um sevDesk bereitzustellen, denen du jederzeit widersprechen kannst.
            </span>
          </div>
        </div>
      </div>
      <div className="c-hero__logo">
        <Link to="/funktionen/" target="_blank">
          <span className="c-logo c-logo--white" />
        </Link>
      </div>
      <div className="c-hero__overflow d-none d-sm-block">
        <div className="c-hero__overflow__container">
          {/* {!isMobile && (
            <Image
              src={`${imageMockup}?auto=format,compress&amp;fit=crop`}
              alt={imageAlt ? imageAlt : ''}
              className="img-fluid c-hero__figure-pagebuilder"
              width={isMobile ? 300 : 500}
              height={isMobile ? 300 : 500}
            />)} */}

        </div>
      </div>
    </section>
  );
}

export default HeroLPA;

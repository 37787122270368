import { useState, useEffect } from 'react'

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setMatches(window.matchMedia(query).matches)
      const mediaQuery = window.matchMedia(query)
      if (mediaQuery.matches !== matches) {
        setMatches(mediaQuery.matches)
      }
      const listener = () => setMatches(mediaQuery.matches)
      mediaQuery.addEventListener('change', listener)

      return () => {
        mediaQuery.removeEventListener('change', listener)
      }
    }
  }, [query, matches])

  return matches
}

export default useMediaQuery

import React, { Component } from 'react'
import GewerbesteuerRechner from '../../calculator/GewerbesteuerrechnerNeu'

class GewerbesteuerRechnerCustomTemplate extends Component {
  render() {
    return (
      <div className="container p-5">
        <div className="align-items-center text-center" style={{ zIndex: '0' }}>
          <GewerbesteuerRechner />
        </div>
      </div>
    )
  }
}

export default GewerbesteuerRechnerCustomTemplate

import React from 'react'
import { useAutoComplete } from '../../assets/js/hook/useAutoComplete'
import { getBranchenCards } from '../../queries/branchen'
import Link from '../base/Link'
import SearchBox from '../searchBox'
import ResultAsList from '../searchBox/result/ResultAsList'
import { getLocale } from '../../queries/locales'

function BranchSearchHero() {
  const locale = getLocale().locale;
  const branchen = getBranchenCards(locale);
  const [hits, input, setInput] = useAutoComplete(
    branchen,
    ({ node: { previewTitle } }) =>
      input && previewTitle.toLowerCase().indexOf(input.toLowerCase()) !== -1,
  )

  return (
    <div className="mt-4 mb-3">
      <div id="search-autocomplete-branch">
        <SearchBox
          placeholder="Finde hier deine Branche"
          addClass="sm_branch"
          setInput={setInput}
          input={input}
        >
          <ResultAsList hits={hits} mapFunc={resultFunc} input={input} >
            <li>
              <Link to="/branchen/#branch-feedback/">
                <strong>Deine Branche ist nicht dabei?</strong>
                <br />
                <span>Sende uns deinen Vorschlag</span>
              </Link>
            </li>
          </ResultAsList>
        </SearchBox>
      </div>
    </div>
  )
}

export default BranchSearchHero

const resultFunc = ({ node: { id, slug, previewTitle } }) => (
  <li key={id}>
    <Link style={{ boxShadow: `none` }} to={`/branchen/${slug}/`}>
      {previewTitle}
    </Link>
  </li>
)

import React, { useContext, useEffect, useRef, useState } from 'react'
import * as styles from './ChatBot.module.scss'
import { socketContext } from '../../provider/SocketProvider'
import { marked } from 'marked'
import { useParams } from 'react-router-dom'

export default function Chatbot() {
  const chatRef = useRef()
  const params = useParams()
  const { socket } = useContext(socketContext)
  const [visible, setVisible] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [isWriting, setWriting] = useState(false)
  const [messages, setMessages] = useState([
    { role: 'assistant', content: 'Wie kann ich dir weiterhelfen?' },
  ])

  const scrollToBottom = () => {
    const chat = chatRef.current
    chat.scrollTop = chat.scrollHeight
  }
  useEffect(() => {
    if (enabled) {
      scrollToBottom()
    }
  }, [messages])

  useEffect(() => {
    if (socket) {
      socket.on('receiveChatBotMessage', (messages) => {
        setWriting(false)
        setMessages(messages)
      })
    }
  }, [socket])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.search?.startsWith('?chatbot=customersuccess')) setEnabled(true)
    }
  }, [])

  const sendMessage = async (message) => {
    try {
      let newMessages = [...messages]
      newMessages.push({
        role: 'user',
        content: message + ' Und auf welchen URLs finde ich passende informationen?',
      })
      socket.emit('sendChatBotMessage', newMessages)
      setWriting(true)
      setMessages(newMessages)
    } catch (err) {
      console.log(err)
    }
  }
  return enabled ? (
    <>
      <div className={styles.button} onClick={() => setVisible(!visible)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2.0"
          stroke="white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
          />
        </svg>
      </div>
      <div className={styles.chatContainer} style={{ display: visible ? 'block' : 'none' }}>
        <div className={styles.chatHeader}>
          <div className={styles.iconContainer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2.0"
              stroke="white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
              />
            </svg>
          </div>
          <p>sevDesk Chatbot</p>
        </div>
        <div className={styles.chatBody} ref={chatRef}>
          {messages?.map((msg, index) => (
            <div key={index} className={`p-2`}>
              <div
                className={msg.role === 'assistant' ? styles.botMessage : styles.userMessage}
                dangerouslySetInnerHTML={{
                  __html: marked(
                    msg.content?.replace(
                      ' Und auf welchen URLs finde ich passende informationen?',
                      '',
                    ),
                  ),
                }}
              ></div>
            </div>
          ))}
        </div>
        {isWriting && <p className={styles.botTyping}>Schreibt...</p>}
        <div className={styles.chatFooter}>
          <input
            placeholder={`Stelle deine Frage...`}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.target.value) {
                sendMessage(e.target.value)
                e.target.value = ''
              }
            }}
          />
        </div>
      </div>
    </>
  ) : null
}

import React from 'react'
import * as search from './index.module.scss'

const SearchBox = ({ placeholder, addClass, setInput, input, children, setSearchType, searchType }) => {
  return (
    <>
      <form noValidate role="search">
        <div className={`${search.searchField} ${search[addClass]}`}>
          <input
            id="suchleiste"
            aria-label="suchleiste"
            type="search"
            placeholder={placeholder}
            autoComplete="off"
            onChange={({ target }) => {
              setInput(target.value)
              setSearchType && setSearchType('userInput')
            }}
            value={searchType?.length ? searchType.includes('userInput') ? input : '' : input}
            onKeyDown={(event) => {
              if (event.keyCode == 13) event.preventDefault()
            }}
          />
          {input && (
            <span className={search.searchField__clear} onClick={() => setInput('')}></span>
          )}
        </div>
      </form>
      {children}
    </>
  )
}

export default SearchBox

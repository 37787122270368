import React from 'react'
import { Script } from 'gatsby'

class EinkommensteuerRechner extends React.Component {
  render() {
    return (
      <div className="calc">
        <div id="s_r_de_einkommensteuer">
          <Script type="text/javascript">
            {`
            s_r_de_einkommensteuer_premium = true;
            s_r_de_einkommensteuer_theme = "bootstrap";
            s_r_de_einkommensteuer_responsive = true;
            s_r_de_einkommensteuer_slidedown = false;
            s_r_de_einkommensteuer_openresult = false;
            s_r_de_einkommensteuer_whitelabel = false;
            s_r_de_einkommensteuer_charts = false;
            s_r_de_einkommensteuer_title = "";
            s_r_de_einkommensteuer_adlink = "";
            s_r_de_einkommensteuer_adtext = "";
            s_r_de_einkommensteuer_adtarget = "_blank";
            s_r_de_einkommensteuer_adsize = "";
            s_r_de_einkommensteuer_adlink_requestmethod = "post";
            s_r_de_einkommensteuer_proxy_url = "";
            s_r_de_einkommensteuer_param_steuerjahr = "1";
            s_r_de_einkommensteuer_param_ausserordentlich = "";
            s_r_de_einkommensteuer_param_jahresbrutto = "36000";
            s_r_de_einkommensteuer_param_entgeltersatz = "";
            s_r_de_einkommensteuer_param_kirchensteuer = "3";
            s_r_de_einkommensteuer_param_splitting = "nein";
        `}
          </Script>
          <Script
            type="text/javascript"
            charset="utf-8"
            src="https://load.smart-rechner.de/widget/einkommensteuer.js"
          ></Script>
        </div>
      </div>
    )
  }
}

export default EinkommensteuerRechner

import React from 'react'

function calculateCashflow() {
  // Betrieblicher Cashflow
  let einnahmen = parseFloat(document.getElementById('einnahmen').value)
  let betriebskosten = parseFloat(document.getElementById('betriebskosten').value)
  let abschreibungen = parseFloat(document.getElementById('abschreibungen').value)
  let steuerzahlungen = parseFloat(document.getElementById('steuerzahlungen').value)
  let betrieblicherCashflow = einnahmen - betriebskosten - steuerzahlungen + abschreibungen

  // Cashflow aus Investitionstätigkeit
  let kapitalausgaben = parseFloat(document.getElementById('kapitalausgaben').value)
  let verkaufAnlage = parseFloat(document.getElementById('verkaufAnlage').value)
  let cashflowInvest = verkaufAnlage - kapitalausgaben

  // Cashflow aus Finanzierungstätigkeit
  let darlehenAufnahme = parseFloat(document.getElementById('darlehenAufnahme').value)
  let darlehenTilgung = parseFloat(document.getElementById('darlehenTilgung').value)
  let dividenden = parseFloat(document.getElementById('dividenden').value)
  let cashflowFinanz = darlehenAufnahme - darlehenTilgung - dividenden

  // Gesamter Cashflow
  let gesamterCashflow = betrieblicherCashflow + cashflowInvest + cashflowFinanz

  // Ergebnis anzeigen
  document.getElementById('resultDiv').innerHTML =
    'Gesamter Cashflow: ' + gesamterCashflow.toFixed(2) + ' €'
}

class CashflowRechner extends React.Component {
  render() {
    return (
      <>
      <h2>Cashflow Berechnung</h2>
          <form id="cashflowForm">
            <fieldset>
              <legend>Betrieblicher Cashflow</legend>
              <label htmlFor="einnahmen">Einnahmen:</label>
              <input type="number" id="einnahmen" required />
              <br />
              <br />
              <label htmlFor="betriebskosten">Betriebskosten:</label>
              <input type="number" id="betriebskosten" required />
              <br />
              <br />
              <label htmlFor="abschreibungen">Abschreibungen:</label>
              <input type="number" id="abschreibungen" required />
              <br />
              <br />
              <label htmlFor="steuerzahlungen">Steuerzahlungen:</label>
              <input type="number" id="steuerzahlungen" required />
              <br />
              <br />
            </fieldset>

            <fieldset>
              <legend>Investitionstätigkeit</legend>
              <label htmlFor="kapitalausgaben">Kapitalausgaben:</label>
              <input type="number" id="kapitalausgaben" required />
              <br />
              <br />
              <label htmlFor="verkaufAnlage">Einnahmen aus dem Verkauf von Anlagevermögen:</label>
              <input type="number" id="verkaufAnlage" required />
              <br />
              <br />
            </fieldset>

            <fieldset>
              <legend>Finanzierungstätigkeit</legend>
              <label htmlFor="darlehenAufnahme">Aufnahme von Darlehen:</label>
              <input type="number" id="darlehenAufnahme" required />
              <br />
              <br />
              <label htmlFor="darlehenTilgung">Tilgung von Darlehen:</label>
              <input type="number" id="darlehenTilgung" required />
              <br />
              <br />
              <label htmlFor="dividenden">Dividendenzahlungen:</label>
              <input type="number" id="dividenden" required />
              <br />
              <br />
            </fieldset>
            <input type="button" value="Berechnen" onClick={calculateCashflow} />
          </form>
          <div className="result" id="resultDiv"></div>
          </>
    )
  }
}

export default CashflowRechner

import React, { createContext, useEffect, useState } from "react";
export const socketContext = createContext();
const { io } = require("socket.io-client");

export default function SocketProvider({ children }) {
  const [enabled, setEnabled] = useState(false)
  const [socket, setSocket] = useState();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.search?.startsWith('?chatbot=customersuccess')) setEnabled(true)
    }
  }, [])
  useEffect(() => {
    if(enabled){
      setSocket(
        io(process.env.GATSBY_CHATBOT_SERVER, {
          transports: ['polling'],
          allowEIO3: true
        })
      );
    }
  }, [enabled]);
  useEffect(() => {
    if (socket) {
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
    }
  }, [socket]);
  return (
    <socketContext.Provider value={{ socket }}>
      {children}
    </socketContext.Provider>
  );
}

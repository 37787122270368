import React, { useState } from 'react'
import * as styles from './SupportFaq.module.scss'

let contentFunktionen = [
  {
    question: 'Wie vergrößere ich mein hochgeladenes Logo?',
    answer:
      'Aktuell kannst du dein Logo anpassen, indem du ein <a href="https://hilfe.sevdesk.de/de/knowledge/eigenes-briefpapier-hinterlegen" title="Eigenes Briefpapier hinterlegen" target="_blank" rel="noopener">Briefpapier</a> hochlädst, welches bereits das Logo in der gewünschten Größe enthält.',
  },
  {
    question: 'Kann ich beim Versand einer Rechnung per E-Mail einen Anhang hinzufügen?',
    answer:
      'Ja. Wenn du eine Rechnung per E-Mail versendest, kannst du vor dem Versenden Anhänge beliebig hinzufügen. Wenn es sich um einen Anhang handelt, der zu jeder E-Mail hinzugefügt werden soll (z.B. AGB), kannst du unter Einstellungen > System > Anhänge einen Anhang hochladen. Dieser wird dann in deinen E-Mails automatisch hinzugefügt.',
  },
  {
    question: 'Ist sevDesk mehrmandantenfähig?',
    answer:
      'Nein, sobald du mehrere Steuernummern hast, ist dies über weitere Accounts abzubilden. Bei gleicher Steuernummer kannst du mit mehreren Kostenstellen arbeiten.',
  },
  {
    question: 'Wie kann ich meinen eigenen Nummernkreis in sevDesk fortführen?',
    answer:
      'Unter Einstellungen > Buchhaltung > Nummernkreise kannst du einen Nummernkreis pro Dokumententyp individuell hinterlegen.',
  },
]

let contentVertrag = [
  {
    question: 'Was passiert nach meiner Testphase?',
    answer:
      'Deine kostenlose Testphase endet ganz automatisch. Für dich entstehen dabei keinerlei Kosten. Wenn du sevDesk weiter nutzen möchtest, kannst du dich für einen Tarif entscheiden, der zu dir passt.',
  },
  {
    question: 'Sind meine Daten trotz Cloud sicher?',
    answer:
      'All deine Daten sind trotz der Speicherung in einer Cloud sicher. Sie werden per TLS verschlüsselt übertragen und bewusst in einem zertifizierten, Rechenzentrum mit Serverstandort in Deutschland gespeichert. Erfahre mehr zum Thema <a href="/sicherheit/" title="Sicherheit" target="_blank" rel="noopener">Sicherheit</a>.',
  },
  {
    question: 'Wie kann ich einen Tarif buchen?',
    answer:
      'Zuerst musst du dich kostenlos unter „kostenlos testen“ anmelden. Um einen Tarif zu buchen, klicke im Hauptmenü auf <strong>Mein sevDesk</strong> und dann auf <strong>Tarif</strong>. Wähle den Tarif aus, den du buchen möchtest. <strong>Beachte!</strong> Wählst du die Laufzeit 12 Monate oder 24 Monate, wird der gesamte Betrag für die Laufzeit auf einmal im Voraus fällig. <br />Eine <a href="https://hilfe.sevdesk.de/de/knowledge/tarif-buchen-%C3%A4ndern#buchen" title="Anleitung Buchen" target="_blank" rel="noopener">Schritt-für-Schritt-Anleitung</a> dazu findest du in unserer Wissensdatenbank.',
  },
  {
    question: 'Welche Zahlungsmöglichkeiten gibt es?',
    answer:
      'Die Gebühren für dein Abo werden monatlich via Kreditkarte oder PayPal eingezogen. Jahresabos werden einmalig pro Jahr im Voraus abgerechnet.',
  },
  {
    question: 'Kann ich meinen gebuchten Tarif nachträglich noch ändern?',
    answer:
      'Du kannst jederzeit über die Einstellungen in der Software in einen höheren Tarif wechseln. Wenn du in einen günstigeren Tarif wechseln möchtest, melde dich einfach bei unserem <a href="https://landing.sevdesk.de/service-support-center-anfrage" title="Support">Support</a>.',
  },
  {
    question: 'Wie kann ich meinen Tarif wechseln?',
    answer:
      'Gehe im Hauptmenü zu <strong>Mein sevDesk</strong> und dann <strong>Tarif</strong>. Hier kannst du deinen bisherigen Tarif wechseln. Nach der Auswahl <strong>Tarif wechseln</strong> kannst du zu einem höheren Tarif oder einer längeren Laufzeit upgraden. <br />Eine <a href="https://hilfe.sevdesk.de/de/knowledge/tarif-buchen-%C3%A4ndern#wechseln" title="Anleitung Wechseln" target="_blank" rel="noopener">Schritt-für-Schritt-Anleitung</a> dazu findest du in unserer Wissensdatenbank.',
  },
  {
    question: 'Wie buche ich ein Add-on? Wie kündige ich ein Add-on?',
    answer:
      'Add-ons sind nur in Verbindung mit einer regulären Tarifbuchung verfügbar. Du kannst sie jederzeit über dein sevDesk Account buchen. In diesem <a href="https://hilfe.sevdesk.de/de/knowledge/add-ons-%C3%BCbersicht#buchen">Hilfeartikel</a> zeigen wir dir, wie du ein Add-on buchst.<br />Du kannst ein Add-on immer zum Ende der entsprechenden Laufzeit - monatlich - kündigen. Bitte beachte: Die Kündigungsfrist beträgt 14 Tage. <a href="https://landing.sevdesk.de/service-support-center-tarif-addons" target="_blank" rel="noopener">Hier</a> findest du unser Kündigungsformular.',
  },
  {
    question: 'Wie kann ich meinen Vertrag kündigen?',
    answer:
      'Die Kündigungsfrist beträgt 14 Tage zum Ende des Abonnements. Einen Monatstarif kündigst du monatlich, bei einem Jahrestarif bis zum Ende des Jahresabos. Deine Kündigungsanfrage sendest du uns bitte über dieses <a href="https://landing.sevdesk.de/kuendigung1" title="Formular" target="_blank" rel="noopener">Formular</a>. Bitte beachte: dein Vertrag verlängert sich automatisch um die gebuchte Laufzeit, wenn du dein Abonnement nicht kündigst.',
  },
  {
    question: 'Was passiert mit meinen Daten nach der Kündigung?',
    answer:
      'Eine GOBD-konforme Speicherung deiner Daten ist nur im Rahmen eines aktiven sevDesk Abos möglich. Nach Ablauf des Abos wird dein Account gesperrt. Wir empfehlen dir deshalb vor Vertragsende all deine Daten aus sevDesk zu exportieren. Natürlich ist es möglich nochmal Zugriff zu gewähren und Backups von deinen Daten zu exportieren, jedoch werden im Laufe der Zeit Serverbereinigungen durchgeführt. Wende dich dazu bitte an <a href="mailto:support@sevdesk.de">support@sevdesk.de</a>.',
  },
  {
    question: 'Wie verwalte ich meine Benutzer und deren Berechtigungen?',
    answer:
      'Gehe für die Nutzerverwaltung zu <strong>Einstellungen</strong> und <strong>Benutzer</strong>.</br>Öffne den entsprechenden Benutzer, um seine Daten zu bearbeiten. Du kannst die Benutzerdaten auf der linken Seite ändern und auf der rechten Seite die Berechtigungen festlegen. Die Berechtigungen sind überwiegend in die Navigationspunkte unterteilt. Entferne einen Haken und der Menüpunkt erscheint beim jeweiligen Benutzer nicht im System.',
  },
  {
    question: 'Was muss ich tun, wenn ich einen Benutzerzugang entfernen möchte?',
    answer:
      'Das alleinige Löschen eines Benutzers hat keine Auswirkungen auf deine gebuchte Komponente. Die Kündigung ist immer zum Ende des gebuchten Tarifs möglich. <ul><li>Kündige das Add-on bevor du den Benutzer löschst</li><li>Gehe zu Einstellungen</li><li>Klicke hier auf Benutzer</li><li>Über das Papierkorb-Symbol kannst du den Benutzer löschen</li></ul><strong>Beachte!<span></span> </strong>Die Kündigungsfrist beträgt 14 Tage.</br>Weitere Informationen findest du in unserem <a href="https://hilfe.sevdesk.de/de/knowledge/benutzerdaten-und-berechtigungen-%C3%A4ndern#loeschen">Hilfeartikel</a>.',
  },
]

export default function SupportFaq() {
  const [toggle, setToggle] = useState(1)
  const [clicked, setClicked] = useState(null)
  function showCategory(id) {
    setToggle(id)
  }
  const showAccordionItem = (i) => {
    if (clicked == i) {
      return setClicked(null)
    }
    setClicked(i)
  }
  return (
    <div className={styles.container}>
      <h2>Häufig gestellte Fragen</h2>
      <div className={styles.categories}>
        <ul>
          <li
            className={toggle === 1 ? styles.categoryActive : styles.category}
            onClick={() => showCategory(1)}
          >
            Funktionen und Anwendungen
          </li>
          <li
            className={toggle === 2 ? styles.categoryActive : styles.category}
            onClick={() => showCategory(2)}
          >
            Dein Vertrag bei sevDesk
          </li>
        </ul>
      </div>
      <div className={toggle === 1 ? styles.showAccordion : styles.hideAccordion}>
        {contentFunktionen?.map((item, i) => {
          return (
            <div className={styles.accordionContent} key={i}>
              <div className={styles.question} onClick={() => showAccordionItem(i)}>
                <div>{item.question}</div>
                <div>
                  <strong>{clicked == i ? '-' : '+'}</strong>
                </div>
              </div>
              <div
                className={clicked == i ? styles.show : styles.hide}
                dangerouslySetInnerHTML={{ __html: item.answer }}
              ></div>
            </div>
          )
        })}
      </div>
      <div className={toggle === 2 ? styles.showAccordion : styles.hideAccordion}>
        {contentVertrag?.map((item, i) => {
          return (
            <div className={styles.accordionContent} key={i}>
              <div className={styles.question} onClick={() => showAccordionItem(i)}>
                <div>{item.question}</div>
                <div>
                  <strong>{clicked == i ? '-' : '+'}</strong>
                </div>
              </div>
              <div
                className={clicked == i ? styles.show : styles.hide}
                dangerouslySetInnerHTML={{ __html: item.answer }}
              ></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

import React from 'react'
import { Link } from 'gatsby'
import * as styles from './result.module.scss'

const ResultAsList = ({ hits, children, mapFunc, input }) =>
  input && (
    <div className={styles.autocomplete__list}>
      <ul>
        {hits?.map(mapFunc)}
        {children}
      </ul>
    </div>
  ) 

export default ResultAsList

import React, { Component } from 'react'
import FristenRechner from '../../calculator/Fristenrechner'

class FristenRechnerCustomTemplate extends Component {
  render() {
    return (
      <div className="container p-5">
        <div className="align-items-center text-center" style={{ zIndex: '0' }}>
          <FristenRechner />
        </div>
      </div>
    )
  }
}

export default FristenRechnerCustomTemplate

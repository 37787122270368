import React from 'react'
import { Script } from 'gatsby'

class MargenRechner extends React.Component {
  render() {
    return (
      <div className="calc">
        <div id="s_r_de_margen">
          <Script type="text/javascript">
            {`
            s_r_de_margen_premium = true;
            s_r_de_margen_theme = "smoothness";
            s_r_de_margen_responsive = true;
            s_r_de_margen_slidedown = false;
            s_r_de_margen_openresult = false;
            s_r_de_margen_whitelabel = false;
            s_r_de_margen_charts = false;
            s_r_de_margen_title = "";
            s_r_de_margen_adlink = "";
            s_r_de_margen_adtext = "";
            s_r_de_margen_adtarget = "_blank";
            s_r_de_margen_adsize = "";
            s_r_de_margen_adlink_requestmethod = "post";
            s_r_de_margen_proxy_url = "";
        `}
          </Script>
          <Script
            type="text/javascript"
            charset="utf-8"
            src="https://load.smart-rechner.de/widget/margen.js"
          ></Script>
        </div>
      </div>
    )
  }
}

export default MargenRechner

// extracted by mini-css-extract-plugin
export var accordionContent = "SupportFaq-module--accordionContent--c31ae";
export var answer = "SupportFaq-module--answer--58176";
export var categories = "SupportFaq-module--categories--e1759";
export var category = "SupportFaq-module--category--5ffb4";
export var categoryActive = "SupportFaq-module--categoryActive--43a60";
export var container = "SupportFaq-module--container--1ab85";
export var hide = "SupportFaq-module--hide--4296a";
export var hideAccordion = "SupportFaq-module--hideAccordion--f17d6";
export var question = "SupportFaq-module--question--15f20";
export var show = "SupportFaq-module--show--407de";
export var showAccordion = "SupportFaq-module--showAccordion--0e28f";
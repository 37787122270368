import React from 'react'
import { Script } from 'gatsby'

class ProzentRechner extends React.Component {
  render() {
    return (
      <div className="calc">
        <div id="s_r_de_prozent">
          <Script type="text/javascript">
            {`
            s_r_de_prozent_premium = true;
            s_r_de_prozent_theme = "smoothness";
            s_r_de_prozent_responsive = true;
            s_r_de_prozent_slidedown = false;
            s_r_de_prozent_openresult = false;
            s_r_de_prozent_whitelabel = false;
            s_r_de_prozent_charts = false;
            s_r_de_prozent_title = "";
            s_r_de_prozent_adlink = "";
            s_r_de_prozent_adtext = "";
            s_r_de_prozent_adtarget = "_blank";
            s_r_de_prozent_adsize = "";
            s_r_de_prozent_adlink_requestmethod = "post";
            s_r_de_prozent_proxy_url = "";
            s_r_de_prozent_param_fragestellung = "1";
        `}
          </Script>
          <Script
            type="text/javascript"
            charset="utf-8"
            src="https://load.smart-rechner.de/widget/prozent.js"
          ></Script>
        </div>
      </div>
    )
  }
}

export default ProzentRechner

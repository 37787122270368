import defaultCampaigns from '../../../data/campaigns.json';
import defaultDiscounts from '../../../data/discounts.json';
import { getCookie, setCookie, setCrossDomainCookie } from '../../../utils';
import { checkPathsAndBlacklist } from '../../../utils/atlas-paths';


// NEU 
const checkCampaignHasNearerExpireDate = (campaign, validCampaign) => {
  const now = new Date().getTime();
  const counter = campaign?.counters
  ?.find((counter) => isValidCounter(counter?.endDate))
  const validCounter = validCampaign?.counters
  ?.find((counter) => isValidCounter(counter?.endDate))

  if(new Date(counter?.endDate).getTime() - now < new Date(validCounter?.endDate).getTime() - now){
    return true
  }
}


export const getDiscountOrCampaign = async() => {
  try {
    let discounts = defaultDiscounts
    let campaigns = defaultCampaigns
    let preview;
    if (typeof window !== 'undefined') {
      //* Preview Stuff
      const searchParams = new URLSearchParams(window.location.search);
      const actionPreview = searchParams.get("actionPreview") ?? sessionStorage.getItem("actionPreview")
      if(actionPreview){
        sessionStorage.setItem("actionPreview", actionPreview)

        const responseData = await fetch(`${process.env.GATSBY_SEVATLAS_BACKEND}/campaigns/preview/${actionPreview}`);
        const response = await responseData.json();

        if(response?.success){
          preview = response?.result;
          console.log("prev", preview)
          if(preview?.type === "campaign"){
            //console.log("repsjdifsd", response);
            campaigns = [preview?.previewData]
            discounts = []
          } else {
            discounts = [preview?.previewData]
          }
        }
      }
      //* Campaign Stuff
      let validCampaign
      campaigns.map((campaign, index) => {
        const now = new Date().getTime()
        const startDate = new Date(campaign?.startDate).getTime()
        const expireDate = new Date(campaign?.expireDate).getTime()
        if (startDate <= now && now <= expireDate) {
          if (
            !validCampaign ||
            checkCampaignHasNearerExpireDate(campaign, validCampaign)
            // expireDate - now < new Date(validCampaign?.expireDate).getTime() - now
          ) {
            validCampaign = campaign
          }
        }
      })
      //* Individual Discount Stuff
      //? Find all valid discounts, if there are more than one valid
      // const validDiscounts = discounts.map((discount, index) => {
      //   const areConditionsMet = new Function('cookies', 'userAgent', discount?.conditions)(
      //     document.cookie,
      //     navigator.userAgent,
      //   )
      //   //console.log("CONDITIONSMET", areConditionsMet)
      //   // const arePathsMet = discount?.paths?.includes(location.pathname) || discount?.paths?.includes("*")
      //   const arePathsMet = checkPathsAndBlacklist(discount?.paths)
      //   //   //console.log("PATHJ", areConditionsMet)
      //   if ((areConditionsMet && arePathsMet) || getCookie('discount') === discount?._id) {
      //     return discount
      //   }
      // })
      let validDiscounts = []
      try{     
        validDiscounts = discounts
          .map((discount, index) => { 
          
            const areConditionsMet = new Function('cookies', 'userAgent', discount?.conditions)(
              document.cookie,
              navigator.userAgent,
            )
  
            const arePathsMet = checkPathsAndBlacklist(discount?.paths)
  
            return {
              discount,
              index,
              areConditionsMet,
              arePathsMet,
            }
          })
          .filter(({ discount, areConditionsMet, arePathsMet }) => {
            const isDiscountFromCookie = getCookie('discount') === discount._id
            return (areConditionsMet && arePathsMet) || isDiscountFromCookie
          })
          .sort((a, b) => {
            // If a discount is from cookie and b isn't, prioritize b
            if (a.areConditionsMet && b.discount._id === getCookie('discount')) {
              return 1
            }
            // If b discount is from cookie and a isn't, prioritize a
            if (b.areConditionsMet && a.discount._id === getCookie('discount')) {
              return -1
            }
            // Otherwise, sort based on index (higher index comes later)
            return a.index - b.index
          })
          .map(({ discount }) => discount)
      } catch(e){
        console.error(e);
      }



      //? Check which is the nearest discount and select the one
      let validDiscount
      validDiscounts.map((individualDiscount, index) => {
        individualDiscount?.discounts?.map((discount, index) => {
          const now = new Date().getTime()
          const startDate = new Date(discount?.startDate).getTime()
          const expireDate = new Date(discount?.expireDate).getTime()
          if (startDate <= now && now <= expireDate) {
            if (validDiscount?.type === 'global' || validDiscount?.type === undefined) {
              validDiscount = discount
              if (process.env.NODE_ENV !== 'production') {
                setCookie('discount', individualDiscount?._id)
              } else {
                setCrossDomainCookie('discount', individualDiscount?._id)
              }
            }
          }
        })
      })
      if(actionPreview){
        validDiscount = preview?.previewData?.discounts?.[0] ?? preview?.previewData
      }
      //? Check counter of valid discount
      if (validDiscount?.counterMode === 'global') {
        validDiscount.counters = validCampaign?.counters //TODO: here we need to insert the counters of the global campaign
      }
      if(validCampaign){
        const clientCookie = getCookie("sevInfo");
        if(clientCookie.includes("p-a") || clientCookie.includes("p-b") || clientCookie.includes("p-c")){
          validCampaign = undefined
        }
      } 
      return validDiscount ?? validCampaign
    }
  } catch (err) {
    console.log(err)
  }
}
export const isValidCounter = (endDate) => {
  // //console.log(endDate)
  const now = new Date().getTime()
  const end = new Date(endDate?.replaceAll("'", '')).getTime()
  return end - now > 0
}

if(typeof window !== 'undefined'){
  window.getDiscountOrCampaign = getDiscountOrCampaign;
  window.isValidCounter = isValidCounter;
}

import React, { Component } from 'react'
import KreditRechner from '../../calculator/Kreditrechner'

class KreditRechnerCustomTemplate extends Component {
  render() {
    return (
      <div className="container p-5">
        <div className="align-items-center text-center" style={{ zIndex: '0' }}>
          <KreditRechner />
        </div>
      </div>
    )
  }
}

export default KreditRechnerCustomTemplate

import React from 'react'
import Image from '../../base/Image'
import './known.css'

const KnownBadges = () => {
  const icons = trustIcons[process.env.GATSBY_LOCALE]

  return (
    <div className="hero-known-test d-none d-md-flex" listener="true">
      <p className="hero-known-test-headline">sevDesk ist bekannt aus</p>
      {icons.map(({ img }, i) => {
        return (
          <Image
            key={i}
            className="hero-known-test-item"
            alt={img.alt}
            src={`${img.src}?w=${img.w}&h=${img.h}&auto=format,compress&q=75`}
            width={img.w}
            height={img.h}
          />
        )
      })}
    </div>
  )
}

export default KnownBadges

const trustIconsShared = [
  {
    img: {
      alt: 'omr reviews',
      src: 'https://sevdesk.imgix.net/34060/1663940884-omr-reviews.png',
      w: 160,
      h: 40,
    },
  },
  {
    img: {
      alt: 'chip',
      src: 'https://sevdesk.imgix.net/34060/1658259923-logo_ohne_claim_chip_rgb.png',
      w: 160,
      h: 40,
    },
  },
  {
    img: {
      alt: 'ntv',
      src: 'https://sevdesk.imgix.net/34060/1664523693-ntv.jpeg',
      w: 160,
      h: 40,
    },
  },
]

const trustIcons = {
  de: [
    {
      img: {
        alt: 'Gründer DE',
        src: 'https://sevdesk.imgix.net/34060/1663940653-grunder-de_logo.png',
        w: 160,
        h: 40,
      },
    },
    {
      img: {
        alt: 't3n',
        src: 'https://sevdesk.imgix.net/34060/1663940823-t3n-logo-press.png',
        w: 160,
        h: 40,
      },
    },
    {
      img: {
        alt: 'welt',
        src: 'https://sevdesk.imgix.net/34060/1608553944-welt.png',
        w: 160,
        h: 40,
      },
    },
    ...trustIconsShared,
  ],
  'de-AT': [
    {
      img: {
        src: 'https://sevdesk.imgix.net/34060/1628155779-atv.png',
        alt: 'ATV und sevDesk',
        w: 160,
        h: 40,
      },
    },
    {
      img: {
        src: 'https://sevdesk.imgix.net/34060/1640330757-orf.png',
        alt: 'ORF und sevDesk',
        w: 160,
        h: 40,
      },
    },
    {
      img: {
        src: `https://sevdesk.imgix.net/34060/1608553938-servustv.png`,
        alt: 'ServusTV und sevDesk',
        w: 160,
        h: 40,
      },
    },
    ...trustIconsShared,
  ],
}

import React, { useState, useEffect } from 'react';
import './Typewriter.css';

const Typewriter = ({ typedArray, typingSpeed = 75, deleteSpeed = 50, pauseDuration = 50 }) => {
    const [wordIndex, setWordIndex] = useState(0);
    const [typedText, setTypedText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        if (wordIndex >= typedArray.length) {
            setWordIndex(0);
        }

        const currentWord = typedArray[wordIndex];

        let timer;
        if (isDeleting) {
            timer = setTimeout(() => {
                setTypedText(typedText.slice(0, -1));
                if (typedText.length === 1) {
                    setIsDeleting(false);
                    setWordIndex((prevWordIndex) => (prevWordIndex + 1) % typedArray.length);
                    setTimeout(() => { }, pauseDuration);
                }
            }, deleteSpeed);
        } else {
            timer = setTimeout(() => {
                setTypedText(currentWord.substring(0, typedText.length + 1));
                if (typedText.length === currentWord.length - 1) {
                    setTimeout(() => { setIsDeleting(true); }, pauseDuration);
                }
            }, typingSpeed);
        }

        return () => clearTimeout(timer);

    }, [typedText, isDeleting, wordIndex]);

    return (
        <span className="typewriter-container">
            {typedText} {' '}
            <span className="typewriter-cursor"></span>
        </span>
    );
};

export default Typewriter;

// Usage:
// <Typewriter typedArray={['Händler.','Grafikdesigner & Fotografen.','Freiberufler.','Handwerker.','jeden Selbständigen.']} />

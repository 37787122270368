import React, { useEffect, useRef, useState } from 'react'
import * as styles from './BottomBanner.module.scss'
import { useDiscountContext } from '../../hooks/useDiscountContext'
import { isTodayInDateRanges } from '../../utils'
let formatNumber = (number) => {
  return number < 10 ? `0${number}` : number
}

export default function BottomBanner({ storageKey = 'sticky-bottom-banner-closed', text }) {
  const containerRef = useRef()
  const { target, ...discount } = useDiscountContext()
  const [show, setShow] = useState(true)
  const [date, setDate] = useState({})
  //   useEffect (() => {
  //     const myScrollFunc = function () {
  //       if (window.scrollY >= 0) {
  //         setShow(true)
  //       } else {
  //         setShow(false)
  //       }
  //     }    
  //     window.addEventListener('scroll', myScrollFunc)
  //     setShow(true) 
  // }, [])
  const closeBanner = () => {
    setShow(false)
    window.localStorage.setItem(storageKey, new Date().toISOString())
  }
  const showCounterBanner = isTodayInDateRanges(discount?.counters)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const bannerDurationInDays = 3

      const bannerClosedTimestamp = window.localStorage.getItem(storageKey)

      if (!bannerClosedTimestamp) {
        // Banner hasn't been closed before, so display it
        // setShow(false)
      } else {
        const currentDate = new Date()
        const closedDate = new Date(bannerClosedTimestamp)
        const daysSinceClosed = Math.round((currentDate - closedDate) / (1000 * 60 * 60 * 24))
        if (daysSinceClosed >= bannerDurationInDays) {
          // Banner has been closed for more than the specified duration, so display it
          window.localStorage.removeItem(storageKey)
          // setShow(false)
        }
      }
      setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime()

        // Find the distance between now and the count down date
        var distance = target - now

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)

        setDate({
          days: formatNumber(days),
          hours: formatNumber(hours),
          minutes: formatNumber(minutes),
          seconds: formatNumber(seconds),
        })
        if (distance < 0) {
          clearInterval(x)
        }
      }, 1000)
    }
  }, [])
  return (
    <a
      href="/preise/"
      ref={containerRef}
      className={`${styles.stickyBannerBottom} ${show ? styles.showBanner : styles.hideBanner}`}
    >
      <div className={styles.stickyBannerBottomContainer}>
        <div className={styles.stickyBannerBottomInner}>
          <div>
            <div className={styles.stickyBannerBottomText} dangerouslySetInnerHTML={{ __html: text ? text : 'Nur für kurze Zeit! <br/>Spare bis zu <span style="color: #78cfc0">50%</span>' }}></div>
          </div>
          {discount?.counterEnabled && showCounterBanner && (
            <div className={styles.megaTopBannerWrapperCounter} id="mega-top-banner-wrapper-counter">
              <div className={styles.megaTopBannerCounterField}>
                <div className={styles.megaTopBannerCounterNumber} id="mega-top-banner-counter-d">
                  {date?.days ?? '00'}
                </div>
                <div className={styles.megaTopBannerCounterMark}>Tage</div>
              </div>
              <div className={styles.megaTopBannerCounterField}>
                <div className={styles.megaTopBannerCounterNumber} id="mega-top-banner-counter-h">
                  {date?.hours ?? '00'}
                </div>
                <div className={styles.megaTopBannerCounterMark}>Std</div>
              </div>
              <div className={styles.megaTopBannerCounterField}>
                <div className={styles.megaTopBannerCounterNumber} id="mega-top-banner-counter-m">
                  {date?.minutes ?? '00'}
                </div>
                <div className={styles.megaTopBannerCounterMark}>Min</div>
              </div>
              <div className={styles.megaTopBannerCounterField}>
                <div className={styles.megaTopBannerCounterNumber} id="mega-top-banner-counter-s">
                  {date?.seconds ?? '00'}
                </div>
                <div className={styles.megaTopBannerCounterMark}>Sek</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </a>
  )
}

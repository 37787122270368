import React, { Component } from 'react'
import CashflowRechner from '../calculator/Cashflowrechner'

class CashflowRechnerCustomTemplate extends Component {
  render() {
    return (
      <div className="container l-section--padding">
        <div className="d-flex flex-column align-items-center">
          <CashflowRechner />
        </div>
      </div>
    )
  }
}

export default CashflowRechnerCustomTemplate

import React, { lazy } from 'react'

// import moment from 'moment'
import { LivePreview, LiveProvider } from 'react-live'
import { getValidDiscount } from '../../assets/js/utils/getValidDiscount'
import EmailFormField from '../../components/form/EmailFormField'
import HeroLPA from '../../components/hero/HeroLPA'
import Hero from '../../components/hero/HeroPagebuilder'
import HeroTyped from '../../components/hero/HeroTyped'
import HeroTypedForm from '../../components/hero/HeroTypedForm'
import HeroTypedLexikon from '../../components/hero/HeroTypedLexikon'
import { customContentIds } from '../../data/customContentIds'
// const { CountdownComponent } = import('../../components/functional/CountdownComponent')
// import ImageSlider from '../../components/carousel/ImageSliderPagebuilder'
// import TestimonialSlider from '../../components/carousel/TestimonialSliderPageBuilder'
import DiscountComponent from '../../components/discount/index'
import { CountdownComponent } from '../../components/functional/CountdownComponent'

const ImageSlider = lazy(() => import('../../components/carousel/ImageSliderPagebuilder'))
const TestimonialSlider = lazy(() => import('../../components/carousel/TestimonialSliderPageBuilder'))
const TrustIcons = lazy(() => import('../../components/trust-icons/trustBadges/index'))
const CustomTrustIcons = lazy(() =>
  import('../../components/trust-icons/AllCustomTrustIconsPageBuilder'),
)
const Button = lazy(() => import('../../components/button'))
const CTA = lazy(() => import('../../components/cta'))
const FounderCTA = lazy(() => import('../../components/newFounderCTA'))
const Card = lazy(() => import('../../components/cards'))
const CustomSection = lazy(() =>
  import('../../components/flexible-content/content-section/CustomSection'),
)
const Title = lazy(() => import('../../components/base/Title'))
const SwitchContentComponent = lazy(() =>
  import('../../components/flexible-content/SwitchComponentPageBuilder'),
)
const ShoppingLPComponentNew = lazy(() => import('../../components/shoppingLP/indexNew'))
const ZusammenarbeitColumn = lazy(() =>
  import('../../components/feature-table/zusammenarbeitTable'),
)
const Inhaltsverzeichnis = lazy(() => import('../../components/inhaltsverzeichnis'))
// const TestimonialSlider = lazy(() =>
//   import('../../components/carousel/TestimonialSliderPageBuilder'),
// )
const FeatureTableSale = lazy(() => import('../../components/feature-table/FeatureTableSale'))
const FeatureTable = lazy(() => import('../../components/feature-table/FeatureTable'))
const FeatureTableLong = lazy(() => import('../../components/feature-table/FeatureTableLong'))
const PricingTableReduced = lazy(() => import('../../components/feature-table/pricingTableReduced'))
const FeatureTableAccountingPartnerKanzleien = lazy(() =>
  import('../../components/feature-table/FeatureTableAccountingPartnerKanzleien'),
)
// const DiscountComponent = lazy(() => import('../../components/discount/index'))
// const ImageSlider = lazy(() => import('../../components/carousel/ImageSliderPagebuilder'))
const FeatureBoxes = lazy(() => import('../../components/flexible-content/FeatureBoxes'))
const RecruteeWidget = lazy(() => import('../../components/karriere/RecruteeWidget'))
const RatgeberStoererbox = lazy(() => import('../../components/ratgeber/ratgeber-stoererbox'))
const News = lazy(() => import('../../components/flexible-content/NewsFeedPageBuilder'))
const VideoModal = lazy(() => import('../../components/modal/Video'))
const Accordion = lazy(() => import('../../components/base/Accordion'))
const FAQ = lazy(() => import('../../components/flexible-content/FaqPagebuilder'))
const RelevanteBeitraege = lazy(() => import('../../components/fixed-content/RelevanteBeitraege'))
const FeatureColumn = lazy(() => import('../../components/feature-column'))
const ContentSection = lazy(() => import('../../components/content-section-scoped'))
const IntegrationComponent = lazy(() =>
  import('../../components/integration/integrationComponent/IntegrationComponent'),
)

const customComponent = (content) => {
  var css1 = content?.customCss?.replace('<p>', '')
  var css = css1?.replace('</p>', '')
  var js1 = content?.customJs?.replace('<p>', '')
  var js = js1?.replace('</p>', '')
  return (
    <>
      <style>{css}</style>
      <div
        dangerouslySetInnerHTML={{ __html: content?.customHtml }}
        className={`${content?.parentClassname} ${content?.animation}`}
      ></div>
      <script>{js}</script>
    </>
  )
}

const shoppinglp = (content) => (
  <ShoppingLPComponentNew
    imageArray={content.imageSlider[0]}
    shortDescription={content.shortDescription}
    description={content.description}
    faqContent={content.accordion[0].accordionitems}
    rate={content.rate}
    coupon24={content.discount[0].code24}
    discount24={content.discount[0].discount24}
    duration24={content.discount[0].duration24}
    coupon12={content.discount[0].code12}
    discount12={content.discount[0].discount12}
    duration12={content.discount[0].duration12}
    coupon1={content.discount[0].code1}
    discount1={content.discount[0].discount1}
    duration1={content.discount[0].duration1}
  ></ShoppingLPComponentNew>
)

const integrationComponent = (content) => {
  return <IntegrationComponent data={content} />
}
const relevanteBeitraege = (content) => {
  if (content.aktive) {
    return <RelevanteBeitraege title={content.title} standard />
  } else {
    return (
      <RelevanteBeitraege
        title={content.title}
        blog={content.blog}
        lexikon={content.lexikon}
        wl={content.weiterfuehrendeLinks}
      />
    )
  }
}

const tableOfContent = (content) => (
  <Inhaltsverzeichnis
    title={content.title}
    inhaltsverzeichnisContent={content.ratgeberContent}
  ></Inhaltsverzeichnis>
)

const ratgeberStoererbox = (content) => (
  <>
    <RatgeberStoererbox
      title={content.title}
      blockId={content.blockId}
      content={content?.content}
      buttonText={content.buttonText}
      buttonUrl={content.buttonUrl}
    ></RatgeberStoererbox>
  </>
)

const faqComponent = (content) => <FAQ content={content.faqcontent} id={content.sectionId} />

const accordionContent = (content) => (
  <CustomSection padding center>
    <div className="col-md-10">
      <div className="c-section-header ">
        <h2 className="c-section-header__title display-2">{content.accordionTitle}</h2>
        <p className="c-section-header__description h3 mt-2 mb-3">{content.accordionSubtitle}</p>
      </div>
      <Accordion list={JSON.parse(content.accordionContent)} />
    </div>
  </CustomSection>
)

const accordionModel = (content) => (
  <CustomSection padding center>
    <div className="col-md-12">
      <div className="c-section-header ">
        <h2 className="c-section-header__title display-2">{content.accordionTitle}</h2>
        <p className="c-section-header__description h3 mt-2 mb-3">{content.accordionSubtitle}</p>
      </div>
      <Accordion list={content.accordionitems} />
    </div>
  </CustomSection>
)

const customId = (content) => customContentIds[content.contentId]

const hero = (content) => {
  const heroProps = [content.whiteText, content.overlay]

  if (content.heroAuswahl === 'Hero') {
    if (content.center) {
      if (content.buttons && content.buttons.length > 0) {
        return (
          <Hero
            heroProps={heroProps}
            imageUrl={content?.image?.url ? content.image.url : ''}
            center
            title={content.title}
            subtitle={content.subtitle}
            note={content.note}
            addClass={content.addClass}
            btnPrimary={
              content.buttons[0] &&
              content.buttons[0].buttonLinkziel && (
                <Button
                  buttonType={content.buttons[0].typeButton}
                  title={content.buttons[0].linkText}
                  url={content.buttons[0].buttonLinkziel}
                  responsiveVisability={content.buttons[0].responsiveVisability}
                />
              )
            }
            btnSecondary={
              content.buttons[1] &&
                content.buttons[1].buttonLinkziel &&
                content.buttons[1].buttonLinkziel &&
                content.buttons[1].buttonLinkziel === '#video' ? (
                <VideoModal title={content.buttons[1].linkText} />
              ) : content.buttons[1] &&
                content.buttons[1].typeButton &&
                content.buttons[1].typeButton === 'video' ? (
                <VideoModal
                  title={content.buttons[1].linkText}
                  url={content.buttons[1].buttonLinkziel}
                />
              ) : content.buttons[1] && content.buttons[1] ? (
                <Button
                  buttonType={content.buttons[1].typeButton}
                  title={content.buttons[1].linkText}
                  url={content.buttons[1].buttonLinkziel}
                  responsiveVisability={content.buttons[1].responsiveVisability}
                />
              ) : null
            }
          >
            {content.subtitle}
          </Hero>
        )
      } else {
        return (
          <Hero
            heroProps={heroProps}
            imageUrl={content?.image?.url ? content.image.url : ''}
            center
            title={content.title}
            subtitle={content.subtitle}
            note={content.note}
            addClass={content.addClass}
          >
            {content.subtitle}
          </Hero>
        )
      }
    } else if (content.buttons && content.buttons.length > 0) {
      return (
        <Hero
          heroProps={heroProps}
          imageUrl={content?.image?.url ? content.image.url : ''}
          title={content.title}
          subtitle={content.subtitle}
          note={content.note}
          addClass={content.addClass}
          btnPrimary={
            content.buttons[0] &&
            content.buttons[0].buttonLinkziel && (
              <Button
                buttonType={content.buttons[0].typeButton}
                title={content.buttons[0].linkText}
                url={content.buttons[0].buttonLinkziel}
                responsiveVisability={content.buttons[0].responsiveVisability}
              />
            )
          }
          btnSecondary={
            content.buttons[1] &&
              content.buttons[1].buttonLinkziel &&
              content.buttons[1].buttonLinkziel &&
              content.buttons[1].buttonLinkziel === '#video' ? (
              <VideoModal title={content.buttons[1].linkText} />
            ) : content.buttons[1] &&
              content.buttons[1].typeButton &&
              content.buttons[1].typeButton === 'video' ? (
              <VideoModal
                title={content.buttons[1].linkText}
                url={content.buttons[1].buttonLinkziel}
              />
            ) : content.buttons[1] && content.buttons[1] ? (
              <Button
                buttonType={content.buttons[1].typeButton}
                title={content.buttons[1].linkText}
                url={content.buttons[1].buttonLinkziel}
                responsiveVisability={content.buttons[1].responsiveVisability}
              />
            ) : null
          }
        >
          {content.subtitle}
        </Hero>
      )
    } else {
      return (
        <Hero
          heroProps={heroProps}
          imageUrl={content?.image?.url ? content.image.url : ''}
          title={content.title}
          subtitle={content.subtitle}
          note={content.note}
          addClass={content.addClass}
        >
          {content.subtitle}
        </Hero>
      )
    }
  } else if (content.heroAuswahl === 'LPA Hero') {
    return (
      <HeroLPA
        title={content.title}
        socialProof
        //trustIcon={content && content.customTrustIcon && content.customTrustIcon.url}
        checkmarks={content.checkmarks}
        imageMockup={content.image?.url}
        imageAlt={content.image?.alt}
      />
    )
  } else if (content.heroAuswahl === 'Typed Hero') {
    if (content.emailInput) {
      return (
        <HeroTypedForm
          title={content.title}
          typedText={content.typedText}
          typedOptions={content.typedOptions}
        />
      )
    } else if (content.branchenSuche) {
      return (
        <HeroTyped
          title={content.title}
          typedText={content.typedText}
          typedOptions={content.typedOptions}
        />
      )
    } else {
      return (
        <HeroTypedLexikon
          title={content.title}
          typedText={content.typedText}
          typedOptions={content.typedOptions}
        />
      )
    }
  }
}

const newsFeed = (content) => <News content={content.newsfeedContent} status={content.statusNews} />

const counter = (content) => (
  <CountdownComponent
    imported_discount={getValidDiscount()}
    showTitle={false}
    showName={false}
    showBtn={false}
    isPreise={true}
  />
)

const recruitee = (content) => {
  let departmentArray = content.category.map((item) => {
    return item.categoryName
  })
  return (
    <ContentSection
      direction="top"
      title={content?.title}
      content={content?.description}
      contentSectionId={content?.contentSectionId}
      contentBlockCenter={content?.textCenter}
    >
      <RecruteeWidget departmentFilter={departmentArray}></RecruteeWidget>
    </ContentSection>
    // <ContentBlockKarriere paddingTop paddingBottom center>
    //   <h1 className="text-center">{content.title}</h1>
    //   <p dangerouslySetInnerHTML={{ __html: content.description }}></p>
    //   <RecruteeWidget departmentFilter={departmentArray}></RecruteeWidget>
    // </ContentBlockKarriere>
  )
}

const trustIcon = (content) => {
  if (content.standardTrustIcons === true) {
    return <TrustIcons></TrustIcons>
  }
  return <CustomTrustIcons content={content} />
}

const switchComponent = (content) => (
  <SwitchContentComponent headline={content.title} list={content.records} />
)

const featureBox = (content) => (
  <FeatureBoxes
    title={content.title}
    subtitle={content.subtitle}
    items={content.boxes}
    id={content.sectionId}
  />
)

const articleCard = (content) => {
  if (content.option === 'ratgeber') {
    return (
      <>
        {(content.title || content.subtitle) && (
          <CustomSection id={content.sectionId}>
            <div className="col-12">
              <h2 className={`c-section-header__title h1 text-center`}>{content.title}</h2>
              <p className="mt-2 mb-3" style={{ color: '#5c626b' }}>
                {content.subtitle}
              </p>
            </div>
          </CustomSection>
        )}

        <div className="container">
          <div className="row">
            {content.cards.map((card, i) => {
              return (
                <Card
                  key={i}
                  centerContent={card?.textausrichtung === 'center'}
                  cardDescription={card.description && card.description}
                  tagline={card.kategorie}
                  title={card.cardTitle}
                  imageUrl={card.image && card.image.url && card.image.url}
                  cardLinkUrl={card.linkUrl && card.linkUrl}
                  cardLinkText={card.link && card.link}
                  cardSize={card.cardSize && card.cardSize}
                  setImageContain={card.setObjectFitContain && card.setObjectFitContain}
                  titleRatgeber
                  isInfoCard={card.isinfocard && card.isinfocard}
                ></Card>
              )
            })}
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        {/* <CustomSection padding center id={content.sectionId}>


        </CustomSection> */}
        <section className="l-section--padding" id={content.sectionId}>
          <div className="container">
            <div className="row">
              <Title center title={content.title} subtitle={content.subtitle} />
              {content.cards.map((card, i) => {
                return (
                  <Card
                    key={i}
                    centerContent={card?.textausrichtung === 'center'}
                    cardDescription={card.description && card.description}
                    tagline={card.kategorie}
                    title={card.cardTitle}
                    imageUrl={card.image && card.image.url && card.image.url}
                    cardLinkUrl={card.linkUrl && card.linkUrl}
                    cardLinkText={card.link && card.link}
                    cardSize={card.cardSize && card.cardSize}
                    setImageContain={card.setObjectFitContain && card.setObjectFitContain}
                    isInfoCard={card.isinfocard && card.isinfocard}
                  ></Card>
                )
              })}
            </div>{' '}
          </div>
        </section>
      </>
    )
  }
}

const slider = (content) => (
  <>
    <TestimonialSlider
      padding
      id={content.sectionId}
      title={content.title}
      data={content.testimonials}
    />
  </>
)
const newContentSection = (content) => {
  return <ContentSection {...content} />
}
const contentSection = (content) => {
  return (
    <ContentSection
      contentSectionId={content?.contentSectionId}
      direction={content?.direction}
      contentImage={content?.image}
      title={content?.contentTitle}
      subtitle={content?.contentSubtitle}
      hubspotFormId={content?.hubspotFormId}
      linkText={content?.linkText}
      linkUrl={content?.linkUrl}
      content={content?.content}
      contentType="image"
    />
  )
}

// const stbForm = (content) => {
//   if (content.stbFormActive) {
//     return <AccountantSignupForm id={content.sectionId} />
//   }
// }

const videoSection = (content) => {
  return (
    <ContentSection
      contentSectionId={content?.contentSectionId}
      direction={content?.direction}
      contentVideo={content?.video}
      contentPreviewImage={content?.previewImage}
      title={content?.contentTitle}
      subtitle={content?.contentSubtitle}
      hubspotFormId={content?.hubspotFormId}
      linkText={content?.linkText}
      linkUrl={content?.linkUrl}
      content={content?.content}
      contentType="video"
    />
  )
}
const ctaForm = (content) => (
  <CTA
    title={
      content.ctaDefaultTitle
        ? content.ctaDefaultTitle
        : 'Bereits über 100.000 Gründer und Selbständige vertrauen sevDesk für eine einfache Buchhaltung.\nÜberzeuge dich selbst!'
    }
    subtitle={content.subtitle}
    showEmailForm
    backgroundImageUrl="https://sevdesk.imgix.net/cta-generic-2.jpg"
  ></CTA>
)

const founderCTA = (content) => {
  return (
    <FounderCTA
      pillerText={content.pillerText}
      title={content.title}
      subtitle={content.subtitle}
      buttonLinkText={content.linkText}
      buttonLinkUrl={content.linkUrl}
      imageUrl={content.image.url} />
  )
}


const ctaDefault = (content) => {
  return (
    <CTA
      title={
        content.ctaDefaultTitle
          ? content.ctaDefaultTitle
          : 'Teste sevDesk jetzt 14 Tage kostenlos und überzeuge dich selbst!'
      }
      backgroundImageUrl={
        content.customImage && content.customImage.url
          ? content.customImage.url
          : 'https://sevdesk.imgix.net/34060/1602755253-cta-generic-3.jpg'
      }
      subtitle={content.ctaDefaultSubtitle ? content.ctaDefaultSubtitle : ''}
      showOverlay={content.ctaDefaultOverlay}
      useDefaultModalButton={content.modal}
      buttonLinkText={content.linkText}
      buttonLinkUrl={content.linkUrl}
      hideBackgroundImage={content.hideBackgroundImage}
    ></CTA>
  )
}

const featurePricingTable = (content) => {
  if (content.componentName === 'Pricing Table Reduced') {
    return (
      <CustomSection padding center id={content?.sectionId}>
        <Title
          title={content.title}
          subtitle={<div dangerouslySetInnerHTML={{ __html: content.subtitle }}></div>}
        />
        <PricingTableReduced></PricingTableReduced>
      </CustomSection>
    )
  }
  if (content.componentName === 'Feature Table Sale') {
    return (
      <CustomSection padding center id={content?.sectionId}>
        <Title
          title={content.title}
          subtitle={<div dangerouslySetInnerHTML={{ __html: content.subtitle }}></div>}
        />
        <FeatureTableSale></FeatureTableSale>
      </CustomSection>
    )
  }
  if (content.componentName === 'Feature Table Long') {
    return (
      <CustomSection padding center id={content?.sectionId}>
        <Title
          title={content.title}
          subtitle={<div dangerouslySetInnerHTML={{ __html: content.subtitle }}></div>}
        />
        <FeatureTableLong id={content?.sectionId}></FeatureTableLong>
      </CustomSection>
    )
  }
  if (content.componentName === 'Partnerkanzlei Table') {
    return (
      <CustomSection padding center id={content?.sectionId}>
        <Title
          title={content.title}
          subtitle={<div dangerouslySetInnerHTML={{ __html: content.subtitle }}></div>}
        />
        <FeatureTableAccountingPartnerKanzleien></FeatureTableAccountingPartnerKanzleien>
      </CustomSection>
    )
  }
  if (content.componentName === 'Feature Table') {
    return (
      <CustomSection padding center>
        <Title
          title={content.title}
          subtitle={<div dangerouslySetInnerHTML={{ __html: content.subtitle }}></div>}
        />
        <FeatureTable></FeatureTable>
      </CustomSection>
    )
  }
}

const imageSlider = (content) => (
  <>
    <Title paddingTop title={content.title} />
    <ImageSlider paddingBottom imageArray={content.images} />
  </>
)

const tvKomponente = (content) => {
  if (content.active) {
    return (
      <DiscountComponent
        heading={content.title}
        title={content.contentTitle}
        button={content.buttonText}
        note={content.note}
        counterOn={true}
        rabatt
      >
        {content.content}
      </DiscountComponent>
    )
  }
  return <DiscountComponent counterOn={true} />
}

const contentBlock = (content) => {


  return (
    <ContentSection
      contentSectionId={content?.contentSectionId}
      direction="top"
      title={content?.contentBlockTitle}
      hubspotFormId={content?.hubspotFormId}
      content={content?.content}
      contentBlockCenter={content?.textCenter}
      contentOption={content?.option}
    />
  )
}

const videoBlock = (content) => {
  return (
    <ContentSection
      contentSectionId={content?.contentSectionId}
      direction="top"
      title={content?.title}
      subtitle={content.subtitle}
      contentType="video"
      contentVideo={content.videoUrl ? { url: content?.videoUrl } : content?.videoDatei}
      contentPreviewImage={content?.previewImage}
    />
  )
  // if (content.videoUrl) {
  //   return (
  //     <VideoBlock
  //       id={content.sectionId}
  //       title={content.title}
  //       subtitle={content.subtitle}
  //       videoUrl={content.videoUrl}
  //     />
  //   )
  // } else if (content.videoDatei.url) {
  //   return (
  //     <VideoBlock
  //       id={content.sectionId}
  //       title={content.title}
  //       subtitle={content.subtitle}
  //       videoDatei={content.videoDatei.url}
  //       // posterUrl="https://sevdesk.imgix.net/34060/1629101916-dashboard-preview-video.png?auto=format,compress&q75&w=430&h=auto&fm=webp"
  //     />
  //   )
  // }
}

const hubspot = (content) => {
  return (
    <ContentSection
      direction="top"
      content={content?.content}
      contentSectionId={content?.contentSectionId}
      contentBlockCenter={content?.textCenter}
    />
  )
  // const ContentBlockProps = [
  //   content.cssClass,
  //   content.center,
  //   content.paddingTop,
  //   content.paddingBottom,
  // ]
  // return <ContentBlock contentBlockProps={ContentBlockProps}>{content.content}</ContentBlock>
}

const featureIcon = (content) => {
  if (content.featureType === 'Feature Icons') {
    return (
      <FeatureColumn
        title={content?.title}
        features={content?.feature}
        alignment="left"
        linkTitle={content.linkText}
        linkUrl={content.linkUrl}
      />
    )
  }
  if (content.featureType === 'Feature List')
    return <FeatureColumn title={content?.title} features={content?.feature} alignment="left" />
}

const featureColumn = (content) => {
  return (
    <FeatureColumn
      title={content?.title}
      subtitle={<div dangerouslySetInnerHTML={{ __html: content.subtitle }}></div>}
      features={content.features}
      alignment={content?.alignment}
      linkTitle={content.btnTitle}
      linkUrl={content.linkUrl}
    />
  )
}

const zusammenarbeitColumn = (content) => {
  if (content.zusammenarbeitActive) {
    return <ZusammenarbeitColumn id={content.sectionId} />
  }
}

const plainHtml = (content) => <div dangerouslySetInnerHTML={{ __html: content.htmlBlock }}></div>


const emailFormField = (content) => <EmailFormField placeholder={content?.placeholder} />
const atlasReactComponent = (content) => {
  //Experiment ob es funzt
  const { code, css } = content
  const style = document.createElement('style')
  const head = document.head || document.getElementsByTagName('head')[0]
  style.appendChild(document.createTextNode(css))
  head.appendChild(style)
  return (
    <LiveProvider
      code={code}
      // scope={{ Button, EmailFormField, CountdownComponent, getValidDiscount, moment }}
      scope={{ Button, EmailFormField, CountdownComponent, getValidDiscount }}
      noInline={true}
    >
      <LivePreview />
    </LiveProvider>
  )
}
const reactComponent = (content) => {
  //Experiment ob es funzt
  const { code, css } = content
  // const style = document.createElement('style')
  // const head = document.head || document.getElementsByTagName('head')[0]
  // style.appendChild(document.createTextNode(css))
  // head.appendChild(style)
  // console.log("CODE", code)
  return (
    <LiveProvider
      code={code}
      // scope={{ Button, EmailFormField, CountdownComponent, getValidDiscount, moment }}
      noInline={true}
    >
      <LivePreview />
    </LiveProvider>
  )
}
const atlasButton = (content) => (
  <Button buttonType={content?.type} title={content?.text} url={content?.url} />
)
export const contents = {
  new_content_section: newContentSection,
  accordion_custom_model: accordionContent,
  accordion: accordionModel,
  article_cards_section: articleCard,
  custom_component: customComponent,
  custom_content_with_id: customId,
  cta_default: ctaDefault,
  ctaform: ctaForm,
  founder_cta: founderCTA,
  custom_trusticon: trustIcon,
  counter: counter,
  table_of_content: tableOfContent,
  ratgeber_stoererbox: ratgeberStoererbox,
  content_section: contentSection,
  content_block: contentBlock,
  empty_section: () => null,
  faq_component: faqComponent,
  feature_box: featureBox,
  feature_table: featurePricingTable,
  feature_icon_lp: featureIcon,
  feature_column: featureColumn,
  Hubspot: hubspot, // ! ???????
  hero: hero,
  image_slider: imageSlider,
  newsfeed: newsFeed,
  plain_html_block: plainHtml,
  video_content_section: videoSection,
  video_block: videoBlock,
  relevante_beitraege: relevanteBeitraege,
  recruitee_widget: recruitee,
  switch_component_block: switchComponent,
  // steuerberater_formular: stbForm,
  shoppinglp_component: shoppinglp,
  testimonial_slider: slider,
  tv_komponente: tvKomponente,
  zusammenarbeit_column: zusammenarbeitColumn,
  email_form_field: emailFormField,
  atlas_react_component: atlasReactComponent,
  react_component: reactComponent,
  atlas_button: atlasButton,
  integration_component: integrationComponent,
}

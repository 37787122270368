// extracted by mini-css-extract-plugin
export var backgroundSvg = "TopBanner-module--backgroundSvg--92ab1";
export var badge = "TopBanner-module--badge--1e6d8";
export var black = "TopBanner-module--black--e6566";
export var close = "TopBanner-module--close--0f569";
export var container = "TopBanner-module--container--d3e5f";
export var cursor = "TopBanner-module--cursor--a0ff8";
export var dot = "TopBanner-module--dot--4f0c3";
export var feuerwerkLeft = "TopBanner-module--feuerwerkLeft--d5beb";
export var feuerwerkRight = "TopBanner-module--feuerwerkRight--e2d4c";
export var headline = "TopBanner-module--headline--21324";
export var megaTopBannerCounterField = "TopBanner-module--megaTopBannerCounterField--2af83";
export var megaTopBannerCounterMark = "TopBanner-module--megaTopBannerCounterMark--68a74";
export var megaTopBannerCounterNumber = "TopBanner-module--megaTopBannerCounterNumber--fa23c";
export var megaTopBannerWrapperCounter = "TopBanner-module--megaTopBannerWrapperCounter--5db80";
export var mint = "TopBanner-module--mint--4acd1";
export var rightContainer = "TopBanner-module--rightContainer--0279e";
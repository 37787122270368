import React from 'react'
import Link from '../base/Link'
import Button from '../button'
// import '../assets/scss/main.scss'
import EmailFormField from '../form/EmailFormField'
import Image from '../base/Image'
class RegisterMandanten extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="l-sign-up l-sign-up--02 l-sign-up--mandanten">
        <header className="l-sign-up__header py-5 text-center">
          {' '}
          <Link to="/" title="sevDesk">
            <span className="c-logo c-logo--white">sevDesk</span>
          </Link>
        </header>
        <main className="l-sign-up__body">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <h1 className="u-text-white text-regular h3 mb-5">
                  Du möchtest dich als <strong>Neukunde</strong> registrieren?
                </h1>
                <div id="hero-signup" className="mb-4">
                  <div className="c-signup-form-s c-signup-form-s--sm">
                    <EmailFormField></EmailFormField>
                  </div>
                </div>
                <div className="pt-sm-3">
                  <ul className="c-check-list-simple u-text-white">
                    <li>14 Tage unverbindlich testen</li>
                    <li>Testphase endet automatisch</li>
                    <li>Keine Kreditkarte erforderlich</li>
                  </ul>
                </div>
                <p className="l-sign-up__note text-note u-text-white">
                  Es gelten die <Link to="/agb/">AGB</Link> &{' '}
                  <Link to="/datenschutz/">Datenschutzerklärung</Link>.
                  Wir verwenden deine E-Mail, um dir Infos rund um sevDesk bereitzustellen, denen du jederzeit widersprechen kannst.
                </p>
              </div>
              <div className="col-md-6 pl-md-5">
                <hr className="d-md-none" />
                <h1 className="u-text-white text-regular h3 mb-5 pt-4 pt-md-0">
                  Du bist <strong>bereits Kunde</strong> bei sevDesk?
                </h1>
                <div className="pt-1 pb-3">
                  {' '}
                  <Button buttonType={'primary'} url="https://my.sevdesk.de">
                    Hier Geht es zum login
                  </Button>
                </div>
                <div className="pt-sm-3">
                  <ul className="c-check-list-simple u-text-white">
                    <li>Zur Loginseite von sevDesk</li>
                    <li>Eingabe E-Mail-Adresse</li>
                    <li>Eingabe Passwort</li>
                  </ul>
                </div>
                <p className="l-sign-up__note text-note u-text-white">
                  Probleme mit dem Login?
                  <br /> Hilfe erhältst du unter{' '}
                  <a href="mailto:steuerberater@sevdesk.de">steuerberater@sevdesk.de</a>
                </p>
              </div>
              <div className="u-text-center col-10 col-md-6 pt-3">
                {' '}
                <Image
                  className="u-img-fluid "
                  src="https://sevdesk.imgix.net/trust-register.png?auto=format,compress&fit=crop&w=450"
                  alt='sevDesk bei trusted'
                  width={450}
                />
              </div>
            </div>
          </div>
        </main>
        <footer className="l-sign-up__footer text-center py-4">
          <nav className="c-links-sm">
            <ul>
              <li>
                <Link to="/funktionen/">Funktionen</Link>
              </li>
              <li>
                <Link to="/datenschutz/">Datenschutz</Link>
              </li>
              <li>
                <Link to="/impressum/">Impressum</Link>
              </li>
            </ul>
          </nav>
        </footer>
      </div>
    )
  }
}
export default RegisterMandanten


import { getCookie,setCookie, getUrlParameter } from '../utils/index'
import { analytics } from './analyticsPlugin'
import CryptoJS from 'crypto-js';

export let timerAbTest = 0

export const customIdentify = (traits) => {
  analytics.identify(traits)
}

const trackEngagement = () => {
  analytics.track("Engagement")
  window.removeEventListener("click", trackEngagement, false)
}

if (typeof window !== 'undefined') {
  window.addEventListener("click", trackEngagement, false)
}

/**
 * Analytics
 **/

//! sevAtlas Tracking Stuff
export const loadSA = async () => {
  //TODO fetch mit Abfrage ob Server erreichbar ist, ansonsten visibility auf unset
  addScript(`${process.env.GATSBY_SEVATLAS_CDN}/v4/scripts/atlas_sevdesk.${process.env.GATSBY_LOCALE.replace('de-AT', 'at')}.js`)

  //TODO: might not be needed
  await new Promise((r) => setTimeout(r, 500))

  if (loadScriptCount === 0) {
    if (typeof window !== 'undefined' && getCookie('userConsent') === 'true') {
      if (window.atlas) {
        window.atlas.loadUserConsentTrueScripts()
      }
      addScript('//js.hs-scripts.com/4999173.js')
      loadScriptCount = 1
    }
  }
}
export const toggleAnalytics = (enabled, init) => {

  loadGTMScript(enabled, init)
  if (enabled === true) {
    //For YouTube
    const event = new Event('consentGiven');
    window.dispatchEvent(event);
    setCookie('userConsentYouTube', 'true');

    analytics.plugins.enable(['sev-analytics', 'segment']).then(() => {

      analytics.page();
      loadScripts();
    }).catch(error => {
      console.error("Error enabling analytics:", error);
    });

  } else {
    analytics.plugins.disable(['sev-analytics', 'segment'])
  }
}
var loadScriptCount = 0
export const loadScripts = () => {
  if (loadScriptCount === 0) {
    if (typeof window !== 'undefined' && getCookie('userConsent') === 'true') {
      if (window.atlas) {
        window.atlas.loadUserConsentTrueScripts()
      }
      addScript('//js.hs-scripts.com/4999173.js')
      loadScriptCount = 1
    }
  }
}
const loadGTMScript = (enabled, init) => {
  // window.dataLayer = window.dataLayer || [];
  if(typeof cbt !== 'undefined') { cbt('restart_trusted_widget'); }
  var s = document.createElement('script')
  var f = document.getElementsByTagName('script')[0]
  s.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-KVBJWFP'
  s.type = 'text/javascript'
  f.parentNode.insertBefore(s, f)

  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
    window.dataLayer.push({
      event: 'userConsent',
      consent_value: enabled,
    })
  }
  gtag('js', new Date())
  
  gtag('config', 'GTM-KVBJWFP', { anonymize_ip: true })
  if(init){
    window.dataLayer?.push({ event: 'PageView_Virtual' })
  }
}

function generateTransactionId(randomString) {
  // Konvertieren des Datums in das gewünschte Format (nur das Datum, keine Zeit)
  const date = new Date();
  const dateString = date.toISOString().split('T')[0]; // Extrahiert das Datum im Format 'YYYY-MM-DD'

  // Kombinieren der zufälligen Zeichenkette und des Datumsstrings
  const combinedString = randomString + dateString;

  // Generieren des MD5-Hashes aus der kombinierten Zeichenfolge
  const hash = CryptoJS.MD5(combinedString).toString();

  return hash;
}

export const trackUserSignedUp = (data, email) => {
  const { clientID, createdClientID } = data
  try {
    let user = analytics.user()

    if (user.traits && user.traits.CompanyId) {
      analytics.reset()
      user = analytics.user()
    }

    const customTraits = {
      createdAt: new Date().toISOString(),
      lead_quality: 'SQL',
      email,
    }

    // Segment
    analytics.identify(createdClientID, customTraits)
    window.localStorage.getItem('referredBy') !== null &&
      analytics.identify({
        referredByHash: window.localStorage.getItem('referredBy'),
      })
    analytics.track('SignUp')
    window.dataLayer.push({
      event: 'successful_signup',
      value: true,
      transactionId: generateTransactionId(createdClientID,),
      hashedMail: CryptoJS.SHA256(email)?.toString(CryptoJS.enc.Hex)
    })
    window.dispatchEvent(new Event('onsignup'))
  } catch (e) {
    console.log(e)
  }
}

export function activateHubspot() {
  isActivated = true
}
export function evalHubspotTracking() {
  setTimeout(function () {
    if (typeof HubSpotConversations != 'object') {
      return false
    }
    var _hsq = (window._hsq = window._hsq || [])
    _hsq.push(['doNotTrack'])
    HubSpotConversations.resetAndReloadWidget()
  }, 500)
}

/**
 * Wird vorübergehend nicht mehr genutzt
 **/

export const trackPageView = (path, referrer) => {
  if(typeof cbt !== 'undefined') { cbt('restart_trusted_widget'); }
    window.dataLayer?.push({ event: 'PageView_Virtual' })
    analytics.page({ path, referrer })
}
export const trackReferralByParameter = (paramName) => {
  const refParam = getUrlParameter(paramName)
  if (refParam) {
    // const userId = window.analytics.user().anonymousId();
    analytics.identify({
      referredByHash: refParam,
    })
    analytics.track('Referred Click')
  }
}

/**
 * Wechsler
 **/
export const trackWechsler = (name, currentSoftware, email, wechslerKohorte) => {
  analytics.identify({
    name,
    current_software: currentSoftware,
    email,
    wechsler_aktion: 'Wechsler-Aktion',
    wechsler_kohorte: wechslerKohorte,
  })
  analytics.track('wechslerLead')
}

export const addScript = (url) => {
  const script = document.createElement('script')
  script.id = 'hs-script-loader'
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

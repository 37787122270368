import React from 'react'
import Image from '../base/Image'


class CheckMarkTags extends React.Component {
  constructor(props) {
    super()
  }
  render() {
    const checkmarks = this.props.checkmarks

    return (
      <ul className="c-hero__usp mb-3">
        {checkmarks.map((checkmark) => (
          <li key={checkmark.description}>
            <Image
              src="https://sevdesk.imgix.net/34060/1602857643-iccheckgreen.svg?auto=format,compress&fit=max&w=24&h=24"
              alt='GreenCheck'
              width={24}
              height={24}
            />
            <span
              style={{ paddingLeft: '0.5rem' }}
              dangerouslySetInnerHTML={{ __html: checkmark.description }}
            ></span>
          </li>
        ))}
      </ul>
    )
  }
}

export default CheckMarkTags

import { useEffect, useState } from 'react'

/**
 *
 * @param {any} array - data to filter
 * @param {function} filter_Callback - callback function that include the filter logic
 * @return {Array} array that contains the filtered data, the input Query and a set function's query
 */
export const useAutoComplete = (array, filter_Callback) => {
  const [input, setInput] = useState('')
  const [hits, setHits] = useState([])

  useEffect(() => {
    const hits = array.length > 0 && array.filter(filter_Callback)
    setHits(hits)
  }, [input])

  return [hits, input, setInput]
}

import React from 'react' //Muss mit rein !!
import { contents } from './contents'
import ReactDOM from 'react-dom'
import { snakeCase } from 'lodash'
import { createRoot } from 'react-dom/client';
import { getValidDiscount, isValidCounter } from '../../assets/js/utils/getValidDiscount'

var counter = {}
export const setComponentCounter = () => {
  counter = {}
}
// export const buildContent = (content, slug) => {
//   if (content && content.model) {
//     counter[_.snakeCase(content?.model?.name)]
//     ? (counter[_.snakeCase(content.model.name)] += 1)
//     : (counter[_.snakeCase(content.model.name)] = 1)
//     const func = contents[content?.model?.name]
//     const component = typeof func === 'function' ? func(content) : null
//     return (
//       <div id={_.snakeCase(content?.model?.name + counter[_.snakeCase(content?.model?.name)])}>{component}</div>
//     )
//   }
// }

function getIdFromString(str) {
  const regex = /-([0-9]+)/;
  const match = str.match(regex);
  return match ? match[1] : null;
}

export const buildContent = (landingContent) => {
  // console.log("LANDING CONTENT", landingContent)
  let buildedContent = landingContent?.map((content, i) => {
    if (content && (content.model || content?._modelApiKey)) {
      const apiKey = content?.model?.apiKey ?? content?._modelApiKey
      counter[snakeCase(apiKey)]
        ? (counter[snakeCase(apiKey)] += 1)
        : (counter[snakeCase(apiKey)] = 1)
      const func = contents[apiKey]
      const component = typeof func === 'function' ? func(content) : null
      return (
        <div
          key={i}
          data-component-id={getIdFromString(content?.id)}
          id={snakeCase(apiKey + counter[snakeCase(apiKey)])}
        >
          {component}
        </div>
      )
    }
  })
  setComponentCounter()
  if (typeof window !== 'undefined') {
    window.dispatchEvent(new Event('buildedContent'))
  }
  return buildedContent
}

export function replaceNodeWithReactComponent(element, reactComponent, testId) {
  const parent = document.createElement('div')

  const root = createRoot(parent);
  if (testId) {
    root.render((<RenderApp id={testId} callback={() => element.replaceWith(parent)}>{reactComponent}</RenderApp>));
  } else {
    // TODO: this may only need reactcomponent
    root.render((<RenderApp callback={() => element.replaceWith(parent)}>{reactComponent}</RenderApp>));
  }
}

function RenderApp({ callback, children, id }) {
  return (
    <div id={id} ref={callback}>
      {children}
    </div>
  );
}

if (typeof window !== 'undefined') {
  //if(document.referrer === "http://localhost:3000/"){
  //if(window.atlas){
  window.getContent = buildContent
  window.replaceNodeWithReactComponent = replaceNodeWithReactComponent
  window.ReactDOM = ReactDOM
  window.getValidRebate = getValidDiscount
  window.isValidCounter = isValidCounter
  //}
  // }
}

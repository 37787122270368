import React, { useState, useEffect, useTransition, lazy } from 'react';
import { extractYouTubeId } from '../../utils/extractYouTubeId';
import { getCookie, setCookie } from '../../utils'
import Link from '../base/Link'

function YouTubeConsentWrapper({ youtubeVideoUrl, isBlog }) {
    const [isConsentGiven, setConsentGiven] = useState(false);
    const [, startTransition] = useTransition();
    const videoId = extractYouTubeId(youtubeVideoUrl);

    useEffect(() => {
        const consentCookie = getCookie('userConsent')
        const consentCookieYouTube = getCookie('userConsentYouTube')

        if ((consentCookieYouTube === 'true') || (consentCookie && consentCookie === 'true')) {
            setConsentGiven(true);
        }

        const handleConsentGiven = () => {
            setConsentGiven(true);
        };
        if (typeof window !== 'undefined') {
            window.addEventListener('consentGiven', handleConsentGiven);

            return () => {
                window.removeEventListener('consentGiven', handleConsentGiven);
            };
        }

    }, []);

    const handleAccept = () => {
        setCookie('userConsentYouTube', 'true');
        setConsentGiven(true);

    };
    return (
        <>
            {isBlog ? (<div id="yt-overlay-container" className="yt-overlay-container">
                {isConsentGiven ? (
                    <iframe
                        loading="lazy"
                        width="560"
                        height="315"
                        src={youtubeVideoUrl}
                        title="YouTube video player"
                        style={{ border: 0 }}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                ) : (
                    <div id="yt-overlay" className="yt-overlay">
                        <img
                            alt="video thumbnail"
                            src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                            className={'yt-thumbnailImage'}
                            loading="lazy"
                        />
                        {/* <img
                            alt="Play Video"
                            src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_play_button_icon_%282013%E2%80%932017%29.svg"
                            loading="lazy"
                            className={'yt-playIcon'}
                        /> */}
                        <span className='h3 mb-2'>Externe Inhalte</span>
                        <span className='yt-overlay-question mb-3'>An dieser Stelle findest du externe Inhalte von YouTube. Durch Klick auf “Externe Inhalte laden” erklärst du dich damit einverstanden, dass dir externe Inhalte angezeigt werden. Dabei werden personenbezogene Daten an die Drittplattform übermittelt.</span>
                        <button className="globalPrimaryButton" id="yt-accept-button" onClick={() => {
                            startTransition(() => {
                                handleAccept();
                            });
                        }} title="Externe Inhalte laden">Externe Inhalte laden</button>
                        <span className='yt-overlay-accept mt-3'>Weitere Informationen findest du in unserer
                            <a href="/datenschutz/" target='_blank' title="Datenschutzerklärung"> Datenschutzerklärung </a>
                            und der Datenschutzerklärung von <a href="https://policies.google.com/privacy" target='_blank' title="YouTube">YouTube</a>.
                        </span>
                    </div>
                )}
            </div>) : (<div
                className="embed-responsive embed-responsive-16by9"
                style={{
                    boxShadow: '0 4px 6px 0 rgba(0,0,0,.11),0 1px 3px 0 rgba(0,0,0,.08)',
                    borderRadius: '6px',
                }}

            >
                <div id="yt-overlay-container" className="yt-overlay-container">
                    {isConsentGiven ? (
                        <iframe
                            loading="lazy"
                            width="560"
                            height="315"
                            src={youtubeVideoUrl}
                            title="YouTube video player"
                            style={{ border: 0 }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    ) : (
                        <div id="yt-overlay" className="yt-overlay">
                            <img
                                alt="video thumbnail"
                                src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                                className={'yt-thumbnailImage'}
                                loading="lazy"
                            />
                            {/* <img
                            alt="Play Video"
                            src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_play_button_icon_%282013%E2%80%932017%29.svg"
                            loading="lazy"
                            className={'yt-playIcon'}
                        /> */}
                            <span className='h3 mb-2'>Externe Inhalte</span>
                            <span className='yt-overlay-question mb-3'>An dieser Stelle findest du externe Inhalte von YouTube. Durch Klick auf “Externe Inhalte laden” erklärst du dich damit einverstanden, dass dir externe Inhalte angezeigt werden. Dabei werden personenbezogene Daten an die Drittplattform übermittelt.</span>
                            <button className="globalPrimaryButton" id="yt-accept-button" onClick={() => {
                                startTransition(() => {
                                    handleAccept();
                                });
                            }} title="Externe Inhalte laden">Externe Inhalte laden</button>
                            <span className='yt-overlay-accept mt-3'>Weitere Informationen findest du in unserer
                                <Link to="/datenschutz/" title="Datenschutzerklärung"> Datenschutzerklärung 
                                </Link>
                                und der Datenschutzerklärung von <a href="https://policies.google.com/privacy" target='_blank' title="YouTube">YouTube</a>.
                            </span>
                        </div>
                    )}

                </div>
            </div>)}
        </>
    );
}

export default YouTubeConsentWrapper;

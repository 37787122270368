import React from 'react'
import { checkIfAlreadyRegistered } from '../signup/SignupForm'
import { getUrlParameter } from '../../utils'
import Button from '../button'
class EmailFormField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: false,
      value: '',
      loading: false,
      submit: false,
      valid: '',
      selectedMonth: undefined,
    }
  }

  componentDidMount() {
    //Für Stb Register UTM Parameter
    const newUserMail = getUrlParameter('newUserMail') !== null && getUrlParameter('newUserMail')
    if (newUserMail) {
      this.setState({ value: newUserMail })
    }
    if (this.props.entryLevel) {
      this.setDefaultSelectedMonth()
    }
  }

  required = (value) => {
    if (!value.toString().trim().length) {
      // We can return string or jsx as the 'error' prop for the validated Component
      return
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    //this.form.validateAll()
    this.setState({ submit: true })
    if (this.validateEmail(this.state.value)) {
      this.setState({ loading: true, error: false })
      checkIfAlreadyRegistered(this.state.value, this.setLoading, this.state.selectedMonth)
    } else {
      this.setState({ error: true })
    }
  }

  setLoading = (loading) => {
    this.setState({ loading })
  }
  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(?=^[^@]{0,64}@[^@]{0,253}$)^[A-Za-z0-9!#$%&‘*+\-\/=?^_`{|}~]+(?:\.[A-Za-z0-9!#$%&‘*+\-\/=?^_`{|}~]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?\.)+[A-Za-z](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/,
      )
  }

  isValid = (email) => {
    this.validateEmail(email) ? this.setState({ valid: 'isValid' }) : this.setState({ valid: '' })
  }

  generateMonthOptions = () => {
    const currentDate = new Date()
    const months = [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ]

    const options = []

    // Füge 6 Monate in der Vergangenheit hinzu
    for (let i = 6; i >= 1; i--) {
      const pastDate = new Date(currentDate)
      pastDate.setMonth(currentDate.getMonth() - i)
      options.push({
        value: `${pastDate.getMonth() + 1}-${pastDate.getFullYear()}`,
        label: `${months[pastDate.getMonth()]} ${pastDate.getFullYear()}`,
      })
    }

    // Füge den aktuellen Monat hinzu
    options.push({
      value: `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`,
      label: `${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`,
    })

    // Füge 3 Monate in die Zukunft hinzu
    for (let i = 1; i <= 3; i++) {
      const futureDate = new Date(currentDate)
      futureDate.setMonth(currentDate.getMonth() + i)
      options.push({
        value: `${futureDate.getMonth() + 1}-${futureDate.getFullYear()}`,
        label: `${months[futureDate.getMonth()]} ${futureDate.getFullYear()}`,
      })
    }

    return options
  }

  setDefaultSelectedMonth = () => {
    const currentDate = new Date()
    const defaultMonth = `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`
    this.setState({ selectedMonth: defaultMonth })
  }
  handleMonthChange = (e) => {
    if(["älter als 6 Monate", "bin kein Neugründer"].includes(e.target.value)){
      window.open("/register/", "_self")
    }
    this.setState({ selectedMonth: e.target.value })
  }
  render() {
    return (
      <form
        ref={(c) => {
          this.form = c
        }}
        role="form"
        noValidate="novalidate"
        onSubmit={this.handleSubmit.bind(this)}
      >
        <span
          className={`c-signup-form-s__control ${
            this.state.error && this.state.submit ? 'is-invalid' : ''
          } ${this.state.loading ? 'is-loading' : ''} ${this.state.valid} `}
        >
          <label style={{ opacity: 0, position: 'absolute', display: 'none' }} htmlFor="signup">
            E-Mail-Adresse
          </label>
          <input
            // id="signup"
            type="email"
            name="email"
            value={this.state.value}
            onChange={({ target: { value } }) => {
              this.setState({ value: value })
              this.isValid(value)
            }}
            // aria-label="email"
            // aria-required="true"
            // aria-invalid="false"
            placeholder={this.props.placeholder}
            className="email-form c-signup-form-s__email"
          />
        </span>
        {this.props.entryLevel && (
          <span>
            <label
              style={{ opacity: 0, position: 'absolute', display: 'none' }}
              htmlFor="customSelect"
            >
              Gründungszeitpunkt
            </label>
            <select
              style={{ marginBottom: 16 }}
              value={this.state.selectedMonth}
              onChange={this.handleMonthChange}
              id="customSelect"
              className="custom-select"
            >
              {this.generateMonthOptions()
                ?.reverse()
                ?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                <option key="01-2000" value="01-2000">
                Gründung vor mehr als 6 Monaten
              </option>
              {/* <option key="älter als 6 Monate" value="älter als 6 Monate">
                älter als 6 Monate
              </option>
              <option key="bin kein Neugründer" value="bin kein Neugründer">
                bin kein Neugründer
              </option> */}
            </select>
          </span>
        )}

        <Button
          style={{
            filter: this.state.loading ? 'grayscale(1)' : null,
            cursor: this.state.loading ? 'default' : 'pointer',
          }}
          disabled={this.state.loading}
          buttonType={'inputField'}
        >
          {this.props.buttonText}
        </Button>
        {this.state.loading && <span className="c-signup-form-s__loader"></span>}
        {this.state.error && (
          <span className="c-signup-form-s__message">
            <span>Bitte gib eine gültige E-Mail-Adresse ein</span>
          </span>
        )}
      </form>
      // <Button buttonType={'primary'}>kostenlos testen</Button>
    )
  }
}

EmailFormField.defaultProps = {
  placeholder: 'E-Mail-Adresse eingeben',
}
export default EmailFormField

import { useEffect } from 'react'
import { checkPathsAndBlacklist } from '../utils/atlas-paths'
import { getAtlasServerData } from '../utils/atlas-server-data'

export default function AtlasWrapper({children, pageContext, location}) {
    
  useEffect(() => {
    async function fetchData() {
      if (typeof window !== 'undefined') {
        const newData = await getAtlasServerData(pageContext)
        if (newData) {
          doStuff(newData)
          newData?.otherExperiments?.map((experiment) => {
            // console.log(experiment)
            doStuff(experiment)
          })
        }
      }
    }
    fetchData()
  }, [pageContext])

  const doStuff = (data) => {
  
    try{
      if (data?.clientData) {
        data?.clientData?.map((item) => {
          try{
            new Function(item?.code)()
          } catch(e){
            console.log("CLIENT SNIPPETS", e)
          }
        })
      }
      if (data?.trackingData) {
        data?.trackingData?.map((item) => {
          try{
            new Function(item?.code)()
          } catch(e){
            console.log("TRACK SNIPPETS", e)
          }
        })
      }
      if (data?.globalJsSnippets) {
        const filtered = data?.globalJsSnippets?.filter(item => checkPathsAndBlacklist([item.path]))
        // console.log("FILTERED", filtered)
        filtered?.map((item) => {
          try{
            const checkPaths = () => {
             return checkPathsAndBlacklist([item.path])
            }
            new Function("checkPaths",`function set(){
              ${item?.code}
            }
            set();
            window.addEventListener("popstate", () => {set()})`)(checkPaths)
            // const script = document.createElement("script")
            // script.textContent = `function set(){
            //   ${item?.code}
            // }
            // set()`;
            // script.className = "globalJsSnippets"
            // document.head.appendChild(script)

          } catch(e){
            console.log("JS SNIPPETS", e)
          }
        })
        const forAllSelected = data?.globalJsSnippets?.find((item) => item.path === '#')
        try{
          forAllSelected && new Function(forAllSelected?.code)()
        } catch(e){
          console.log("All", e)
        }
      }
    } catch (e) {
      console.log("CATCH IT", e)
    }
  }
  return children
}



import React from 'react'
import { Script } from 'gatsby'

class AbschreibungsRechner extends React.Component {
  render() {
    return (
      <div className="calc">
        <div id="s_r_de_abschreibung">
          <Script type="text/javascript">
            {`
            s_r_de_abschreibung_premium = true;
            s_r_de_abschreibung_theme = "smoothness";
            s_r_de_abschreibung_responsive = true;
            s_r_de_abschreibung_slidedown = false;
            s_r_de_abschreibung_openresult = false;
            s_r_de_abschreibung_whitelabel = false;
            s_r_de_abschreibung_charts = false;
            s_r_de_abschreibung_title = "";
            s_r_de_abschreibung_adlink = "";
            s_r_de_abschreibung_adtext = "";
            s_r_de_abschreibung_adtarget = "_blank";
            s_r_de_abschreibung_adsize = "";
            s_r_de_abschreibung_adlink_requestmethod = "post";
            s_r_de_abschreibung_proxy_url = "";
            s_r_de_abschreibung_param_brutto_netto = "1";
            s_r_de_abschreibung_param_anschaffungskosten = "1000";
            s_r_de_abschreibung_param_nutzungsdauer = "4";
            `}
          </Script>
          <Script
            type="text/javascript"
            charset="utf-8"
            src="https://load.smart-rechner.de/widget/abschreibung.js"
          ></Script>
        </div>
      </div>
    )
  }
}

export default AbschreibungsRechner

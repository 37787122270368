import React, { useEffect } from 'react'
import Button from '../button'
import Tooltip from '../tooltip/Tooltip'

class MargenRechner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cost: '',
      serve: '',
      margeEuro: '',
      margePercent: '',
      revenue: '',
      resultBruttoRevenue7: '',
      resultBruttoRevenue19: '',
      resultNetto: '',
      resultBruttoFaktor7: '',
      resultBruttoFaktor19: '',
      showResult: false,
      showDetails1: false,
      showDetails2: false,
      showDetails3: false,
      showDetails4: false,
      showDetails5: false,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.calculateResult()
    this.state.showResult = true
  }

  calculateResult = () => {
    const cost = parseFloat(this.state.cost)
    const serve = parseFloat(this.state.serve)
    const margeEuro = parseFloat(this.state.margeEuro)
    // const margePercent = parseFloat(this.state.margePercent)
    const revenue = parseFloat(this.state.revenue)

    const resultBruttoRevenue7 = revenue * 1.07
    const resultBruttoRevenue19 = revenue * 1.19
    const resultNetto = revenue / cost
    const resultBruttoFaktor7 = resultBruttoRevenue7 / cost / 1.07
    const resultBruttoFaktor19 = resultBruttoRevenue19 / cost / 1.19

    this.setState({
      cost: cost.toFixed(2),
      serve: serve.toFixed(2),
      margeEuro: margeEuro.toFixed(2),
      // margePercent: margePercent.toFixed(2),
      revenue: revenue.toFixed(2),
      resultBruttoRevenue7: resultBruttoRevenue7.toFixed(2),
      resultBruttoRevenue19: resultBruttoRevenue19.toFixed(2),
      resultNetto: resultNetto.toFixed(3),
      resultBruttoFaktor7: resultBruttoFaktor7.toFixed(3),
      resultBruttoFaktor19: resultBruttoFaktor19.toFixed(3).replace('.', ','),
    })
  }

  toggleDetails1 = () => {
    this.setState((prevState) => ({
      showDetails1: !prevState.showDetails1,
    }))
  }
  toggleDetails2 = () => {
    this.setState((prevState) => ({
      showDetails2: !prevState.showDetails2,
    }))
  }
  toggleDetails3 = () => {
    this.setState((prevState) => ({
      showDetails3: !prevState.showDetails3,
    }))
  }
  toggleDetails4 = () => {
    this.setState((prevState) => ({
      showDetails4: !prevState.showDetails4,
    }))
  }
  toggleDetails5 = () => {
    this.setState((prevState) => ({
      showDetails5: !prevState.showDetails5,
    }))
  }

  render() {
    const {
      resultBruttoRevenue7,
      resultBruttoRevenue19,
      resultNetto,
      resultBruttoFaktor7,
      resultBruttoFaktor19,
      showResult,
      showDetails1,
      showDetails2,
      showDetails3,
      showDetails4,
      showDetails5,
    } = this.state

    return (
      <>
        <div className="container l-section--padding">
          <div className="d-flex flex-column align-items-center">
            <div className="c-calculator">
              <h1>Margenrechner</h1>
              <form id="margeForm" onSubmit={this.handleSubmit}>
                <div className="formDiv">
                  <div className="flexDiv">
                    <label>Kosten</label>
                    <div className="inputDiv">
                      <input
                        placeholder="450,00"
                        type="number"
                        className={`formField`}
                        name="cost"
                        value={this.state.cost}
                        onChange={(e) => this.setState({ cost: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                  <div className="flexDiv">
                    <label>Aufschlag in %</label>
                    <div className="inputDiv">
                      <input
                        placeholder="10,00"
                        type="number"
                        className={`formField`}
                        name="serve"
                        value={this.state.serve}
                        onChange={(e) => this.setState({ serve: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                  <div className="flexDiv">
                    <label>Marge in €</label>
                    <div className="inputDiv">
                      <input
                        placeholder="45,00"
                        type="number"
                        className={`formField`}
                        name="margeEuro"
                        value={this.state.margeEuro}
                        onChange={(e) => this.setState({ margeEuro: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                  <div className="flexDiv">
                    <label>Marge in %</label>
                    <div className="inputDiv">
                      <input
                        placeholder="9,09"
                        type="number"
                        className={`formField`}
                        name="margePercent"
                        value={this.state.margePercent}
                        onChange={(e) => this.setState({ margePercent: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                  <div className="flexDiv">
                    <label>Erlös</label>
                    <div className="inputDiv">
                      <input
                        placeholder="495,00"
                        type="number"
                        className={`formField`}
                        name="revenue"
                        value={this.state.revenue}
                        onChange={(e) => this.setState({ revenue: e.target.value })}
                        required
                      />
                    </div>
                  </div>
                  <Button buttonType="submit" title="Berechnen"></Button>
                </div>
              </form>
              {showResult && (
                <div className="calcDiv">
                  {/* <div className="calulactionDiv"> */}
                  <div className="title">Ergebnisse:</div>
                  <div className="flexDiv">
                    Bruttoerlös bei 7% USt
                    <div>
                      {resultBruttoRevenue7}
                      <button
                        onClick={this.toggleDetails1}
                        style={showDetails1 ? { transform: 'rotate(180deg)' } : {}}
                      >
                        <svg
                          fill="#000000"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 10l5 5 5-5z" />
                          <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {showDetails1 && (
                    <div className="detail">
                      Dies ist der Erlös inklusive 7 Prozent Umsatzsteuer.
                      <br />
                      Dieser Bruttoerlös wird berechnet, indem 7 Prozent des Erlöses zum Erlös
                      selber addiert werden. Dies wird erreicht, indem der Erlös mit 1,07
                      multipliziert wird.
                    </div>
                  )}
                  <div className="flexDiv">
                    Bruttoerlös bei 19% USt
                    <div>
                      {resultBruttoRevenue19}
                      <button
                        onClick={this.toggleDetails2}
                        style={showDetails2 ? { transform: 'rotate(180deg)' } : {}}
                      >
                        <svg
                          fill="#000000"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 10l5 5 5-5z" />
                          <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {showDetails2 && (
                    <div className="detail">
                      Dies ist der Erlös inklusive 19 Prozent Umsatzsteuer. <br />
                      Dieser Bruttoerlös wird berechnet, indem 19 Prozent des Erlöses zum Erlös
                      selber addiert werden. Dies wird erreicht, indem der Erlös mit 1,19
                      multipliziert wird.
                    </div>
                  )}
                  <div className="flexDiv">
                    Nettofaktor
                    <div>
                      {resultNetto}
                      <button
                        onClick={this.toggleDetails3}
                        style={showDetails3 ? { transform: 'rotate(180deg)' } : {}}
                      >
                        <svg
                          fill="#000000"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 10l5 5 5-5z" />
                          <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {showDetails3 && (
                    <div className="detail">
                      Der Nettofaktor ist der Faktor, also Multiplikator, um von den Kosten zum
                      Erlös ohne Umsatzsteuer (Nettoerlös) zu kommen. Es gilt dann <br />
                      <strong>Kosten × Nettofaktor = Nettoerlös</strong> <br />
                      Stellt man die Formel entsprechend um, so wird der Nettofaktor folgendermaßen
                      berechnet: <br />
                      <strong>Nettoerlös / Kosten = Nettofaktor</strong>
                    </div>
                  )}
                  <div className="flexDiv">
                    Bruttofaktor bei 7% USt
                    <div>
                      {resultBruttoFaktor7}
                      <button
                        onClick={this.toggleDetails4}
                        style={showDetails4 ? { transform: 'rotate(180deg)' } : {}}
                      >
                        <svg
                          fill="#000000"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 10l5 5 5-5z" />
                          <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {showDetails4 && (
                    <div className="detail">
                      Der Bruttofaktor ist der Faktor, um von den Kosten zum Bruttoerlös, also zum
                      Erlös inklusive Umsatzsteuer, zu kommen. <br />
                      Es gilt dann bei 7 Prozent Umsatzsteuer <br />
                      <br />
                      <strong>Kosten × Bruttofaktor × 1,07 = Bruttoerlös</strong> <br />
                      <br />
                      Stellt man die Formel entsprechend um, so wird der Bruttofaktor folgendermaßen
                      berechnet: <br />
                      <br />
                      <strong>Bruttoerlös / Kosten / 1,07 = Bruttofaktor</strong> <br />
                    </div>
                  )}
                  <div className="flexDiv">
                    Bruttofaktor bei 19% USt
                    <div>
                      {resultBruttoFaktor19}
                      <button
                        onClick={this.toggleDetails5}
                        style={showDetails5 ? { transform: 'rotate(180deg)' } : {}}
                      >
                        <svg
                          fill="#000000"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M7 10l5 5 5-5z" />
                          <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {showDetails5 && (
                    <div className="detail">
                      Der Bruttofaktor ist der Faktor, um von den Kosten zum Bruttoerlös, also zum
                      Erlös inklusive Umsatzsteuer, zu kommen. <br />
                      Es gilt dann bei 19 Prozent Umsatzsteuer <br /> <br />
                      <span style={{ fontWeight: '600', padding: '10px 0', textAlign: 'center' }}>
                        Kosten × Bruttofaktor × 1,19 = Bruttoerlös
                      </span>{' '}
                      <br />
                      <br />
                      Stellt man die Formel entsprechend um, so wird der Bruttofaktor folgendermaßen
                      berechnet: <br />
                      <br />
                      <strong>Bruttoerlös / Kosten / 1,19 = Bruttofaktor</strong> <br />
                    </div>
                  )}
                  {/* </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default MargenRechner

import React, { useEffect, useState } from 'react'
import Link from '../base/Link'
import * as styles from './CountdownComponent.module.scss'
import Button from '../button'
import { useDiscount } from '../../assets/js/hook/useDiscount'
import { useCountdown } from '../../assets/js/hook/useCountdown'
import { isValidDiscount } from '../../assets/js/utils/getValidDiscount'
import getDiscountedPrice from '../../assets/js/utils/getDiscountPercentage'
import { useDiscountContext } from '../../hooks/useDiscountContext'

export const localization = {
  YEAR_PLURAL: 'Jahre',
  YEAR_SINGULAR: 'Jahr',
  MONTH_PLURAL: 'Monate',
  MONTH_SINGULAR: 'Monat',
  WEEK_PLURAL: 'Wochen',
  WEEK_SINGULAR: 'Woche',
  DAY_PLURAL: 'Tage',
  DAY_SINGULAR: 'Tag',
  HOUR_PLURAL: 'Stunden',
  HOUR_SINGULAR: 'Stunde',
  MINUTE_PLURAL: 'Minuten',
  MINUTE_SINGULAR: 'Minute',
  SECOND_PLURAL: 'Sekunden',
  SECOND_SINGULAR: 'Sekunde',
  MILLISECOND_PLURAL: 'Millisekunden',
  MILLISECOND_SINGULAR: 'Millisekunde',
}
export const CountdownComponent = ({showTitle, showName, showBtn, isPreise}) => {
  const subtitle = "Nur fur kurze Zeit";
  const title = "Sichere dir sevDesk jetzt bit zu";
  const [isTargetRepresented, setTargetIsRepresented] = useState(false);
  // const [discount, target, duration, month] = useDiscount(imported_discount);
  const {target, ...discount} = useDiscountContext()
  const [days, hours, minutes, seconds] = useCountdown(target);
  const [visible, setVisible] = useState(true)
  // const {discountPercentage} = getDiscountedPrice("biAnnual", 24,'warenwirtschaft', duration )
  useEffect(()=>{
    if(new Date(target) < new Date() || isNaN(target)){
      setVisible(false)
    }
    
    setTargetIsRepresented(target);
  },[days]);


  return visible ? (
    <>
    {
      isTargetRepresented && discount?.counterEnabled ? 
      (isPreise ? (
                  <div className={`${styles.cCountdownWp} ${styles.uTextCenter}`}>
                    <div className={`${styles.cCountdown}`}>
                      <div className={`${styles.cCountdown__contentBlackfriday} ${styles.cCountdown__content}`}>
                        <div className={`${styles.cCountdown__content__countdownBlackfriday} ${styles.cCountdown__content__countdown}`}>
                          <div style={{ width: '100%' }}>
                            <div style={{ cursor: 'pointer' }}>
                              <Link title="Rabatt sichern" to="/register/">
                                  <div className={styles.tickInfo}>
                                    { showTitle&& discount?.additionalComponents?.counter?.html &&(<>
                                      {<div style={{textTransform: "uppercase"}} dangerouslySetInnerHTML={{__html: discount?.additionalComponents?.counter?.html}}/>}
                                    </>)}
                                    <NewCountdownCounter days={days} hours={hours} minutes={minutes} seconds={seconds} transparent={false}/>
                                  </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          ):(
                <div className={styles.ccCountdownMain}>
                    <div style={{ width: '100%' }}>
                      <div style={{ cursor: 'pointer' }}>
                        {showTitle && title && (
                          <div className={styles.ccCountdownHeader}>
                            <h4>{subtitle} {showName && discount['name']}</h4>
                            { showTitle&& discount?.additionalComponents?.counter?.html &&(<>
                                      {<div style={{textTransform: "uppercase"}} dangerouslySetInnerHTML={{__html: discount?.additionalComponents?.counter?.html}}/>}
                                    </>)}
                          </div>
                        )}
                        <Link title="Rabatt sichern" to="/register/">
                          <div className={styles.ccCountdownCounter}>
                            <NewCountdownCounter days={days} hours={hours} minutes={minutes} seconds={seconds} transparent={false}/>
                          </div>
                        </Link>
                        { showBtn && (<div className={styles.ccCountdownButton}>
                          <Button style={{ margin: "1.5em 0 2.5em 0"}} title={"KOSTENLOSE TESTPHASE STARTEN"} />
                        </div>)
                        }
                      </div>
                    </div>
                </div>
    )) : ""
    }</>
  ) : ""
}
export const NewCountdownCounter = (props) =>{

  const fieldStyle = props.transparent ? `${styles.ccCountdownField} ${styles.background}`:`${styles.ccCountdownField}`;
  return (
    <div className={styles.ccCountdownCounter}>
      <div className={`${fieldStyle}`}>
        <div className={styles.ccCountdownNumber}>
          {props.days}
        </div>
        <div className={styles.ccCountdownMark}>
          {props.days>1?localization.DAY_PLURAL:localization.DAY_SINGULAR}
        </div>
      </div>

      <div className={`${fieldStyle}`}>
        <div className={styles.ccCountdownNumber}>
          {props.hours}
        </div>
        <div className={styles.ccCountdownMark}>
          {props.hours>1?localization.HOUR_PLURAL:localization.HOUR_SINGULAR}
        </div>
      </div>

      <div className={`${fieldStyle}`}>
        <div className={styles.ccCountdownNumber}>
          {props.minutes}
        </div>
        <div className={styles.ccCountdownMark}>
          {props.minutes>1?localization.MINUTE_PLURAL:localization.MINUTE_SINGULAR}
        </div>
      </div>

      <div className={`${fieldStyle}`}>
        <div className={styles.ccCountdownNumber}>
          {props.seconds}
        </div>
        <div className={styles.ccCountdownMark}>
          {props.seconds>1?localization.SECOND_PLURAL:localization.SECOND_SINGULAR}
        </div>
      </div>
    </div>)
}

import React from 'react'
import { Script } from 'gatsby'

class MinijobRechner extends React.Component {
  render() {
    return (
      <div className="calc">
        <div id="s_r_de_minijob">
          <Script type="text/javascript">
            {`
            s_r_de_minijob_premium = true;
            s_r_de_minijob_theme = "smoothness";
            s_r_de_minijob_responsive = true;
            s_r_de_minijob_slidedown = false;
            s_r_de_minijob_openresult = false;
            s_r_de_minijob_whitelabel = false;
            s_r_de_minijob_charts = false;
            s_r_de_minijob_title = "";
            s_r_de_minijob_adlink = "";
            s_r_de_minijob_adtext = "";
            s_r_de_minijob_adtarget = "_blank";
            s_r_de_minijob_adsize = "";
            s_r_de_minijob_adlink_requestmethod = "post";
            s_r_de_minijob_proxy_url = "";
            s_r_de_minijob_param_zeitraum = "1";
            s_r_de_minijob_param_monatsbrutto = "520";
            s_r_de_minijob_param_ausuebung = "1";
            s_r_de_minijob_param_rentenpflicht_verzicht = "ja";
            s_r_de_minijob_param_krankenversicherung = "1";
            `}
          </Script>
          <Script
            type="text/javascript"
            charset="utf-8"
            src="https://load.smart-rechner.de/widget/minijob.js"
          ></Script>
        </div>
      </div>
    )
  }
}

export default MinijobRechner

import React from 'react'
import { Link } from 'gatsby'
import EmailFormField from '../form/EmailFormField'
import Typewriter from '../Typewriter'
import useMediaQuery from '../../hooks/useMediaQuery'

function HeroTypedForm(props) {
  const {
    addClass,
    typedOptions,
    typedStrings,
    imageUrl,
    title,
    typedText,
  } = props;

  const additionalClass = addClass || '';
  const options = typedOptions && JSON.parse(typedOptions);
  const typedArray = options || typedStrings;
  const image = imageUrl || 'https://sevdesk.imgix.net/funktionen-hero.jpg';
  const isMobile = useMediaQuery('(max-width: 1024px)');

  return (

    <div
      data-bgset={`${image}?auto=format,compress&q=75${isMobile ? '&w=425&h=375&fit=crop' : '&w=1920&h=500&fit=crop'
        }`}
      style={{
        backgroundImage: `url(${image}?auto=format,compress&q=75${isMobile ? '&w=425&h=375&fit=crop' : '&w=1920&h=500&fit=crop'
          })`,
      }}
      className={`c-hero-typed l-section--padding c-hero-typed--blue lazyload ${additionalClass}`}
    >

      <div className="container py-lg-5">
        <div className="row py-md-3 justify-content-center">
          <div className="col-11 col-sm-10 col-md-7">
            <h1
              className="display-1 text-white"
              dangerouslySetInnerHTML={{ __html: title }}
            ></h1>
            <h2 className="h3 text-white">
              <div dangerouslySetInnerHTML={{ __html: typedText }}></div>
              <Typewriter
                typedArray={typedArray}
                typingSpeed={50}
                deleteSpeed={30}
                pauseDuration={900}
              />
            </h2>
            <div className="col-md-10 mt-4 mb-3 px-0">
              <div className="c-signup-form-s c-signup-form-s--sm">
                <EmailFormField />
              </div>
            </div>

            <span className="u-text-white fc-hero__note--white">
              Es gelten die <Link to="/agb/">AGB</Link> &{' '}
              <Link to="/datenschutz/">Datenschutzerklärung</Link>. Wir verwenden deine E-Mail, um dir Infos rund um sevDesk bereitzustellen, denen du jederzeit widersprechen kannst.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroTypedForm;
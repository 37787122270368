export const discountBlacklist = [
  '/karriere/',
  '/impressum/',
  '/datenschutz/',
  '/team/',
  '/sicherheit/',
  '/agb/',
  '/blog/',
  '/lexikon/',
  '/selbststaendig-machen/',
  '/stb',
  '/ratgeber/',
  '/register',
  '/preise/',
  '/email-bestaetigt/',
  '/email-bestaetigen/',
  '/fuer-steuerberater/',
  '/steuerberater',
  '/presse/',
  '/partner/',
]

const legals = [
  '/impressum/',
  '/datenschutz/',
  '/team/',
  '/sicherheit/',
  '/agb/',
  '/email-bestaetigt/',
  '/email-bestaetigen/',
]

export const vorlagenBlacklist = [
  "agb-muster/",
  "abmahnungsvorlage/",
  "abschlagsrechnung-muster/",
  "akontorechnung-muster/",
  "angebotsvorlage/",
  "angebotsvorlage-kleinunternehmer/",
  "anzahlungsrechnung-muster/",
  "arbeitszeugnis-muster/",
  "aufhebungsvertrag-muster/",
  "auftragsbestaetigung-vorlage/",
  "bwa-vorlage/",
  "bewirtungsbeleg-vorlage/",
  "bilanz-vorlage/",
  "vorlage-budgetplanung/",
  "businessplan-vorlage/",
  "datenschutzerklaerung-muster/",
  "dauerrechnung-muster/",
  "dienstplan-vorlage/",
  "differenzbesteuerung-rechnung-muster/",
  "dolmetscher-rechnung-muster/",
  "eigenbeleg-vorlage/",
  "eur-vorlage/",
  "fahrtenbuch-vorlage/",
  "finanzierungsplan-vorlage/",
  "gbr-vertrag-muster/",
  "gehaltsabrechnung-muster/",
  "geschaeftsbrief-vorlage/",
  "gewinn-verlustrechnung-vorlage/",
  "gutschrift-vorlage/",
  "handelsrechnung-muster/",
  "honorarrechnung-muster/",
  "impressum-vorlage/",
  "inventurliste-vorlage/",
  "kassenbericht-vorlage/",
  "kostenlose-excel-kassenbuch-vorlage/",
  "kfz-rechnung-muster/",
  "kleinbetragsrechnung-muster/",
  "kostenlose-lieferschein-vorlagen/",
  "kostenvoranschlag-vorlage/",
  "lastenheft-vorlage/",
  "mahnungsvorlage/",
  "pauschalrechnung-muster/",
  "pflichtenheft-vorlage/",
  "preiskalkulation-vorlage/",
  "pressemitteilung-vorlage/",
  "rechnungsvorlage-privatperson/",
  "proformarechnung/",
  "vorlage-provisionsabrechnung/",
  "quittungsvordruck-vorlage/",
  "rechnung-in-schweiz-muster/",
  "rechnungskorrektur-muster/",
  "rechnung-vorlage/",
  "rechnungsvorlage-dienstleistung/",
  "rechnungsvorlage-englisch/",
  "rechnungsvorlage-fotograf/",
  "rechnungsvorlage-freiberufler/",
  "rechnungsvorlage-gastronomie/",
  "rechnungsvorlage-handwerker/",
  "rechnungsvorlage-kleinunternehmer/",
  "rechnungsvorlage-musiker/",
  "rechnung-ohne-umsatzsteuer-vorlage/",
  "reisekostenabrechnung-vorlage/",
  "reverse-charge-rechnung-vorlage/",
  "risikoanalyse-vorlage/",
  "saldenbestaetigung-muster/",
  "sammelrechnung-muster/",
  "schlussrechnung-muster/",
  "schriftliche-vereinbarung-vorlage/",
  "spesenabrechnung-vorlage/",
  "sponsoringrechnung-vorlage/",
  "stornorechnung-vorlage/",
  "stundenzettel-vorlage/",
  "teilrechnung-muster/",
  "verschwiegenheitserklaerung-muster/",
  "vollmacht-muster/",
  "zahlungsbestaetigung-vorlage/",
  "zahlungserinnerung-vorlage/",
  "zahlungsnachweis-vorlage/",
  "zeiterfassung-vorlage/"
]

export const checkDiscountBlackList = (pathname) => {
  for (var i = 0; i < discountBlacklist.length; i++) {
    if (pathname.startsWith(discountBlacklist[i])) {
      return false
    }
  }

  return true
}


export const checkPathsAndBlacklist = (paths) => {
    const pathname = window.location.pathname === "/" ? "/startseite/" : window.location.pathname
    if(paths.includes("*") || paths.includes(pathname)){
        return true
    } else if(paths.includes("legals*")){
        if(legals.includes(pathname)){
            return true
        }
    } else if(paths.includes("vorlagen*")){
      if(vorlagenBlacklist.includes(pathname)){
          return true
      }
  }
  else if(paths.includes("discount_paths*")){
        return checkDiscountBlackList(pathname)
    } else if(paths.some(item => item.includes("*"))){
        const filteredPaths = paths.filter(item => item.includes("*"))
        for(const path of filteredPaths){
            if(pathname.startsWith(path.replace("*", ""))){
                return true
            }
        }
    }
    return false
}

// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getDate } from '../../utils/getDate';
import Button from '../button';
import EmailFormField from '../../components/form/EmailFormField';
import Link from '../base/Link';
import KnownBadges from '../trust-icons/testBadges/KnownBadges';

// import { useDetectDevice } from '../../assets/js/hook/useDetectDevice';
import { useDiscountContext } from '../../hooks/useDiscountContext';
import { useMediaQuery } from '../../utils/useMediaQuery';

import './newHero.scss';

const HeroTVSpot = (props) => {
  const { target, ...discount } = useDiscountContext()
  // const [date, setDate] = useState(undefined)
  const [hero, setHero] = useState({
    imageUrlDesktop:
      'https://sevdesk.imgix.net/34060/1663942690-titelbild_test_hero.png?auto=format,compress&q=80',
    imageUrlMobile: 'https://sevdesk.imgix.net/34060/1663240722-floating-phones-sevdesk-app.jpg?auto=format,compress',
    pillHtml: 'Mit sevDesk bereit für E-Rechnung und Wachstumschancengesetz',
    pillLink: '/ratgeber/buchhaltung-finanzen/wachstumschancengesetz/#besteuerung',
    headlineHtml: 'Keine Experimente. Saubere Buchhaltung für Gründer und Selbstständige.',
  })

  const isMobile = useMediaQuery('(max-width: 1024px)');
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  useEffect(() => {

    let heroObject = {
      imageUrlDesktop:
        'https://sevdesk.imgix.net/34060/1663942690-titelbild_test_hero.png?auto=format,compress&q=80',
      imageUrlMobile: '',
      pillHtml: 'Mit sevDesk bereit für E-Rechnung und Wachstumschancengesetz',
      pillLink: '/ratgeber/buchhaltung-finanzen/wachstumschancengesetz/#besteuerung',
      headlineHtml: 'Keine Experimente. Saubere Buchhaltung für Gründer und Selbstständige.',
    }
    if (discount?.additionalComponents?.hero?.enabled) {
      if (!discount?.additionalComponents?.hero?.isCustom) {
        heroObject = {
          ...heroObject,
          pillLink: '/preise/',
          ...discount?.additionalComponents?.hero,
        }
      } else {
        new Function(discount?.additionalComponents?.hero?.customJs)()
      }
    }
    setHero(heroObject)
  }, [])

  return (
    <>
      {isMobile &&
        <section className="mobileHeroHomepage">
          <div className="container">
            <div className="mobileHeroHomepageContainer" >
              <h1 className="title">
                Keine Experimente. Saubere Buchhaltung für Gründer und Selbstständige.
              </h1>
              <div className="description">
                <p>
                  Beeindrucke deine Kunden mit <strong>professionellen Rechnungen</strong> , verstehe <strong>wieviel Geld du letzten Monat verdient hast</strong> und wer dir noch was schuldet.
                  Alles<strong> GoBD-konform</strong> und mit Schnittstelle zu Elster und deinem Steuerberater.
                </p>
                {/* <ul className={`c-check-list-simple`}>
                  <li>Spare Zeit dank einfacher Rechnungsstellung</li>
                  <li>Vermeide Papierchaos mit digitaler Belegverwaltung</li>
                  <li>Behalte den Überblick über deinen Unternehmenserfolg</li>
                </ul> */}
                {/* <p>Beeindrucke deine Kunden mit professionellen Rechnungen, verstehe wieviel Geld du letzten Monat verdient hast und wer dir noch was schuldet.</p>
                  <p>Alles GoBD-konform und mit Schnittstelle zu Elster und deinem Steuerberater.</p> */}

              </div>
              <div className="mb-4" style={{ width: '100%' }}>
                <div id="hero-signup">
                  <div className="c-signup-form-s c-signup-form-s--sm mobileHeroHomepageInputfield">
                    <EmailFormField />
                  </div>
                </div>
                {/* <div className="fc-hero__actions pt-sm-2"><Button /></div> */}

              </div>
              <div className='mobileHeroHomepageImage'>
                <img
                  alt="dashboard"
                  src='https://sevdesk.imgix.net/34060/1680171974-dashboard.jpg?auto=format,compress&w=400'
                  fetchpriority="high"
                />
              </div>
              <div className='trustedContainer'>
                <img
                  className="register-card-image"
                  id="register-card-gobd"
                  src="https://sevdesk.imgix.net/34060/1657886329-trusted_gobd.png?auto=format,compress&w=100"
                />
                <img
                  className="register-card-image"
                  src="https://sevdesk.imgix.net/34060/1658127468-trusted.png?auto=format,compress&w=100"
                />
                <img
                  className="register-card-image"
                  src="https://sevdesk.imgix.net/34060/1709277899-trusted_omr_top_rated.png?auto=format,compress&w=100"
                />
              </div>
            </div>
          </div>
        </section>
      }

      {isDesktop && <>
        <div className="hero-test">
          <section className="l-overflow">
            <div className="fc-hero fc-hero--left fc-hero--white-text">
              <div className="container">
                <div className="fc-hero__content newHero col-md-6 col-12">
                  {discount ? (
                    <Link id="hero-test-pill" className="hero-test-pill" to='/e-rechnung-software/'>
                        {/* <Link id="hero-test-pill" className="hero-test-pill" to={hero?.pillLink}> */}
                      {/* <div className="hero-test-pill-pill">Bis zum {date}</div> */}
                      <div className="hero-test-pill-pill">News</div>
                      <div
                        className="hero-test-pill-text hero-test-pill-text-new"
                        dangerouslySetInnerHTML={{ __html: 'Mit sevDesk bereit für E-Rechnung und Wachstumschancengesetz' }}
                      />
                      {/* {discount?.counterEnabled && (
                        <div className="hero-test-pill-pill">
                          Bis zum {getDate(target, 'DD.MM.')}
                        </div>
                      )} */}
                      {/* <div
                        className="hero-test-pill-text"
                        dangerouslySetInnerHTML={{ __html: hero?.pillHtml }}
                      /> */}
                      <svg
                        width="12"
                        height="11"
                        viewBox="0 0 12 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.33268 6.16681H8.77935L5.52602 9.42014C5.26602 9.68014 5.26602 10.1068 5.52602 10.3668C5.78602 10.6268 6.20602 10.6268 6.46602 10.3668L10.8593 5.97348C11.1193 5.71348 11.1193 5.29348 10.8593 5.03348L6.47268 0.633477C6.21268 0.373477 5.79268 0.373477 5.53268 0.633477C5.27268 0.893477 5.27268 1.31348 5.53268 1.57348L8.77935 4.83348H1.33268C0.966016 4.83348 0.666016 5.13348 0.666016 5.50014C0.666016 5.86681 0.966016 6.16681 1.33268 6.16681Z"
                          fill="#006370"
                        />
                      </svg>
                    </Link>
                  ) : (
                    <div className="spacer"></div>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<h1 class='fc-hero__headline'>${hero?.headlineHtml}</h1>`,
                    }}
                  />
                  <div className="fc-hero__desc mb-4">
                    <p>Beeindrucke deine Kunden mit professionellen Rechnungen, verstehe wieviel Geld du letzten Monat verdient hast und wer dir noch was schuldet.</p>
                    <p>Alles GoBD-konform und mit Schnittstelle zu Elster und deinem Steuerberater.</p>
                    <div className="hero-test-signup-wrapper">
                      <p className="hero-test-signup-label">E-Mail-Adresse</p>
                      <div id="hero-test-signup">
                        <div className="mb-4">
                          <div id="hero-signup">
                            <div className="c-signup-form-s c-signup-form-s--sm">
                              <EmailFormField />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hero-test-signup-note">
                        Teste sevDesk 14 Tage völlig kostenlos. Keine Kreditkarte erforderlich und
                        die Testphase endet automatisch. Es gelten die <Link to="/agb/">AGB</Link> &{' '}
                        <Link to="/datenschutz/">Datenschutzerklärung</Link>.
                        Wir verwenden deine E-Mail, um dir Infos rund um sevDesk bereitzustellen, denen du jederzeit widersprechen kannst.
                      </div>
                      <div className="hero-test-trusted">
                        <div className="hero-test-trusted-right">
                          <img
                            alt="trusted"
                            src="https://sevdesk.imgix.net/34060/1658127468-trusted.png?w=120&h=160&auto=format,compress"
                            loading="lazy"
                            width={'59px'}
                            height={'84px'}
                          />
                          <img
                            alt="omr_leader"
                            width={'84px'}
                            height={'84px'}
                            src="https://sevdesk.imgix.net/34060/1709277899-trusted_omr_top_rated.png?w=160&h=160&auto=format,compress"
                            loading="lazy"
                          />
                          <img
                            alt="netzsieger"
                            src="https://sevdesk.imgix.net/34060/1664525345-netzsieger.png?w=220&h=160&auto=format,compress&q=80"
                            loading="lazy"
                            width={'110px'}
                            height={'84px'}
                          />
                          <img
                            alt="chip"
                            width={'76px'}
                            height={'84px'}
                            src="https://sevdesk.imgix.net/34060/1679926577-chip-testsieger-2023.jpg?w=140&h=160&auto=format,compress&q=80"
                            style={{ paddingLeft: '6px' }}
                            loading="lazy"
                          />
                          <img
                            alt="pcmagazin"
                            width={'138px'}
                            height={'84px'}
                            src="https://sevdesk.imgix.net/34060/1680674170-pcm-0323-sehr-gut-sevdesk-buchhaltung.jpg?w=260&h=160&auto=format,compress&q=80"
                            style={{ paddingLeft: '6px' }}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fc-hero__actions pt-sm-2"></div>
                  <span className="fc-hero__note"></span>
                </div>
                <div className="hero-test-image">
                  <img
                    alt="dashboard"
                    src={hero?.imageUrlDesktop}
                    fetchpriority="high"
                    width={'751px'}
                    height={'540px'}
                  />
                </div>
              </div>{' '}
              <span className="fc-hero__overlay"></span>
            </div>
          </section>
        </div>
        <KnownBadges />
      </>}
    </>

  )



}

export default HeroTVSpot

// extracted by mini-css-extract-plugin
export var active = "PricingTableTest-module--active--c5707";
export var activeBtn = "PricingTableTest-module--activeBtn--7d72a";
export var badge = "PricingTableTest-module--badge--dee27";
export var bannerContainer = "PricingTableTest-module--bannerContainer--15d9d";
export var bannerList = "PricingTableTest-module--bannerList--b95b3";
export var bordered = "PricingTableTest-module--bordered--41d3a";
export var buttonContainer = "PricingTableTest-module--buttonContainer--953cc";
export var cardContainer = "PricingTableTest-module--cardContainer--3a00e";
export var couponStyle = "PricingTableTest-module--couponStyle--22fe8";
export var description = "PricingTableTest-module--description--4bd28";
export var descriptionExtra = "PricingTableTest-module--descriptionExtra--36165";
export var durationButtonGroup = "PricingTableTest-module--durationButtonGroup--8e60d";
export var featureList = "PricingTableTest-module--featureList--284c6";
export var mobileButton = "PricingTableTest-module--mobileButton--c6f63";
export var note = "PricingTableTest-module--note--22d33";
export var paddingTop = "PricingTableTest-module--paddingTop--9603f";
export var priceValue = "PricingTableTest-module--priceValue--f19b1";
export var pricingExtra = "PricingTableTest-module--pricingExtra--5f85e";
export var promoted = "PricingTableTest-module--promoted--597bc";
export var strikethrough = "PricingTableTest-module--strikethrough--54f5f";
export var switchButton = "PricingTableTest-module--switchButton--c182f";
export var switchButtonContainer = "PricingTableTest-module--switchButtonContainer--6e219";
export var title = "PricingTableTest-module--title--d8a09";
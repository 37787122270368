import React from 'react'
import Modal from '../base/Modal'
import Cookies from 'universal-cookie'
import Button from '../button'

const cookies = new Cookies()

class GEOModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      LanguageValue: '',
      open: true,
      // openFirst: false,
      // openSecond: false,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.setState({ LanguageValue: event.target.value })
  }

  onCloseModal = () => {
    this.setState({ open: false })
    this.props.onClose()
  }

  onOpenGEOWeiche = () => {
    this.setState({ open: true })
  }

  goToCountrySite = () => {
    var expDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365)

    cookies.set('geoConsent', this.state.LanguageValue, { expires: expDate, path: '/' })

    if (this.state.LanguageValue === language[process.env.GATSBY_LOCALE]) {
      this.props.onClose()
      this.onCloseModal()
    } else {
      window.location.href = site[process.env.GATSBY_LOCALE] + window.location.pathname + window.location.search
    }
  }

  componentDidMount() {
    this.setState({ LanguageValue: getInitRadioValue() })
  }

  render() {
    return (
      <Modal
        // modalId="f-privacy-modal"
        // classNames={{
        //   modal: 'geo-modal',
        // }}
        // center
        // closeOnEsc={false}
        // closeOnOverlayClick={false}
        // showCloseIcon={false}
        cx="geo-modal"
        open={this.state.open}
        onClose={this.onCloseModal}
      >
        <div className="geo-banner">
          <div className="container">
            <div className="row">
              <div className="col mb-2 mt-2">
                <h2 className="c-section-header__title h4 mb-3">
                  Wähle dein Land aus, damit sevdesk den Anforderungen deines Standorts entspricht
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-9 mb-1">
                <div className="form-check languageSwitcher">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="languageSwitcher"
                    id="austria"
                    value="austria"
                    defaultChecked
                    onChange={this.handleChange}
                  />
                  <label className="form-check-label" htmlFor="austria">
                    Österreich
                  </label>
                </div>
              </div>
              <div className="col-3 mb-1">
                <span className="lang-flag float-right">
                  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={30} viewBox="0 0 5 3">
                    <desc>Flag of Austria</desc>
                    <rect id="red_stripe1" width={5} height={3} y={0} x={0} fill="#ed2939" />
                    <rect id="white_stripe" width={5} height={2} y={1} x={0} fill="#fff" />
                    <rect id="red_stripe2" width={5} height={1} y={2} x={0} fill="#ed2939" />
                  </svg>
                </span>
              </div>

              <div className="w-100"></div>
              <div className="col-9 mb-3">
                <div className="form-check languageSwitcher">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="languageSwitcher"
                    id="germany"
                    value="germany"
                    onChange={this.handleChange}
                  />
                  <label className="form-check-label" htmlFor="germany">
                    Deutschland
                  </label>
                </div>
              </div>
              <div className="col-3 mb-3">
                <span className="lang-flag float-right">
                  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={30} viewBox="0 0 5 3">
                    <desc>Flag of Germany</desc>
                    <rect id="black_stripe" width={5} height={3} y={0} x={0} fill="#000" />
                    <rect id="red_stripe" width={5} height={2} y={1} x={0} fill="#D00" />
                    <rect id="gold_stripe" width={5} height={1} y={2} x={0} fill="#FFCE00" />
                  </svg>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="geo-banner__container_button">
                  <div className="col-lg-6">
                    <Button
                      id="dialog2_close_btn"
                      buttonType={'submit'}
                      title="Standort auswählen"
                      onClick={this.goToCountrySite}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default GEOModal

const language = {
  de: 'germany',
  'de-AT': 'austria',
}

const site = {
  de: 'https://sevdesk.at',
  'de-AT': 'https://sevdesk.de',
}

function getInitRadioValue() {
  const radioArray = document.getElementsByClassName('form-check-input')
  let value = ''
  for (let index = 0; index < radioArray.length; index++) {
    if (radioArray[index].checked) {
      value = radioArray[index].value
      break
    }
  }
  return value
}

import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { useLocation } from '@reach/router'
// import { setConsent } from '../base/SEO'
import Link from '../base/Link'
import Button from '../button'
import { getCookie, getUrlParameter, setCrossDomainCookie } from '../../utils'
import cookieTexts from '../../data/cookieContent.json'
import { toggleAnalytics } from '../../analytics/sevlytics'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import Tabs from '../base/Tabs'
/**
 * The consent modal should not be displayed on these pages.
 */
const excludedPages = [
  '/datenschutz',
  '/datenschutz/',
  '/impressum',
  '/impressum/',
  '/sicherheit-datenschutz/',
  '/sicherheit-datenschutz',
]

const ConsentModal = () => {
  let isExcludedPage = false

  excludedPages.forEach((excludedPage) => {
    const location = useLocation()
    if (location.pathname.endsWith(excludedPage) && getUrlParameter('cookieSettings') === '') {
      isExcludedPage = true
      if (typeof window !== 'undefined') document.body.style.visibility = 'unset'
    }
  })

  if (isExcludedPage) {
    return null
  }

  const cookies = new Cookies()

  const [isOpen, setIsOpen] = useState(false)
  const [showSettings, setShowSettings] = useState(false)

  const showSettingBanner = () => {
    setShowSettings(true)
  }

  const lockScroll = () => {
    document.body.style.height = '100vh'
    document.body.style.overflowY = 'hidden'
  }

  const unlockScroll = () => {
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.height = ''
    document.body.style.overflowY = ''
  }

  const updateConsent = (allowTracking, init) => {
    const expDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365)
    setCrossDomainCookie('userConsent', allowTracking)
    toggleAnalytics(allowTracking, init)

    setIsOpen(false)
    unlockScroll()
  }

  useEffect(() => {
    const consent = getCookie('userConsent')
    if (consent) {
      updateConsent(consent === 'true' ? true : false)
      if (consent !== 'true') {
        document.body.style.visibility = 'unset'
      }
      return
    } else {
      document.body.style.visibility = 'unset'
    }
    
    setIsOpen(true)
    lockScroll()

    if (getUrlParameter('cookieSettings') === 'true') {
      showSettingBanner()
    }

    return function cleanup() {
      unlockScroll()
    }
  }, [])

  return (
    isOpen && (
      <div className="cookie-banner-overlay">
        <div id="cookie" className={`cookie-banner`}>
          {!showSettings && (
            <Intro
              onSettingsClick={() => setShowSettings(true)}
              onSaveClick={() => {
                updateConsent(false, true)
              }}
              onAcceptAllClick={() => {
                updateConsent(true, true)
              }}
            />
          )}

          {showSettings && (
            <Settings
              onSaveClick={() => {
                updateConsent(false, true)
              }}
              onAcceptAllClick={() => {
                updateConsent(true, true)
              }}
            />
          )}
        </div>
      </div>
    )
  )
}

export default ConsentModal

const Intro = ({ onSettingsClick, onSaveClick, onAcceptAllClick }) => (
  <div className="container">
    <div className="row">
      <div className="col">
        <h2 className="c-section-header__title h2 mb-3">Deine Datenschutz-Einstellungen</h2>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <div className="scroll-container">
          <p>
            Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für
            soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren.
            Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner
            für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese
            Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt
            haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben. Sie willigen
            gemäß Art. 49 Abs. 1 lit. a DSGVO darüber hinaus ein, dass auch Anbieter in den USA Ihre
            Daten verarbeiten. In diesem Fall ist es möglich, dass Ihre Daten durch Behörden, zu
            Kontroll- oder Überwachungszwecken, möglicherweise auch ohne Rechtsbehelfsmöglichkeit,
            verarbeitet werden können. Weitere Informationen finden Sie in unserem{' '}
            <Link to="/impressum/" title="Impressum">
              Impressum
            </Link>{' '}
            oder unter unseren{' '}
            <Link to="/datenschutz/" title="Datenschutzbestimmungen">
              Datenschutzbestimmungen
            </Link>
            . Dort können Sie Ihre Zustimmung jederzeit widerrufen. Hier geht es weiter nur mit den
            {'  '}
            <Link
              title="Nur notwendige"
              onClick={onSaveClick}
              data-consent-button="Nur notwendige"
              style={{ cursor: `pointer` }}
              to={'/'}
            >
              notwendigen
            </Link>{' '}
            Cookies.
          </p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <div className="cookie-banner__container_button mt-3">
          {/* <Button
            buttonType={'submit'}
            className="cookie-link"
            title="Einstellungen"
            onClick={onSettingsClick}
            data-consent-button="Einstellungen"
          >
            Einstellungen
          </Button> */}
          <div
            className="cookie-link"
            title="Einstellungen"
            onClick={onSettingsClick}
            data-consent-button="Einstellungen"
          >
            Einstellungen
          </div>
          <div className="cookie-button">
            <Button
              id="dialog2_close_btn"
              buttonType={'submit'}
              onClick={onAcceptAllClick}
              data-consent-button="Alle akzeptieren"
            >
              Alle akzeptieren
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const Settings = ({ onSaveClick, onAcceptAllClick }) => {
  // const translateCookieDescription = (value) => {
  //   const destination = cookieTexts.find((destination) => destination.id === value)
  //   return destination ? destination.text : ''
  // }
  const [preferences, setPreferences] = useState({})
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 className="c-section-header__title h2 mb-3">Datenschutz-Einstellungen</h2>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="scroll-container mb-2">
            <Tabs>
              <div label="Marketing">
                <p>
                  <small>
                    Diese Technologien werden von Werbetreibenden verwendet, um Anzeigen zu
                    schalten, die für Ihre Interessen relevant sind.
                  </small>
                </p>

                <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={['0']}>
                  {cookieTexts
                    .filter((cookieText) => cookieText.category == 'Marketing')
                    .map((destination, i) => {
                      return (
                        <AccordionItem key={i} className="c-accordion__item">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <h6 className="c-accordion__item__title pb-1">
                                <i className="c-accordion__item__icon"></i>
                                {destination.id}
                              </h6>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="c-accordion__item__content">
                            <hr />
                            <div className="row pt-1" key={destination.id}>
                              <div className="col-12">
                                <div className="cookie-category-title">
                                  <div className="custom-control custom-switch">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={destination.id}
                                      checked={Boolean(preferences[destination.id])}
                                      onChange={() => {
                                        setPreferences({
                                          ...preferences,
                                          [destination.id]: !preferences[destination.id],
                                        })
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={destination.id}
                                    >
                                      {destination.id}
                                    </label>
                                  </div>
                                </div>

                                <div className="cookie-category-description">
                                  <h6>Anbieter</h6>
                                  <p>{destination.provider}</p>
                                  <h6>Zweck</h6>
                                  <div dangerouslySetInnerHTML={{__html: `<p>${destination.purpose}</p>`}}></div>
                                  <h6>Rechtsgrundlage</h6>
                                  <p>{destination.paragraph}</p>
                                  <h6>Datenschutzerklärung</h6>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: destination.privacyPolicy }}
                                  ></div>
                                  <h6>Eingesetze Cookies und Laufzeit</h6>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: destination.cookieName }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )
                    })}
                </Accordion>
              </div>
              <div label="Promotion">
                <p>
                  <small>
                    Bitte beachten Sie, dass für die über unsere Verarbeitung hinausgehende
                    Verwendung Ihrer Daten die Verantwortung bei diesen Unternehmen liegt und sie
                    ohne unsere Mitwirkung erfolgt.
                  </small>
                </p>
                <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={['0']}>
                  {cookieTexts
                    .filter((cookieText) => cookieText.category == 'Promotion')
                    .map((destination, i) => {
                      return (
                        <AccordionItem key={i} className="c-accordion__item">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <h6 className="c-accordion__item__title pb-1">
                                <i className="c-accordion__item__icon"></i>
                                {destination.id}
                              </h6>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="c-accordion__item__content">
                            <hr />
                            <div className="row pt-1" key={destination.id}>
                              <div className="col-12">
                                <div className="cookie-category-title">
                                  <div className="custom-control custom-switch">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={destination.id}
                                      checked={Boolean(preferences[destination.id])}
                                      onChange={() => {
                                        setPreferences({
                                          ...preferences,
                                          [destination.id]: !preferences[destination.id],
                                        })
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={destination.id}
                                    >
                                      {destination.id}
                                    </label>
                                  </div>
                                </div>

                                <div className="cookie-category-description">
                                  <h6>Anbieter</h6>
                                  <p>{destination.provider}</p>
                                  <h6>Zweck</h6>
                                  <div dangerouslySetInnerHTML={{__html: `<p>${destination.purpose}</p>`}}></div>
                                  <h6>Rechtsgrundlage</h6>
                                  <p>{destination.paragraph}</p>
                                  <h6>Datenschutzerklärung</h6>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: destination.privacyPolicy }}
                                  ></div>
                                  <h6>Eingesetze Cookies und Laufzeit</h6>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: destination.cookieName }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )
                    })}
                </Accordion>
              </div>
              <div label="Essenziell">
                <p>
                  <small>
                    Essenzielle Technologien sind notwendig, damit unsere Website einwandfrei
                    funktioniert. Sie helfen dabei, Grundfunktionen wie Seitennavigation, Formulare
                    und Zugriff auf sichere Bereiche der Website zu ermöglichen.
                  </small>
                </p>
                <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={['0']}>
                  {cookieTexts
                    .filter((cookieText) => cookieText.category == 'Essential')
                    .map((destination, i) => {
                      return (
                        <AccordionItem key={i} className="c-accordion__item">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <h6 className="c-accordion__item__title pb-1">
                                <i className="c-accordion__item__icon"></i>
                                {destination.id}
                              </h6>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="c-accordion__item__content">
                            <hr />
                            <div className="row pt-1" key={destination.id}>
                              <div className="col-12">
                                <div className="cookie-category-title">
                                  <div className="custom-control custom-switch">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={destination.id}
                                      checked
                                      disabled
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={destination.id}
                                    >
                                      {destination.id}
                                    </label>
                                  </div>
                                </div>

                                <div className="cookie-category-description">
                                  <h6>Anbieter</h6>
                                  <p>{destination.provider}</p>
                                  <h6>Zweck</h6>
                                  <div dangerouslySetInnerHTML={{__html: `<p>${destination.purpose}</p>`}}></div>
                                  <h6>Rechtsgrundlage</h6>
                                  <p>{destination.paragraph}</p>
                                  <h6>Datenschutzerklärung</h6>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: destination.privacyPolicy }}
                                  ></div>
                                  <h6>Eingesetze Cookies und Laufzeit</h6>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: destination.cookieName }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )
                    })}
                </Accordion>
              </div>
            </Tabs>
          </div>
          <p style={{ lineHeight: '1' }}>
            <small style={{ color: '#838383', fontSize: '8px' }}>
              *Die Einstellungen dienen derzeit allein der Kundeninformation. Wir möchten drauf
              hinweisen, dass wir aus technischen Gründen, derzeit alle Cookies ausschalten, sobald
              eine Einwilligung nicht erteilt wird!
            </small>
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="cookie-banner__container_button mt-3">
            <div className="col-12 col-md-5 col-xl-4">
              {' '}
              <Button
                buttonType={'submit'}
                title="Auswahl speichern"
                style={{ background: '#fff', color: '#4076bd' }}
                data-consent-button="Auswahl speichern"
                onClick={() => {
                  onSaveClick()
                }}
              >
                Auswahl speichern
              </Button>
            </div>
            <div className="col-12 col-md-5 col-xl-4">
              {' '}
              <Button
                buttonType={'submit'}
                id="dialog2_close_btn"
                title="Alle Akzeptieren"
                data-consent-button="Alle Akzeptieren"
                onClick={() => {
                  onAcceptAllClick()
                }}
              >
                Alle Akzeptieren
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

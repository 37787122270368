import React from 'react'
import BranchenSearchHero from '../branchenSearch/BranchenSearchHero'
import Typewriter from '../Typewriter'
import useMediaQuery from '../../hooks/useMediaQuery'

function HeroTyped(props) {
  const {
    addClass,
    imageUrl,
    typedOptions,
    title,
    typedText,
  } = props;

  const additionalClass = addClass || '';
  const image = imageUrl || 'https://sevdesk.imgix.net/funktionen-hero.jpg';
  const options = typedOptions && JSON.parse(typedOptions);
  const typedArray = options
    ? options
    : ['Händler.', 'Grafikdesigner & Fotografen.', 'Freiberufler.', 'Handwerker.', 'jeden Selbständigen.'];

  const isMobile = useMediaQuery('(max-width: 1024px)');

  return (
    <div
      data-bgset={`${image}?auto=format,compress&q=75${isMobile ? '&w=425&h=375&fit=crop' : '&w=1920&h=500&fit=crop'}`}
      style={{
        backgroundImage: `url(${image}?auto=format,compress&q=75${isMobile ? '&w=425&h=375&fit=crop' : '&w=1920&h=500&fit=crop'})`,
      }}
      className={`c-hero-typed l-section--padding c-hero-typed--blue lazyload ${additionalClass}`}
    >
      <div className="container py-lg-4">
        <div className="row justify-content-center">
          <div className="col-11 col-sm-8 col-md-6">
            <h1 className="display-1 text-white" dangerouslySetInnerHTML={{ __html: title }}></h1>
            <h2 className="h3 text-white" style={{ height: '3em' }}>
              {' '}
              <div dangerouslySetInnerHTML={{ __html: typedText }}></div>
              <Typewriter typedArray={typedArray} typingSpeed={50} deleteSpeed={30} pauseDuration={900} />
            </h2>

            <BranchenSearchHero></BranchenSearchHero>

            <p className="text-white u-text-note text-center mb-0">
              Deine Branche ist nicht dabei? Schreib uns{' '}
              <a className="text-white u-text-underline" href={"#branch-feedback"}>
                deinen Wunsch
              </a>
              !
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroTyped;
import React, { lazy } from 'react'

// import WechselnTemplatePagebuilder from '../components/signup/WechselnTemplatePagebuilder';
import RegisterMandanten from '../components/custom_id_content/RegisterMandanten'
import RegisterDefault from '../components/custom_id_content/Register'
import RegisterEntryLevel from '../components/custom_id_content/RegisterEntryLevel'
import HeroTV2022 from '../components/hero/HeroTVSpot2022'

import AbschreibungsrechnerCustomTemplate from '../components/custom_id_content/smart-rechner/AbschreibungsrechnerCustomTemplate'
import CashflowRechnerCustomTemplate from '../components/custom_id_content/CashflowrechnerCustomTemplate'
import MinijobRechnerCustomTemplate from '../components/custom_id_content/smart-rechner/MinijobrechnerCustomTemplate'
import KreditRechnerCustomTemplate from '../components/custom_id_content/smart-rechner/KreditrechnerCustomTemplate'
import MargenRechnerCustomTemplate from '../components/custom_id_content/smart-rechner/MargenrechnerCustomTemplate'
import FirmenwagenRechnerCustomTemplate from '../components/custom_id_content/smart-rechner/FirmenwagenrechnerCustomTemplate'
import WährungsRechnerCustomTemplate from '../components/custom_id_content/smart-rechner/WährungsrechnerCustomTemplate'
import PayPalRechnerCustomTemplate from '../components/custom_id_content/smart-rechner/PayPalrechnerCustomTemplate'
import FristenRechnerCustomTemplate from '../components/custom_id_content/smart-rechner/FristenrechnerCustomTemplate'
import ProzentRechnerCustomTemplate from '../components/custom_id_content/smart-rechner/ProzentrechnerCustomTemplate'
import SteuersparRechnerCustomTemplate from '../components/custom_id_content/smart-rechner/SteuersparrechnerCustomTemplate'
// import PricingTableTest from '../components/preise/pricing-page-test/PricingTableTest';
import PricingPageComponent from '../components/preise/pricing-page-test/PricingPageComponent';
import GewerbesteuerRechnerCustomTemplate from '../components/custom_id_content/smart-rechner/GewerbesteuerrechnerCustomTemplate';
import EinkommensteuerRechnerCustomTemplate from '../components/custom_id_content/smart-rechner/EinkommensteuerrechnerCustomTemplate';
import MargenRechner from '../components/calculator/MargenrechnerNeu';
import ProzentRechner from '../components/calculator/ProzentrechnerNeu';
import SupportFaq from '../components/custom_id_content/supportFaq/SupportFaq'
const WechselnTemplatePagebuilder = lazy(() =>
  import('../components/signup/WechselnTemplatePagebuilder'),
)

const EinkommenSteuerRechner = lazy(() => import('../components/calculator/Einkommenssteuerrechner'))
const MehrwertsteuerRechner = lazy(() => import('../components/calculator/Mehrwertsteuerrechner'))
const StundensatzRechner = lazy(() => import('../components/calculator/Stundensatzrechner'))
const BentoComponentRechnungenSchreiben = lazy(() => import('../components/custom_id_content/BentoComponents/RechnungenSchreiben/BentoComponent'));
const BentoComponentStartseite = lazy(() => import('../components/custom_id_content/BentoComponents/Startseite/BentoComponent'));

const PricingTable = lazy(() => import('../components/preise/PricingTable'))
const CTAEntryLevelOffer = lazy(() => import('../components/cta/entryLevelOffer'))
const BranchenList = lazy(() => import('../components/custom_id_content/Branchen'))
const StbBlogList = lazy(() => import('../components/custom_id_content/StbBlog'))
const AutorenList = lazy(() => import('../components/custom_id_content/Autoren'))
const BranchRecommendationForm = lazy(() => import('../components/form/BranchRecommendation'))
const IntegrationenSearch = lazy(() => import('../components/integration/integrationSearch'))
const PartnerSearch = lazy(() => import('../components/integration/partnerSearch'))
const BranchenSlider = lazy(() => import('../components/carousel/BranchenSlider'))
const BranchSearchIndex = lazy(() => import('../components/branchenSearch/BranchSearchIndex'))
const NeustartFeed = lazy(() => import('../components/flexible-content/NeustartFeed'))
const OnlineBankingSeach = lazy(() => import('../components/form/OnlineBankingSearch'))
const FAQPagebuilder = lazy(() => import('../components/faq/FAQPagebuilder'))
const Stundenlohnrechner = lazy(() => import('../components/calculator/Stundenlohnrechner'))
const ArbeitszeitrechnerCustomTemplate = lazy(() =>
  import('../components/custom_id_content/ArbeitszeitrechnerCustomTemplate'),
)
// const AbschreibungsrechnerCustomTemplate = lazy(() => import('../components/custom_id_content/smart-rechner/AbschreibungsrechnerCustomTemplate'));
// const CashflowRechnerCustomTemplate =  lazy(() => import('../components/custom_id_content/CashflowrechnerCustomTemplate'));
// const MinijobRechnerCustomTemplate =  lazy(() => import('../components/custom_id_content/smart-rechner/MinijobRechnerCustomTemplate'));
// const KreditRechnerCustomTemplate =  lazy(() => import('../components/custom_id_content/smart-rechner/KreditRechnerCustomTemplate'));
// const MargenRechnerCustomTemplate =  lazy(() => import('../components/custom_id_content/smart-rechner/MargenRechnerCustomTemplate'));
// const FirmenwagenRechnerCustomTemplate =  lazy(() => import('../components/custom_id_content/smart-rechner/FirmenwagenRechnerCustomTemplate'));
// const WährungsRechnerCustomTemplate =  lazy(() => import('../components/custom_id_content/smart-rechner/WährungsRechnerCustomTemplate'));
// const PayPalRechnerCustomTemplate =  lazy(() => import('../components/custom_id_content/smart-rechner/PayPalRechnerCustomTemplate'));
// const FristenRechnerCustomTemplate =  lazy(() => import('../components/custom_id_content/smart-rechner/FristenRechnerCustomTemplate'));
// const ProzentRechnerCustomTemplate =  lazy(() => import('../components/custom_id_content/smart-rechner/ProzentRechnerCustomTemplate'));
// const SteuersparRechnerCustomTemplate =  lazy(() => import('../components/custom_id_content/smart-rechner/SteuersparRechnerCustomTemplate'));
const GewerbesteuerRechner = lazy(() => import('../components/calculator/GewerbesteuerRechner'))
const Umsatzsteuerrechner = lazy(() => import('../components/calculator/Umsatzsteuerrechner'))
const Kundenstimmen = lazy(() => import('../components/custom_id_content/kundenstimmen'))
const Lexikon = lazy(() => import('../components/custom_id_content/Lexikon'))
const SteuerAbsetzen = lazy(() => import('../components/custom_id_content/SteuerAbsetzen'))
const Buchungssatz = lazy(() => import('../components/custom_id_content/Buchungssatz'))
const Unternehmenskennzahlen = lazy(() =>
  import('../components/custom_id_content/Unternehmenskennzahlen'),
)
const AmazonAktion = lazy(() => import('../components/custom_id_content/AmazonAktionTemplate'))
const FastbillTable = lazy(() => import('../components/custom_id_content/FastbillTable'))
const LexofficeTable = lazy(() => import('../components/custom_id_content/LexofficeTable'))
const DebitoorTable = lazy(() => import('../components/custom_id_content/DebitoorTable'))
const ZervantTable = lazy(() => import('../components/custom_id_content/ZervantTable'))
const Team = lazy(() => import('../components/custom_id_content/Team'))
const StbSuche = lazy(() => import('../components/steuerberater/StbPagebuilder'))
const WorkspaceSuche = lazy(() => import('../components/workspace/WorkspaceSuche'))
const PresseMitteilungen = lazy(() => import('../components/custom_id_content/Presse'))
const PresseForm = lazy(() => import('../components/custom_id_content/PresseForm'))
const RatgeberCTA = lazy(() => import('../components/ratgeber/ratgeber-cta'))
const HeroSEATemplate = lazy(() => import('../components/custom_id_content/HeroSEATemplate'))
const ContentSection = lazy(() => import('../components/content-section-scoped'))
const HubspotForm = lazy(() => import('react-hubspot-form'))
const Title = lazy(() => import('../components/base/Title'))
const Rechnungsgenerator = lazy(() => import('../components/tools/Rechnungsgenerator'))
const Angebotsgenerator = lazy(() => import('../components/tools/Angebotsgenerator'))
const Gewerbeanmeldung = lazy(() => import('../components/tools/Gewerbeanmeldung'))
const ShoppingLPComponent = lazy(() => import('../components/shoppingLP/index'))
const shopingLPFAQContent = lazy(() => import('./shoppingLPFiles/shopingLPFAQContent.json'))
const description = lazy(() => import('./shoppingLPFiles/description.json'))
const imageArrayRechnung = lazy(() => import('./shoppingLPFiles/imageArrayRechnung.json'))
const imageArrayRechnungAT = lazy(() => import('./shoppingLPFiles/imageArrayRechnungAT.json'))
const PricingHero = lazy(() => import('../components/custom_id_content/PricingHero'))
// const PricingTableAbtest = lazy(() => import('../components/preise/PricingTableTest'));
const RegisterRechnungsgenerator = lazy(() =>
  import('../components/custom_id_content/Register/RegisterRechnungsgenerator'),
)
const RegisterNew = lazy(() => import('../components/custom_id_content/Register/RegisterNew'))
const RegisterGlobalTest = lazy(() =>
  import('../components/custom_id_content/Register/RegisterGlobalTest'),
)
const AngebotsgeneratorKU = lazy(() => import('../components/tools/Angebotsgenerator-ku'))
const RechnungsgeneratorKU = lazy(() => import('../components/tools/Rechnungsgenerator-ku'))
const ToolsTable = lazy(() => import('../components/tools/table/table'))
const Roirechner = lazy(() => import('../components/tools/ROI-Rechner'))
const BusinessModelCanvas = lazy(() => import('../components/tools/BusinessModelCanvas'))
const FirmennamenGenerator = lazy(() => import('../components/tools/FirmennamenGenerator'))
const CashflowRechner = lazy(() => import('../components/tools/CashflowRechner'))
const RegisterReduced = lazy(() =>
  import('../components/custom_id_content/Register/RegisterReduced'),
)
const AkquiseGenerator = lazy(() => import('../components/tools/AkquiseGenerator'))
const TarifWizard = lazy(() => import('../components/tools/TarifWizard'))
const AddOnSection = lazy(() => import('../components/addon/AddOnSection'))
const PricingTableNewAtlas = lazy(() => import('../components/preise/PricingTableNewAtlas'))
const Eigenbeleg = lazy(() => import('../components/tools/Eigenbeleg'))
const SegmentationComponent = lazy(() =>
  import('../components/custom_id_content/SegmentationComponent/SegmentationComponent'),
)
const EmployeesRecruitFriends = lazy(() =>
  import('../components/custom_id_content/EmployeesRecruitFriends/EmployeesRecruitFriends'),
)
const Switch = lazy(() => import('../components/custom_id_content/Producttour/Switch'))
const supportFaq = lazy(() => import('../components/custom_id_content/supportFaq/SupportFaq'))
export const customContentIds = {
  Producttour: <Switch />,
  EmployeesRecruitFriends: <EmployeesRecruitFriends />,
  BentoComponentRechnungenSchreiben: <BentoComponentRechnungenSchreiben />,
  BentoComponentStartseite: <BentoComponentStartseite />,
  eigenbeleg: <Eigenbeleg />,
  Rechnungsgenerator: <Rechnungsgenerator />,
  RechnungsgeneratorTable: <ToolsTable type={'rechnung'} />,
  deineRechnungTable: <ToolsTable type={'rechnung'} generator />,
  RechnungsgeneratorAT: <Rechnungsgenerator />,
  RechnungsgeneratorKleinunternehmerregelung: <RechnungsgeneratorKU />,
  Angebotsgenerator: <Angebotsgenerator />,
  AngebotsgeneratorTable: <ToolsTable type={'angebot'} />,
  deinAngebotTable: <ToolsTable type={'angebot'} generator />,
  GewerbeanmeldungOnline: <Gewerbeanmeldung />,
  AngebotsgeneratorAT: <Angebotsgenerator />,
  AngebotsgeneratorKleinunternehmerregelung: <AngebotsgeneratorKU />,
  ROIrechner: <Roirechner></Roirechner>,
  BusinessModelCanvasGenerator: <BusinessModelCanvas />,
  FirmennamenGenerator: <FirmennamenGenerator />,
  CashflowRechner: <CashflowRechner />,
  PricingHero: <PricingHero isNew={false} fullScreen={false} />,
  HeroSEATemplate: <HeroSEATemplate />,
  Form: <PresseForm />,
  SegmentationComponent: <SegmentationComponent />,
  // f4c9c80334775: <PricingTableAbTest />,
  f4c9c80334775: <PricingTableNewAtlas />,
  AddOnSection: <AddOnSection />,
  // priceTable_abtest: <PricingTableAbtest />,
  f324ff3qxc448: <WechselnTemplatePagebuilder />,
  Team: <Team />,
  Akquisegenerator: <AkquiseGenerator />,
  presseMitteilungen: <PresseMitteilungen />,
  BranchenList: <BranchenList />,
  AutorenList: <AutorenList />,
  BranchRecommendationForm: <BranchRecommendationForm />,
  IntegrationenSearch: <IntegrationenSearch />,
  StbBlogList: <StbBlogList />,
  Tarifwizard: <TarifWizard />,
  // PricingTableTest: <PricingTableTest />,
  PricingTableTest: <PricingPageComponent />,
  supportFaq: <SupportFaq />,
  shoppingLPRedesignRechnung:
    process.env.GATSBY_LOCALE === 'de' ? (
      <ShoppingLPComponent
        rate="Rechnung"
        faqContent={shopingLPFAQContent}
        description={description.rechnung}
        imageArray={imageArrayRechnung}
      />
    ) : (
      <ShoppingLPComponent
        rate="Rechnung"
        faqContent={shopingLPFAQContent}
        description={description.rechnung}
        imageArray={imageArrayRechnungAT}
      />
    ),
  shoppingLPRedesignBuchhaltung:
    process.env.GATSBY_LOCALE === 'de' ? (
      <ShoppingLPComponent
        rate="Buchhaltung"
        faqContent={shopingLPFAQContent}
        description={description.buchhaltung}
        imageArray={imageArrayRechnung}
      />
    ) : (
      <ShoppingLPComponent
        rate="Buchhaltung"
        faqContent={shopingLPFAQContent}
        description={description.buchhaltung}
        imageArray={imageArrayRechnungAT}
      />
    ),
  shoppingLPRedesignWarenwirtschaft:
    process.env.GATSBY_LOCALE === 'de' ? (
      <ShoppingLPComponent
        rate="Warenwirtschaft"
        faqContent={shopingLPFAQContent}
        description={description.warenwirtschaft}
        imageArray={imageArrayRechnung}
      />
    ) : (
      <ShoppingLPComponent
        rate="Warenwirtschaft"
        faqContent={shopingLPFAQContent}
        description={description.warenwirtschaft}
        imageArray={imageArrayRechnungAT}
      />
    ),

  PartnerSearch: <PartnerSearch />,
  RegisterMandanten: <RegisterMandanten />,
  RegisterReduced: <RegisterReduced />,
  RegisterDefault: <RegisterDefault />,
  RegisterEntryLevel: <RegisterEntryLevel />,
  RegisterGlobalTest: <RegisterGlobalTest />,
  RegisterRechnungsgenerator_Abtest: <RegisterRechnungsgenerator />,
  RegisterRechnungsgenerator: <RegisterNew type="Rechnung" />,
  RegisterAngebotsgenerator: <RegisterNew type="Angebot" />,
  f6b83g2342348: (
    <>
      <BranchSearchIndex />
      <BranchenSlider />
    </>
  ),
  HeroTVSpot2022: <HeroTV2022 />,
  fu93592394nf: <NeustartFeed />,
  ghsk36zsan1235: <AmazonAktion />,
  f238823423e84: (
    <>
      <Title paddingTop title="Wir freuen uns über deine Kontaktaufnahme!" />
      <ContentSection direction="top">
        <HubspotForm
          portalId="4999173"
          formId="919ffd48-c053-4f45-9148-ddfd9801068b"
          onSubmit={() => console.log('Submit!')}
          onReady={(form) => console.log('Form ready!')}
          loading={<div>Loading...</div>}
        />
      </ContentSection>
      {/* <ContentBlock paddingBottom center>
          <HubspotForm
            portalId="4999173"
            formId="919ffd48-c053-4f45-9148-ddfd9801068b"
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          />
        </ContentBlock> */}
    </>
  ),
  f7238423003g4: <OnlineBankingSeach />,
  FAQ: <FAQPagebuilder />,
  g234234234h66: <PricingTable />,
  hhu9234234233: <ArbeitszeitrechnerCustomTemplate />,
  Gewerbesteuerrechner: <GewerbesteuerRechner />,
  u83453452dsfff: <Stundenlohnrechner />,
  u4534ff345334: <Umsatzsteuerrechner />,
  Abschreibungsrechner: <AbschreibungsrechnerCustomTemplate />,
  Cashflowrechner: <CashflowRechnerCustomTemplate />,
  Minijobrechner: <MinijobRechnerCustomTemplate />,
  Kreditrechner: <KreditRechnerCustomTemplate />,
  Margenrechner: <MargenRechnerCustomTemplate />,
  Firmenwagenrechner: <FirmenwagenRechnerCustomTemplate />,
  Währungsrechner: <WährungsRechnerCustomTemplate />,
  PayPalrechner: <PayPalRechnerCustomTemplate />,
  Fristenrechner: <FristenRechnerCustomTemplate />,
  Prozentrechner: <ProzentRechnerCustomTemplate />,
  Steuersparrechner: <SteuersparRechnerCustomTemplate />,
  Einkommensteuerrechner: <EinkommensteuerRechnerCustomTemplate />,
  GewerbesteuerrechnerNeu: <GewerbesteuerRechnerCustomTemplate />,
  ProzentrechnerNeu: <ProzentRechner />,
  MargenrechnerNeu: <MargenRechner />,
  ZuVersteuerndesEinkommenSelbstständig: <EinkommenSteuerRechner title="hauptberuflich" isNebenberuflich={false} />,
  ZuVersteuerndesEinkommenNebenberuflich: <EinkommenSteuerRechner title="nebenberuflich" isNebenberuflich={true} />,
  Mehrwertsteuerrechner: <MehrwertsteuerRechner />,
  Stundensatzrechner: <StundensatzRechner />,
  k72343j2343c2: <Kundenstimmen />,
  l723e89832ddf: <Lexikon />,
  SteuerAbsetzen: <SteuerAbsetzen />,
  Buchungssatz: <Buchungssatz />,
  Unternehmenskennzahlen: <Unternehmenskennzahlen />,
  Fastbill: <FastbillTable />,
  lexoffice: <LexofficeTable />,
  Debitoor: <DebitoorTable />,
  Zervant: <ZervantTable />,
  SteuerberaterSuche: <StbSuche />,
  WorkspaceSuche: <WorkspaceSuche />,
  RatgeberCTA: <RatgeberCTA />,
  CtaEntryLevelOffer: <CTAEntryLevelOffer />,
}

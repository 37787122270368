import React from 'react'
import { Script } from 'gatsby'

class GewerbesteuerRechner extends React.Component {
  render() {
    return (
      <div className="calc">
        <div id="s_r_de_gewerbesteuer">
          <Script type="text/javascript">
            {`
            s_r_de_gewerbesteuer_premium = true;
            s_r_de_gewerbesteuer_theme = "bootstrap";
            s_r_de_gewerbesteuer_responsive = true;
            s_r_de_gewerbesteuer_slidedown = false;
            s_r_de_gewerbesteuer_openresult = false;
            s_r_de_gewerbesteuer_whitelabel = false;
            s_r_de_gewerbesteuer_charts = false;
            s_r_de_gewerbesteuer_title = "";
            s_r_de_gewerbesteuer_adlink = "";
            s_r_de_gewerbesteuer_adtext = "";
            s_r_de_gewerbesteuer_adtarget = "_blank";
            s_r_de_gewerbesteuer_adsize = "";
            s_r_de_gewerbesteuer_adlink_requestmethod = "post";
            s_r_de_gewerbesteuer_proxy_url = "";
            s_r_de_gewerbesteuer_param_gewerbegewinn = "50000";
            s_r_de_gewerbesteuer_param_hinzurechnungsbetrag = "";
            s_r_de_gewerbesteuer_param_kuerzungsbetrag = "";
            s_r_de_gewerbesteuer_param_hebesatz = "200";
            s_r_de_gewerbesteuer_param_rechtsform = "1";
        `}
          </Script>
          <Script
            type="text/javascript"
            charset="utf-8"
            src="https://load.smart-rechner.de/widget/gewerbesteuer.js"
          ></Script>
        </div>
      </div>
    )
  }
}

export default GewerbesteuerRechner

import React from 'react'
import { Script } from 'gatsby'

class FristenRechner extends React.Component {
  render() {
    return (
      <div className="calc">
        <div id="s_r_de_fristen">
          <Script type="text/javascript">
            {`
            s_r_de_fristen_premium = true;
            s_r_de_fristen_theme = "smoothness";
            s_r_de_fristen_responsive = true;
            s_r_de_fristen_slidedown = false;
            s_r_de_fristen_openresult = false;
            s_r_de_fristen_whitelabel = false;
            s_r_de_fristen_charts = false;
            s_r_de_fristen_title = "";
            s_r_de_fristen_adlink = "";
            s_r_de_fristen_adtext = "";
            s_r_de_fristen_adtarget = "_blank";
            s_r_de_fristen_adsize = "";
            s_r_de_fristen_adlink_requestmethod = "post";
            s_r_de_fristen_proxy_url = "";
            s_r_de_fristen_param_frist_anfang = "TODAY";
            s_r_de_fristen_param_frist_art = "1";
            s_r_de_fristen_param_bundesland = "11";
            s_r_de_fristen_param_willenserklaerung = "ja";
            s_r_de_fristen_param_fristdauer_zeitraum = "1";
            s_r_de_fristen_param_fristdauer_anzahl = "1";
        `}
          </Script>
          <Script
            type="text/javascript"
            charset="utf-8"
            src="https://load.smart-rechner.de/widget/fristen.js"
          ></Script>
        </div>
      </div>
    )
  }
}

export default FristenRechner

// extracted by mini-css-extract-plugin
export var close = "ExitIntent-module--close--f1592";
export var closeBtn = "ExitIntent-module--close-btn--2752c";
export var exitBtns = "ExitIntent-module--exit-btns--aede6";
export var exitHeading = "ExitIntent-module--exit-heading--1822b";
export var exitImage = "ExitIntent-module--exit-image--09675";
export var exitImageOverlay = "ExitIntent-module--exit-image-overlay--7ec24";
export var exitIntentContainer = "ExitIntent-module--exit-intent-container--3a005";
export var exitIntentContainerLeft = "ExitIntent-module--exit-intent-container-left--fbfb6";
export var exitIntentContainerRight = "ExitIntent-module--exit-intent-container-right--b269e";
export var exitIntentPopup = "ExitIntent-module--exit-intent-popup--c1f3d";
export var exitNote = "ExitIntent-module--exit-note--f07d9";
export var exitText = "ExitIntent-module--exit-text--ef3d4";
export var testBtn = "ExitIntent-module--test-btn--ccbc9";
export var visible = "ExitIntent-module--visible--ea830";
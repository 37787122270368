import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'
import sevAnalytics from './sevAnalytics'
export const analytics = Analytics({
  app: 'sevdesk-website',
  plugins: [
    segmentPlugin({
      name: "segment",
      writeKey: "iKDuvCNSB0CxLuvNyUbHxbIMULHocZTK",
      enabled: false,
    }),
    sevAnalytics({
      name: 'sev-analytics',
      key: 'Zykudx5AOrxJwX2Q5vDxCqqDkcgRCDKz',
      enabled: true
    }),
  ],
})
if(typeof window !== 'undefined'){
  window.atlasAnalytics = analytics;
}

import React from 'react'
import { useMediaQuery } from '../../utils/useMediaQuery'
import { removeAutoFormat } from '../../utils'

const HeroPagebuilder = (props) => {
  // console.log("imageUrl", props.imageUrl)
  const newImage = removeAutoFormat(props.imageUrl && props.imageUrl)
  // console.log("newImage", newImage)
  const isMobile = useMediaQuery('(max-width: 768px)')
  const isDesktop = useMediaQuery('(min-width: 769px)')


  const heroProps = props.heroProps ? props.heroProps : ''
  const white = props.whiteText || heroProps[0] ? 'fc-hero--white-text' : ''
  const overlay =
    props.overlay || heroProps[1] ? '&blend-mode=color&blend-color=173E5E&con=-21&exp=-15' : ''
  const centerContent = props.center ? 'is-center' : ''
  const additionalClass = props.addClass ? props.addClass : ''
  const desktopImage = newImage ? newImage : ''
  const inhalt = props.children


  const dataBGSet = newImage
    ? `url(${desktopImage}?auto=format,compress&fit=crop&q=60&w=1440&h=700&fp-z=1&fp-y=.5&fp-x=.6&${overlay})`
    : ''


  return (
    <React.Fragment>
      {isMobile &&
        (<section className="l-overflow">
          <div className={`fc-hero fc-hero--left  ${white} ${centerContent} ${additionalClass} `}>
            <div className="container">
              <div className="fc-hero__content">
                <h1
                  className="fc-hero__headline display-3 mb-3"
                  dangerouslySetInnerHTML={{ __html: props.title }}
                ></h1>
                <div
                  className="fc-hero__desc mb-4"
                  dangerouslySetInnerHTML={{ __html: inhalt }}
                ></div>
                <div className="fc-hero__actions pt-sm-2">
                  {props.btnPrimary ? props.btnPrimary : null}{' '}
                  {props.btnSecondary ? props.btnSecondary : null}{' '}
                  {props.btnVideo ? props.btnVideo : null}
                </div>
                <span className="fc-hero__note">{props.note}</span>
              </div>
            </div>{' '}
          </div>
        </section>)
      }
      {isDesktop &&
        (<section className="l-overflow">
          <div
            className={`img-fluid fc-hero fc-hero--left ${white} ${centerContent} ${additionalClass} lazyload`}
            data-bgset={dataBGSet}
            style={{
              backgroundImage: dataBGSet,
            }}
          >
            <div className="container">
              <div className="fc-hero__content">
                <h1
                  className="fc-hero__headline display-3 mb-3"
                  dangerouslySetInnerHTML={{ __html: props.title }}
                ></h1>
                <div
                  className="fc-hero__desc mb-4"
                  dangerouslySetInnerHTML={{ __html: inhalt }}
                ></div>
                <div className="fc-hero__actions pt-sm-2">
                  {props.btnPrimary ? props.btnPrimary : null}{' '}
                  {props.btnSecondary ? props.btnSecondary : null}{' '}
                  {props.btnVideo ? props.btnVideo : null}
                </div>
                <span className="fc-hero__note">{props.note}</span>
              </div>
            </div>{' '}
            <span className="fc-hero__overlay" />
          </div>
        </section>)}
      <noscript>
        <section className="l-overflow">
          <div
            className={`img-fluid fc-hero fc-hero--left ${white} ${centerContent} ${additionalClass} lazyload`}
          >
            <div className="container">
              <div className="fc-hero__content">
                <h1
                  className="fc-hero__headline display-3 mb-3"
                  dangerouslySetInnerHTML={{ __html: props.title }}
                ></h1>
                <div
                  className="fc-hero__desc mb-4"
                  dangerouslySetInnerHTML={{ __html: inhalt }}
                ></div>
                <div className="fc-hero__actions pt-sm-2">
                  {props.btnPrimary ? props.btnPrimary : null}{' '}
                  {props.btnSecondary ? props.btnSecondary : null}{' '}
                  {props.btnVideo ? props.btnVideo : null}
                </div>
                <span className="fc-hero__note">{props.note}</span>
              </div>
            </div>{' '}
            <span className="fc-hero__overlay" />
          </div>
        </section>
      </noscript>
    </React.Fragment>
  )

}

export default HeroPagebuilder

import React from 'react'
import { Script } from 'gatsby'

class SteuersparRechner extends React.Component {
  render() {
    return (
      <div className="calc">
        <div id="s_r_de_steuer_absetzen">
          <Script type="text/javascript">
            {`
            s_r_de_steuer_absetzen_premium = true;
            s_r_de_steuer_absetzen_theme = "smoothness";
            s_r_de_steuer_absetzen_responsive = true;
            s_r_de_steuer_absetzen_slidedown = false;
            s_r_de_steuer_absetzen_openresult = false;
            s_r_de_steuer_absetzen_whitelabel = false;
            s_r_de_steuer_absetzen_charts = false;
            s_r_de_steuer_absetzen_title = "";
            s_r_de_steuer_absetzen_adlink = "";
            s_r_de_steuer_absetzen_adtext = "";
            s_r_de_steuer_absetzen_adtarget = "_blank";
            s_r_de_steuer_absetzen_adsize = "";
            s_r_de_steuer_absetzen_adlink_requestmethod = "post";
            s_r_de_steuer_absetzen_proxy_url = "";
            s_r_de_steuer_absetzen_param_ausgaben = "2000";
            s_r_de_steuer_absetzen_param_steuerjahr = "1";
            s_r_de_steuer_absetzen_param_bruttojahreseinkommen = "30000";
            s_r_de_steuer_absetzen_param_kirchensteuer = "3";
        `}
          </Script>
          <Script
            type="text/javascript"
            charset="utf-8"
            src="https://load.smart-rechner.de/widget/steuer_absetzen.js"
          ></Script>
        </div>
      </div>
    )
  }
}

export default SteuersparRechner

import { graphql, useStaticQuery } from 'gatsby'

export const getLocale = (() => {
  const cache = {}
  const locale = process.env.GATSBY_LOCALE || ''
  return (): locale => {
    if (!cache[locale]) {
      const { allLocalesJson } = useStaticQuery(graphql`
        query Locales {
          allLocalesJson {
            edges {
              node {
                locale
                siteURL
                test
                land
                tel
                vorlagenHubspotFormID
                contextSignupURL
              }
            }
          }
        }
      `)
      cache[locale] = allLocalesJson.edges.filter(({ node: { locale } }) => locale === process.env.GATSBY_LOCALE)[0].node
    }
    return cache[locale]
  }
})()

interface locale {
  locale: string
  siteURL: string
  test: string
  land: string
  tel: string
  vorlagenHubspotFormID: string
  contextSignupURL: string
}

import React, { useState, useEffect } from 'react'
import PricingTable from './PricingTableTest'
import { useCountdown } from '../../../assets/js/hook/useCountdown'
import { useDiscountContext } from '../../../hooks/useDiscountContext'
import EmailFormField from '../../form/EmailFormField'

import * as cardstyle from './PricingCTACard.module.scss'

export default function PricingPageComponent() {

  return (
    <div className="pricingTestPage">
      <PricingHeader />
      <Pills />
      <PricingTable />
      <CTACard />
    </div>
  )
}

const PricingHeader = () => (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-10 col-sm-9 col-md-7">
        <div className="pricingTestContainer pt-5">
          <h1 className="pricingTestH1">Preise</h1>
          <p className="pricingTestP">Wir haben den passenden Tarif für dein Business</p>
        </div>
      </div>
    </div>
  </div>
)

const CTACard = () => {
  return (
    <div className="container">
      <div className={cardstyle.cardContainer}>
        <div className={cardstyle.colLeft}>
          <p className={cardstyle.title}>
            Finde jetzt heraus, welcher Tarif am besten zu dir passt.
          </p>
          <p className={cardstyle.description}>
            Du bist dir noch nicht sicher, welche Funktionen du benötigst? <br />
            Du kannst alle Funktionen von sevDesk 14 Tage lang unverbindlich und kostenlos testen.
          </p>
          <div style={{ width: '100%' }}>
            <div className="c-signup-form-s c-signup-form-s--sm">
              <EmailFormField></EmailFormField>
            </div>
          </div>
        </div>
        <div className={cardstyle.colRight}>
          <ul className={`c-check-list-simple mb-0 ${cardstyle.list}`}>
            <li>
              <b className={cardstyle.bold}>14 Tage kostenlos testen.</b> Die Testphase endet
              automatisch und muss nicht gekündigt werden.
            </li>
            <li>
              <b className={cardstyle.bold}>Keine Kreditkarte erforderlich.</b> Einfach
              E-Mail-Adresse eingeben und direkt loslegen.
            </li>
            <li>
              <b className={cardstyle.bold}>Wir sind für dich da.</b> Unser Support steht dir bei
              Bedarf zur Seite.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export function Pills() {


  const { target, ...discount } = useDiscountContext()
  const showCounterBanner = shouldShowCounter(discount);
  // console.log('showCounterBanner!!', showCounterBanner)
  const [days, hours, minutes, seconds] = useCountdown(target)
  return (
    <>
      {/* {discount && discount ? (
            <div className='container p-3 p-md-2'>
                <div className='row justify-content-center'>
                    <div className={`pillContainer`}>
                        <span className={`pillDate`}>
                            {days}:{hours}:{minutes}:{seconds}
                        </span>
                        <span className={`pillText`}>Sichere dir jetzt bis zu 50% Rabatt auf deinen Wunschtarif</span>
                    </div>
                </div>
            </div>
            ) : (
                <div className="mt-2"></div>
            )} */}
      {discount?.counterEnabled ? (
        <div className="container p-3 p-md-2 mb-md-3">
          <div className="row justify-content-center">
            <div className={`pillContainer mx-3`}>
              {showCounterBanner && (
                <span className={`pillDate`}>

                  {days}:{hours}:{minutes}:{seconds}
                </span>)}

              <span className={`pillText`}>
                {discount?.additionalComponents?.topBanner?.html ? extractTextFromHTML(discount?.additionalComponents?.topBanner?.html) : 'Sichere dir jetzt bis zu 50% Rabatt auf deinen Wunschtarif'}
               
              </span>
            </div>
          </div>
        </div>
      ) : <div></div>}
    </>
  )
}


function shouldShowCounter(discount) {
  // Get today's date
  const today = new Date();

  // Extract the counters array from the discount object
  const { counters } = discount;
  if (counters && counters.length > 0) {
    // Loop through each counter to see if today falls within any defined ranges
    for (let i = 0; i < counters.length; i++) {
      const { startDate, endDate } = counters[i];
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Check if today's date is between the start and end dates
      if (today >= start && today <= end) {
        return true;
      }
    }
  }

  return false;
}


function extractTextFromHTML(html) {
  // Create a temporary DOM element
  const tempElement = document.createElement('div');
  // Set the provided HTML as the innerHTML of the temporary element
  tempElement.innerHTML = html || '';
  // Extract and return the text content
  return tempElement.textContent || tempElement.innerText;
}

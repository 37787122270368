import PropTypes from 'prop-types'
import React from 'react'
import { useDiscountContext } from '../../hooks/useDiscountContext'
import { getLocale } from '../../queries/locales'
import Button from '../button'
import Image from '../base/Image'
import { CountdownComponent } from '../functional/CountdownComponent'
import useMediaQuery from '../../hooks/useMediaQuery'
import * as styles from './Discount.module.scss'
import { isTodayInDateRanges } from '../../utils'

const DiscountComponent = ({ button, counterOn }) => {

  const { highestDiscount, ...discount } = useDiscountContext()
  const buttonText = button ? button : discount ? 'finde den passenden Tarif' : getLocale().test

  // const {discountPercentage} = getDiscountedPrice("biAnnual", 24,'warenwirtschaft', highestDiscount?.duration )

  const isMobile = useMediaQuery('(max-width: 1224px)')
  // const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' })


  const isDiscountActive = isTodayInDateRanges(discount?.counters)
  // console.log('isDiscountActive', isDiscountActive)

  return discount?.additionalComponents?.discountComponent?.enabled ? (
    <section className={`l-section l-section--padding`}>
      <div className={`container`}>
        <div className={`row lg-gutters justify-content-center`}>
          <div className={`${styles.content}`}>
            <div className={`col-12 col-md-5 p-0`}>
              <Image
                src={
                  discount?.additionalComponents?.discountComponent?.imageUrl &&
                    discount?.additionalComponents?.discountComponent?.imageUrl !== ''
                    ? `${discount?.additionalComponents?.discountComponent?.imageUrl}&w=450&h=360`
                    : `https://sevdesk.imgix.net/34060/1649152546-gobdmockup.png?auto=format&q=90&w=${isMobile ? 350 : 580}&h=${isMobile ? 280 : 490}`
                }
                alt="Deine sichere und smarte Buchhaltungssoftware"
                className={`${styles.img} ${discount ? styles.discountImage : ''}`}
                width={isMobile ? 350 : 580}
                height={isMobile ? 280 : 490}
              />
            </div>
            <div className={`col-12 col-md-7`}>
              <div className={`${styles.text}`}>
                {discount ? (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<h2 class='h2'>${discount?.additionalComponents?.discountComponent?.html}</h2>`,
                      }}
                    />
                    <p>
                      {/* Teste jetzt alle Funktionen von sevDesk 14 Tage kostenlos und erhalte
                      anschließend bei der Buchung deines Tarifs bis zu{' '}
                      <span>
                        {highestDiscount?.duration}{' '}
                        {highestDiscount?.duration === 1 ? 'Monat' : 'Monate'} kostenlos*
                      </span>{' '} */}
                      {discount?.additionalComponents?.discountComponent?.contentHtml}
                    </p>
                  </>
                ) : (
                  <>
                    <h2 className={`h2`}>
                      Deine <span className={`${styles.textHighlight}`}>sichere und smarte</span>{' '}
                      Buchhaltungssoftware.
                    </h2>
                    <p>Teste jetzt alle Funktionen von sevDesk 14 Tage kostenlos.</p>
                  </>
                )}
                {/*<p>*/}
                {/*Teste jetzt alle Funktionen von sevDesk 14 Tage kostenlos und erhalte anschließend bei der Buchung deines Tarifs bis zu <span>*/}
                {/*      {highestDiscount?.duration} {highestDiscount?.duration === 1 ? "Monat" : "Monate"} kostenlos**/}
                {/*    </span>{' '}*/}
                {/*</p>*/}
                {discount ? (
                  <>
                    <p>
                      Dein Rabattcode:{' '}
                      <span className={`${styles.discountCode}`}>{highestDiscount?.coupon}</span>
                      {/* <ul className={`c-check-list-simple`}>
                          <li>Starte jetzt in deine kostenlose Testphase</li>
                          <li>Finde den für dich passenden Tarif</li>
                          <li>Löse den Rabatt bei deiner Buchung ein und spare</li>
                      </ul> */}
                    </p>
                  </>
                ) : (
                  <>
                    {' '}
                    <p className={`mb-2`}>
                      Du schreibst <b>Angebote und Rechnungen, digitalisierst</b> und{' '}
                      <b>verwaltest deine Belege</b> und sparst Zeit mit der{' '}
                      <b>direkten Schnittstelle zu deinem Finanzamt</b>
                    </p>
                  </>
                )}
                <Button title={buttonText} url="/preise/" />
                <br />
                {discount && (
                  <span className={`d-block mt-3 mb-3 ${styles.smallprint}`}>
                    *Gültig bei Abschluss eines Tarifs mit einer Laufzeit von{' '}
                    {highestDiscount?.duration}{' '}
                    {highestDiscount?.duration === 1 ? 'Monat' : 'Monaten'}.
                  </span>
                )}
              </div>
            </div>
          </div>
          {discount && isDiscountActive && (
            <div className={`${styles.textCountdown} ${styles.countdown}`}>
              <>
                <CountdownComponent
                  imported_discount={discount}
                  showTitle={false}
                  showName={false}
                  showBtn={false}
                />
              </>
            </div>
          )}
        </div>
      </div>
    </section>
  ) : (
    ''
  )
}

export default DiscountComponent

DiscountComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLinkUrl: PropTypes.string,
  counterOn: PropTypes.bool,
  mockupImageUrl: PropTypes.string,
}

DiscountComponent.defaultProps = { counterOn: true, buttonText: '' }

import React, { useEffect } from 'react'

class ProzentRechner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      input1: '',
      input2: '',
      result: '12,00',
      selectedOption: 'option1',
      placeholder1: '10',
      placeholder2: '120',
      text1: 'Prozent von',
      text2: '',
      text3: 'sind',
      showCalculation: false,
      options: {
        option1: {
          result: '12,00',
          placeholder1: '10',
          placeholder2: '120',
          text1: 'Prozent von',
          text2: '',
          text3: 'sind',
        },
        option2: {
          result: '10,00',
          placeholder1: '12',
          placeholder2: '120',
          text1: 'von',
          text2: '',
          text3: 'sind',
        },
        option3: {
          result: '120,00',
          placeholder1: '12',
          placeholder2: '10',
          text1: 'sind',
          text2: 'Prozent',
          text3: 'von',
        },
        option4: {
          result: '132,00',
          placeholder1: '120',
          placeholder2: '10',
          text1: 'zuzüglich',
          text2: 'Prozent',
          text3: 'sind',
        },
        option5: {
          result: '10,00',
          placeholder1: '132',
          placeholder2: '120',
          text1: 'im Vergleich zu',
          text2: '',
          text3: 'sind',
        },
        option6: {
          result: '120,00',
          placeholder1: '132',
          placeholder2: '10',
          text1: 'sind',
          text2: 'Prozent',
          text3: 'mehr als',
        },
        option7: {
          result: '108,00',
          placeholder1: '120',
          placeholder2: '10',
          text1: 'abzüglich',
          text2: 'Prozent',
          text3: 'sind',
        },
        option8: {
          result: '9,09',
          placeholder1: '132',
          placeholder2: '120',
          text1: 'im Vergleich zu',
          text2: '',
          text3: 'sind',
        },
        option9: {
          result: '133,33',
          placeholder1: '120',
          placeholder2: '10',
          text1: 'sind',
          text2: 'Prozent',
          text3: 'weniger als',
        },
      },
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value }, this.calculateResult)
  }

  handleOptionChange = (e) => {
    const selectedOption = e.target.value
    this.setState({
      selectedOption,
      input1: '',
      input2: '',
      result: this.state.options[selectedOption].result,
      placeholder1: this.state.options[selectedOption].placeholder1,
      placeholder2: this.state.options[selectedOption].placeholder2,
      text1: this.state.options[selectedOption].text1,
      text2: this.state.options[selectedOption].text2,
      text3: this.state.options[selectedOption].text3,
    })
  }

  calculateResult = () => {
    const { selectedOption, input1, input2 } = this.state
    if (selectedOption === 'option1') {
      this.state.result = (input1 / 100) * input2
    } else if (selectedOption === 'option2') {
      this.state.result = (input1 / input2) * 100
    } else if (selectedOption === 'option3') {
      this.state.result = input1 / (input2 / 100)
    } else if (selectedOption === 'option4') {
      this.state.result = input1 * (1 + input2 / 100)
    } else if (selectedOption === 'option5') {
      this.state.result = (input1 / input2 - 1) * 100
    } else if (selectedOption === 'option6') {
      this.state.result = input1 / (1 + input2 / 100)
    } else if (selectedOption === 'option7') {
      this.state.result = input1 * (1 - input2 / 100)
    } else if (selectedOption === 'option8') {
      this.state.result = (input1 / input2 - 1) * 100
    } else if (selectedOption === 'option9') {
      this.state.result = input1 / (1 - input2 / 100)
    }
  }

  toggleCalculation = () => {
    // Funktion, um die Berechnung anzuzeigen oder auszublenden
    this.setState((prevState) => ({
      showCalculation: !prevState.showCalculation,
    }))
  }

  render() {
    const {
      result,
      selectedOption,
      placeholder1,
      placeholder2,
      text1,
      text2,
      text3,
      showCalculation,
    } = this.state

    return (
      <>
        <div className="container l-section--padding">
          <div className="d-flex flex-column align-items-center">
            <div className="c-calculator">
              <h1>Prozentrechner</h1>
              <form id="prozentForm">
                <div className="c-form-control">
                  <label>Typische Fragestellungen</label>
                  <select
                    value={selectedOption}
                    onChange={this.handleOptionChange}
                    className={`c-form-control__field`}
                  >
                    <optgroup label="Anteilig">
                      <option value="option1">Wie viel sind 10 Prozent von 120?</option>
                      <option value="option2">Wie viel Prozent sind 12 von 120?</option>
                      <option value="option3">Wie viel sind 12 von 10 Prozent?</option>
                    </optgroup>
                    <optgroup label="Zuzüglich">
                      <option value="option4">Wie viel sind 120 zuzüglich 10 Prozent?</option>
                      <option value="option5">
                        Wie viel Prozent mehr sind 132 im Verleich zu 120?
                      </option>
                      <option value="option6">132 sind 10 Prozent mehr als wie viel?</option>
                    </optgroup>
                    <optgroup label="Abzüglich">
                      <option value="option7">Wie viel sind 120 abzüglich 10 Prozent?</option>
                      <option value="option8">
                        Wie viel Prozent weniger sind 132 im Verleich zu 120?
                      </option>
                      <option value="option9">120 sind 10 Prozent weniger als wie viel?</option>
                    </optgroup>
                  </select>
                </div>
                <div className="formDiv">
                  <div className="inputDiv">
                    <input
                      placeholder={placeholder1}
                      type="number"
                      className={`formField`}
                      name="input1"
                      onChange={this.handleInputChange}
                      value={this.state.input1}
                    />
                  </div>
                  <div className="text">{text1}</div>
                  <div className="inputDiv">
                    <input
                      placeholder={placeholder2}
                      type="number"
                      className={`formField`}
                      name="input2"
                      onChange={this.handleInputChange}
                      value={this.state.input2}
                    />
                  </div>
                  {text2 !== '' && <div>{text2}</div>}
                </div>
              </form>
              <div className="result" id="resultDiv">
                {text3}
                <br />
                {result}
              </div>
              <div className="detailsDiv">
                <div>
                  <span>Details und Formel zur Berechnung</span>
                  <button
                    onClick={this.toggleCalculation}
                    style={showCalculation ? { transform: 'rotate(180deg)' } : {}}
                  >
                    <svg
                      fill="#000000"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 10l5 5 5-5z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                  </button>
                </div>
                {showCalculation && (
                  <>
                    Die konkrete Berechnung anhand deiner Eingaben lautet: <br />
                    <div className="calulactionDiv">
                      {selectedOption === 'option1' ? (
                        <>
                          {this.state.input1 != '' ? (
                            <>
                              {this.state.input1}/100 x {this.state.input2}
                            </>
                          ) : (
                            <>
                              {this.state.placeholder1}/100 x {this.state.placeholder2}
                            </>
                          )}
                        </>
                      ) : selectedOption === 'option2' ? (
                        <>
                          {this.state.input1 != '' ? (
                            <>
                              {this.state.input1} / {this.state.input2} × 100
                            </>
                          ) : (
                            <>
                              {this.state.placeholder1} / {this.state.placeholder2} × 100
                            </>
                          )}
                        </>
                      ) : selectedOption === 'option3' ? (
                        <>
                          {this.state.input1 != '' ? (
                            <>
                              {this.state.input1} / ({this.state.input2}/100)
                            </>
                          ) : (
                            <>
                              {this.state.placeholder1} / ({this.state.placeholder2}/100)
                            </>
                          )}
                        </>
                      ) : selectedOption === 'option4' ? (
                        <>
                          {this.state.input1 != '' ? (
                            <>
                              {this.state.input1} × (1 + {this.state.input2}/100)
                            </>
                          ) : (
                            <>
                              {this.state.placeholder1} × (1 + {this.state.placeholder2}/100)
                            </>
                          )}
                        </>
                      ) : selectedOption === 'option5' ? (
                        <>
                          {this.state.input1 != '' ? (
                            <>
                              ({this.state.input1} / {this.state.input2} - 1) × 100
                            </>
                          ) : (
                            <>
                              ({this.state.placeholder1} / {this.state.placeholder2} - 1) × 100
                            </>
                          )}
                        </>
                      ) : selectedOption === 'option6' ? (
                        <>
                          {this.state.input1 != '' ? (
                            <>
                              {this.state.input1} / (1 + ({this.state.input2}/100))
                            </>
                          ) : (
                            <>
                              {this.state.placeholder1} / (1 + ({this.state.placeholder2}/100))
                            </>
                          )}
                        </>
                      ) : selectedOption === 'option7' ? (
                        <>
                          {this.state.input1 != '' ? (
                            <>
                              {this.state.input1} × (1 - {this.state.input2}/100)
                            </>
                          ) : (
                            <>
                              {this.state.placeholder1} × (1 - {this.state.placeholder2}/100)
                            </>
                          )}
                        </>
                      ) : selectedOption === 'option8' ? (
                        <>
                          {this.state.input1 != '' ? (
                            <>
                              ({this.state.input1} / {this.state.input2} - 1) × 100
                            </>
                          ) : (
                            <>
                              <>
                                ({this.state.placeholder1} / {this.state.placeholder2} - 1) × 100
                              </>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {this.state.input1 != '' ? (
                            <>
                              {this.state.input1}/ (1 - {this.state.input2}/100)
                            </>
                          ) : (
                            <>
                              {this.state.placeholder1}/ (1 - {this.state.placeholder2}/100)
                            </>
                          )}
                        </>
                      )}
                      <br />
                      = <br />
                      {result}
                    </div>
                    Das Ergebnis wurde hierbei auf fünf Stellen gerundet. <br />
                    <span>Formel zur Berechnung</span>
                    Die allgemeine Berechnungsformel für die vorliegende Fragestellung lautet:
                    <br />
                    <div className="calulactionDiv">
                      {selectedOption === 'option1' ? (
                        <>
                          Prozentsatz/100 x Grundwert
                          <br />
                          = <br />
                          Prozentwert
                        </>
                      ) : selectedOption === 'option2' ? (
                        <>
                          Prozentwert / Grundwert × 100
                          <br />
                          = <br />
                          Prozentsatz
                        </>
                      ) : selectedOption === 'option3' ? (
                        <>
                          Prozentwert / (Prozentsatz/100)
                          <br />
                          = <br />
                          Grundwert
                        </>
                      ) : selectedOption === 'option4' ? (
                        <>
                          Grundwert × (1 + Prozentsatz/100)
                          <br />
                          = <br />
                          Prozentwert
                        </>
                      ) : selectedOption === 'option5' ? (
                        <>
                          (Prozentwert / Grundwert - 1) × 100
                          <br />
                          = <br />
                          Prozentsatz
                        </>
                      ) : selectedOption === 'option6' ? (
                        <>
                          Prozentwert / (1 + (Prozentsatz/100))
                          <br />
                          = <br />
                          Grundwert
                        </>
                      ) : selectedOption === 'option7' ? (
                        <>
                          Grundwert × (1 - Prozentsatz/100)
                          <br />
                          = <br />
                          Prozentwert
                        </>
                      ) : selectedOption === 'option8' ? (
                        <>
                          (Grundwert / Prozentwert - 1) × 100
                          <br />
                          = <br />
                          Prozentsatz
                        </>
                      ) : (
                        <>
                          Prozentwert / (1 - Prozentsatz/100)
                          <br />
                          = <br />
                          Grundwert
                        </>
                      )}
                    </div>
                    Dabei sind die Begriffe, wie folgt definiert:
                    <ul>
                      <li>
                        Grundwert: Die Ausgangsgröße, die 100 % entspricht und auf die sich der
                        Prozentsatz bezieht.
                      </li>
                      <li>
                        Prozentwert: Der Wert, der gemäß Prozentsatz vom Grundwert abgeleitet wurde.
                      </li>
                      <li>
                        Prozentsatz: Der Anteil vom Prozentwert am Grundwert, ausgedrückt in
                        Prozent.
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ProzentRechner

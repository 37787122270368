import React from 'react'
import { Script } from 'gatsby'

class FirmenwagenRechner extends React.Component {
  render() {
    return (
      <div className="calc">
        <div id="s_r_de_firmenwagen">
          <Script type="text/javascript">
            {`
            s_r_de_firmenwagen_premium = true;
            s_r_de_firmenwagen_theme = "smoothness";
            s_r_de_firmenwagen_responsive = true;
            s_r_de_firmenwagen_slidedown = false;
            s_r_de_firmenwagen_openresult = false;
            s_r_de_firmenwagen_whitelabel = false;
            s_r_de_firmenwagen_charts = false;
            s_r_de_firmenwagen_title = "";
            s_r_de_firmenwagen_adlink = "";
            s_r_de_firmenwagen_adtext = "";
            s_r_de_firmenwagen_adtarget = "_blank";
            s_r_de_firmenwagen_adsize = "";
            s_r_de_firmenwagen_adlink_requestmethod = "post";
            s_r_de_firmenwagen_proxy_url = "";
            s_r_de_firmenwagen_param_vergleich = "1";
            s_r_de_firmenwagen_param_antriebsart = "1";
            s_r_de_firmenwagen_param_listenpreis = "40000";
            s_r_de_firmenwagen_param_entfernung = "20";
            s_r_de_firmenwagen_param_steuersatz = "30";
            s_r_de_firmenwagen_param_anschaffungspreis = "35000";
            s_r_de_firmenwagen_param_lfd_kosten = "6000";
            s_r_de_firmenwagen_param_priv_nutzung = "20";
        `}
          </Script>
          <Script
            type="text/javascript"
            charset="utf-8"
            src="https://load.smart-rechner.de/widget/firmenwagen.js"
          ></Script>
        </div>
      </div>
    )
  }
}

export default FirmenwagenRechner

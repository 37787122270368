import React from 'react'
import { Script } from 'gatsby'

class WährungsRechner extends React.Component {
  render() {
    return (
      <div id="s_r_de_waehrungen_umr">
        <Script type="text/javascript">
          {`
            s_r_de_waehrungen_umr_premium = true;
            s_r_de_waehrungen_umr_theme = "smoothness";
            s_r_de_waehrungen_umr_responsive = true;
            s_r_de_waehrungen_umr_slidedown = false;
            s_r_de_waehrungen_umr_openresult = false;
            s_r_de_waehrungen_umr_whitelabel = false;
            s_r_de_waehrungen_umr_charts = false;
            s_r_de_waehrungen_umr_title = "";
            s_r_de_waehrungen_umr_adlink = "";
            s_r_de_waehrungen_umr_adtext = "";
            s_r_de_waehrungen_umr_adtarget = "_blank";
            s_r_de_waehrungen_umr_adsize = "";
            s_r_de_waehrungen_umr_adlink_requestmethod = "post";
            s_r_de_waehrungen_umr_proxy_url = "";
            s_r_de_waehrungen_umr_param_von_value = "1,00";
            s_r_de_waehrungen_umr_param_von_select = "7";
            s_r_de_waehrungen_umr_param_nach_select = "31";
        `}
        </Script>
        <Script
          type="text/javascript"
          charset="utf-8"
          src="https://load.smart-rechner.de/widget/waehrungen_umr.js"
        ></Script>
      </div>
    )
  }
}

export default WährungsRechner

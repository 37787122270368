import React from "react";
import * as styles from "./Loader.module.scss";
export default function Loader({message, isShown, style}) {
  return (
    <>
      <div className={styles.overlay} style={{display: isShown ? "flex" : "none", ...style}}>
          <div className={styles.loader}></div>
          <p>{message}</p>
        </div>
    </>
  );
}

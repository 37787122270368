import sevDesk from '../../httpClients/sevDesk'
import { trackUserSignedUp } from '../../analytics/sevlytics'
import { getCookie, getUrlParameter } from '../../utils'
import { getLocale } from '../../queries/locales'
import { getDiscountOrCampaign } from '../../assets/js/utils/getDiscountOrCampaign'


export const checkIfAlreadyRegistered = (email, loading, entryLevelOffer) => {
  sevDesk
    .checkEmail(email)
    .then((response) => {
      // console.log("RESPONSE", response.data)
      // this.plistaTracking();
      // this.linkedInTracking();
      // this.CNDTracking();
      console.log('resp data', response.result)
      setTimeout(() => {
        console.log(response)
        if (response.result === 1) {
          const referredBy =
            window.localStorage.getItem('referredBy') !== null &&
            window.localStorage.getItem('referredBy')
          const celloFwf =
            window.localStorage.getItem('referrer_token') !== null &&
            window.localStorage.getItem('referrer_token')
          const referredByPartner =
            getCookie('referredByPartner') !== '' &&
            getCookie('referredByPartner')
          const referredByAwin =
            window.localStorage.getItem('referredByAwin') !== null &&
            window.localStorage.getItem('referredByAwin')
          // Nur wenn sie sich über den Link registrieren
          const accountantToken =
            getUrlParameter('accountantToken') !== null && getUrlParameter('accountantToken')

          const msclkid = getCookie('_uetmsclkid')?.replace("_uet", "")
          const ga4ClientId = getCookie('_ga')?.replace("GA1.1.", "")

          createAccount(
            email,
            loading,
            referredBy,
            referredByPartner,
            referredByAwin,
            accountantToken,
            celloFwf,
            msclkid,
            ga4ClientId,
            entryLevelOffer
            )
        } else {
          sevDesk.toLogin(email)
        }
      }, 200)
    })
    .catch((error) => {
      // console.log("Check Error", error)
      hubspotForm("0376ba13-0b73-4abe-9737-e9e64e1a4c04", [{ name: "email", value: email }])
      loading(false)
    })
}

const getCookieGCLID = () => {
  //let gclid = getCookie("sev_gclid")
  let gclid = window.localStorage.getItem('sev_gclid')
  if (gclid !== undefined && gclid !== null) {
    return gclid
  } else {
    return ''
  }
}
export const createAccount = async(
  email,
  loading,
  referredBy,
  referredByPartner,
  referredByAwin,
  accountantToken,
  celloFwf,
  msclkid,
  ga4ClientId,
  entryLevelOffer
) => {
  let gclid = getCookieGCLID()
  const validDiscount = await getDiscountOrCampaign()
  let individual_discount_url = `${process.env.GATSBY_SEVATLAS_CDN}/v4/data/webApp/discount_${validDiscount?._id}.json`;
  if(validDiscount?.discountType === "campaign"){
    individual_discount_url = `${process.env.GATSBY_SEVATLAS_CDN}/v4/data/webApp/campaign_sevdesk.${process.env.GATSBY_LOCALE.replace(
      'de-AT',
      'at',
    )}.json`;
  }
  let payload = {
    username: email,
    auth: 1337,
    track_invite: referredBy || '',
    awc: referredByAwin,
    context: getLocale().contextSignupURL,
    track_partner: referredByPartner || '',
    gclid: gclid || '',
    accountantToken: accountantToken || '',
    referrer_token: celloFwf || '',
    individual_discount_url,
    msclkid: msclkid || '',
    ga4_clientId: ga4ClientId || '',
    entry_level_offer: !!entryLevelOffer,
  }
  if(!!entryLevelOffer){
    payload.campaign = `newFounder6Months=${entryLevelOffer}&6MonthsTrialType=Founder`
  }
  sevDesk
    .createAccount(payload)
    .then((response) => {
      // console.log("RESPONSE", response.data)
      console.log('resp data', response)
      
      if (response.success === 'true') {
        console.log('Your Client ID is ' + response?.createdClientID)
        // if ('url' in response.data) {
        //   let urlHack = response.data.url.replace('https://sevdesk.de/', 'https://my.sevdesk.de/')
        //   loginUrl = urlHack
        // } else {
        //   loginUrl = 'https://sevdesk.de/email-bestaetigen/'
        // }

        // Track with segment and redirect to dashboard
        //onSuccessCallback()
        trackUserSignedUp(response, email)
        // CND Tracking Start
        // CND Tracking END
        setTimeout(() => {
          // navigate(loginUrl)
          // navigate('/email-bestaetigen/')

          if(response.url){
            window.location.href = response.url;
          } else{
            window.location.pathname = '/email-bestaetigen/'
          }
          // loading(false)
        }, 200)
      } else {
        loading(false)
      }
    })
    .catch((error) => {
      hubspotForm("0376ba13-0b73-4abe-9737-e9e64e1a4c04", [{ name: "email", value: email }])
      loading(false)
    })
}

// const generatePersonalization = () => {
//   var perso = PERSONALISATIONS_WEBSITE;
//   const code = window.sevDataLayer.user.persoConfig.code;
//   const text = window.sevDataLayer.user.persoConfig.text;
//   perso.audience.tenant = window.sevDataLayer.user.channel;
//   perso.discount[1].autosetCoupon = code
//   perso.discount[12].autosetCoupon = code
//   perso.discount[24].autosetCoupon = code

//   perso.discount[1].tariffHeadingTranslate = text?.month.replace('{Code}', code)
//   perso.discount[12].tariffHeadingTranslate = text?.year.replace('{Code}', code)
//   perso.discount[24].tariffHeadingTranslate = text?.twoYears.replace('{Code}', code)
// }

import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './Tooltip.module.scss'

export default function Tooltip(props) {
  return (
    <div className={`${styles.tooltipContainer} ${props.abTest ? styles.isABTest : ''}`}>
      {props.children ? props.children : <i className={styles.tooltipIcon}></i>}
      <div className={`${props.leftSide ? styles.leftSide : styles.rightSide} ${styles.tooltipText}`} dangerouslySetInnerHTML={{ __html: props.content }} />
    </div>
  )
}

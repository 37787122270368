import '@popperjs/core/dist/umd/popper.min.js'
import React from 'react'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

import { trackPageView, evalHubspotTracking, loadSA } from './src/analytics/sevlytics'
import { getUrlParameter, setCrossDomainCookie } from './src/utils'
import { setComponentCounter } from './src/templates/render/buildContent'
import AtlasWrapper from './src/components/AtlasWrapper'
import DiscountProvider from './src/provider/DiscountProvider'
import LanguageNavigator from './src/components/cookie/LanguageNavigator'
import SocketProvider from './src/provider/SocketProvider'
import ChatBot from './src/components/chatbot/ChatBot'

// Wrap all Pages in an Atlas Component
export const wrapPageElement = ({ element, props }) => {
  return (
    <DiscountProvider {...props}>
      <SocketProvider>
        <ChatBot/>
        <LanguageNavigator />
        <AtlasWrapper {...props}>{element}</AtlasWrapper>
        {/* {element} */}
      </SocketProvider>
    </DiscountProvider>
  )
}

// Tracking Funtions
export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // const classNameToRemove = 'globalJsSnippets'
  // const scriptElements = document.querySelectorAll(`script.${classNameToRemove}`)

  // scriptElements.forEach((scriptElement) => {
  //   // Remove each script element
  //   scriptElement.remove()
  // })
  // console.log("SCRIPT REMOVE")
  window.previousPath = prevLocation?.pathname
}
// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  // getDiscountOrCampaign()
  const referrer = prevLocation ? process.env.SITE_URL + prevLocation.pathname : document.referrer

  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search)
    if (params.get('referrer') !== null) {
      localStorage.setItem('sa_referrer', params.get('referrer'))
    }
    // Setzt referredByPartner
    var referredByPartner = getUrlParameter('partner')
    if (referredByPartner) {
      window.localStorage.setItem('referredByPartner', referredByPartner)
      setCrossDomainCookie('referredByPartner', referredByPartner)
    }
    var celloFwf = getUrlParameter('ucc')
    if (celloFwf) {
      window.localStorage.setItem('referrer_token', celloFwf)
    }
    var celloFwf = getUrlParameter('ucc')
    if (celloFwf) {
      window.localStorage.setItem('referrer_token', celloFwf)
    }
    // window.dataLayer = window.dataLayer.filter(item => item.event !== "userConsent")
    setTimeout(() => {
      trackPageView(location.pathname, referrer && referrer)
    }, 200)

    window.dispatchEvent(new Event('popstate'))
  }

  // Setzt Counter
  setComponentCounter()
  if (typeof window !== 'undefined' && window.location.search.startsWith('?sref')) {
    const referredBy = window.location.href.split('?sref=')[1]
    window.localStorage.setItem('referredBy', referredBy)
  }

  evalHubspotTracking()
}
// export const wrapPageElement = ({ element, props }) => {
//   return <Overlay {...props}>{element}</Overlay>
// }
const crossDomainTracking = () => {
  if (typeof window.ga != 'function') {
    return false
  }
  window.ga('require', 'linker')
  window.ga('linker:autoLink', [
    'sevdesk.de',
    'sevdesk.ch',
    'sevdesk.at',
    'sevdesk.gr',
    'sevdesk.es',
    'sevdesk.uk',
    'sevdesk.com',
    'sevdesk.fr',
    'sevdesk.it',
  ])
  return true
}

function setCookieGCLID() {
  let gclid = getUrlParameter('gclid')
  if (gclid !== undefined && gclid !== '') {
    //setCookie("sev_gclid", gclid)
    localStorage.setItem('sev_gclid', gclid)
  }
}

async function initialCDNCheck() {
  try {
    if (typeof window !== 'undefined') {
      const response = await fetch('https://atlas-cdn.sevdesk.de')
      if (!response.ok) {
        document.body.style.visibility = 'unset'
      }
    }
  } catch (err) {
    console.log(err)
  }
}
try {
  initialCDNCheck()
  crossDomainTracking()
  setCookieGCLID()
  loadSA()
  // inject()
  // loadScripts()
} catch (e) {}

// export const addScript = url => {
//   const script = document.createElement("script")
//   script.src = url
//   script.async = true
//   document.body.appendChild(script)
// }

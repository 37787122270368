import React, { createContext, useEffect, useState } from 'react'
import { getDiscountOrCampaign } from '../assets/js/utils/getDiscountOrCampaign'
import Loader from '../components/Loader/Loader'
import ExitIntent from '../components/discount/ExitIntent'
// import TopBanner from '../components/discount/TopBanner'
import BlackweekTopBanner from '../components/discount/blackweek/TopBanner'
import { checkDiscountBlackList, checkPathsAndBlacklist, vorlagenBlacklist } from '../utils/atlas-paths'
import BottomBanner from '../components/discount/BottomBanner'

function getLastCouponValue(obj, keyName, parentKeys = ['biAnnual', 'annual', 'monthly']) {
  let lastCouponValue = null
  let lastCouponPath = null

  function findLastCoupon(obj, parentKeys, path = []) {
    for (const key in obj) {
      if (key === keyName && obj?.enabled === true) {
        lastCouponValue = obj[key]
        lastCouponPath = [...path, key]
      }

      if (typeof obj[key] === 'object') {
        const newPath = [...path, key]
        findLastCoupon(obj[key], parentKeys, newPath)
      }
    }
  }

  findLastCoupon(obj, parentKeys)
  return {
    coupon: lastCouponValue,
    duration: lastCouponPath?.includes('monthly')
      ? 1
      : lastCouponPath?.includes('annual')
        ? 12
        : 24,
  }
}

export const discountContext = createContext()


export default function DiscountProvider({ children, ...props }) {
  const [discount, setDiscount] = useState()
  const [isDiscountPage, setIsDiscountPage] = useState(false);
  const [isTemplatePage, setIsTemplatePage] = useState(false);
  const [target, setTarget] = useState({})
  const [highestDiscount, setHighestDiscount] = useState()
  const [isBlackWeek, setBlackweek] = useState(false)

  const fetchDiscount = async () => {
    const discount = await getDiscountOrCampaign()
    if (Array.isArray(discount?.counters)) {
      const target = Date.parse(
        discount?.counters
          ?.find((counter) => isValidCounter(counter?.endDate))
          ?.endDate?.replaceAll("'", ''),
      )
      setHighestDiscount(getLastCouponValue(discount, 'coupon'))
      setTarget(target)
    }
    setDiscount(discount ?? {})
  }
  useEffect(() => {
    setIsDiscountPage(checkDiscountBlackList(props.location.pathname))
    setIsTemplatePage(vorlagenBlacklist.some(item => props.location.pathname.includes(item)))
    fetchDiscount()
  }, [props.location.pathname])

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.search.startsWith("?blackweek=conversion")) {
        setBlackweek(true)
      }
    }
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.discount = {
        target,
        highestDiscount,
      }
    }
  }, [target, highestDiscount, discount])

  //! Laura / Ben
  const runAdditionalScripts = () => {
    if (discount?.additionalComponents?.scripts) {
      new Function("checkPaths", discount?.additionalComponents?.scripts)(checkPathsAndBlacklist);
    }
  }
  useEffect(() => {
    runAdditionalScripts()
  }, [discount])

  //   useEffect(() => {
  //     const target = Date.parse(
  //         discount?.counters
  //           ?.find((counter) => isValidCounter(counter?.endDate))
  //           ?.endDate?.replaceAll("'", ''),
  //       )
  //       setHighestDiscount(getLastCouponValue(discount, 'coupon'))
  //       setTarget(target)
  //   }, [discount])
  return (
    <>
      {discount ? (
        <discountContext.Provider value={{ ...discount, target, highestDiscount, setDiscount }}>
          {isDiscountPage && discount?.additionalComponents?.exitIntent?.enabled && (
            <ExitIntent {...discount?.additionalComponents?.exitIntent} />
          )}
          {!isBlackWeek && isDiscountPage && !isTemplatePage && discount?.additionalComponents?.topBanner?.enabled && (
            <BlackweekTopBanner
              bannerID="discountBanner"
              text={discount?.additionalComponents?.topBanner?.html}
              isCounterEnabled={discount?.counterEnabled}
            />
          )}
          {/* {console.log("DISCOUNT", discount)} */}
          {/* <BlackweekTopBanner
            bannerID="discountBanner"
            text={discount?.additionalComponents?.topBanner?.html}
            isCounterEnabled={discount?.counterEnabled}
          /> */}
          {/* {
            isDiscountPage && discount?.additionalComponents?.topBanner?.enabled && <TopBanner
            bannerID="discountBanner"
            text={discount?.additionalComponents?.topBanner?.html}
            isCounterEnabled={discount?.counterEnabled}
            />
          } */}
          {children}
          {isDiscountPage && discount?.additionalComponents?.bottomBanner?.enabled && (
            <BottomBanner text={discount?.additionalComponents?.bottomBanner?.html} />
          )}
        </discountContext.Provider>
      ) : (
        <>
          <Loader isShown={true} />
          <noscript>
            <discountContext.Provider value={{ ...discount, target, highestDiscount, setDiscount }}>
              {children}
            </discountContext.Provider>
          </noscript>
        </>
      )}
    </>
  )
}

